import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { ThreeDots } from "react-bootstrap-icons";

export const HeaderContainer = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  height: 64px;
  padding: 20px;
  background-color: ${({ theme }) => theme.neutralBackgroundMedium};
  justify-content: space-between;
`;

export const VerticalSeparator = styled.div<{
  $right?: number;
  $left?: number;
  $notVisible?: boolean
}>`
  border-left: 1px solid ${({ theme, $notVisible }) => $notVisible ? 'transparent' : theme.neutralBorder};
  height: 64px;
  position: relative;
  align-self: center;
  padding-left: 16px;
  ${({ $right }) => ($right ? "right:" + $right + "px" : "")}
  ${({ $left }) => ($left ? "left:" + $left + "px" : "")}
`;

export const ProjectTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;
  padding-left: 20px;
  text-align: center;
`;

export const NavLinkButton = styled(NavLink)`
  display: inline-grid;
  height: 40px;
  width: 104px;
  justify-items: center;
  align-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.neutralWeak};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  &.active {
    display: flex;
    height: 40px;
    width: 104px;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.neutralBackgroundBase};
    color: ${({ theme }) => theme.neutralText};
    border-radius: 20px 20px 20px 20px;

    &:after {
      content: "";
      height: 2px;
      border-radius: 10px 10px 0 0;
      width: 58px;
      margin-top: 62px;
      background: ${({ theme }) => theme.neutralText};
      position: absolute;
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
    border-radius: 20px 20px 20px 20px;
    color: ${({ theme }) => theme.neutralText};
  }
`;

export const ProjectNameTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  height: 28px;
`;

export const ProjectTitleAction = styled(ThreeDots)`
  align-self: center;
  cursor: pointer;
  filter: invert(39%) sepia(9%) saturate(571%) hue-rotate(178deg)
    brightness(94%) contrast(89%);
`;

export const NotificationIcon = styled.button<{ $showAlert: boolean }>`
  display: flex;
  align-self: center;
  border: none;
  border-radius: 120px;
  width: 36px;
  height: 36px;
  background: ${({ theme, $showAlert }) =>
    $showAlert ? theme.neutralBackground : "transparent"};
  justify-content: center;
  place-items: center;
  cursor: pointer;
  position: relative;

  svg {
    position: absolute;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const NotificationAlert = styled.div`
  height: 16px;
  width: 16px;
  background: ${({ theme }) => theme.secondaryBackground};
  right: -8px;
  bottom: 0;
  position: absolute;
  padding: 1px 3px;
  border-radius: 0px 8px 8px 8px;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: ${({ theme }) => theme.secondaryText};
`;

export const HeadingMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProjectNameWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const Status = styled.div<{ $size: number }>`
  font-size: ${({ $size }) => `${$size}px `};
  color: ${({ theme }) => theme.primaryText};
  justify-content: center;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.primaryBackground};
  max-width: 87px;
  border-radius: 20px;
  font-weight: 400;
`;

export const StatusTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 20px;
`;
