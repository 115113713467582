import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";

import {
  Credentials,
  DividerDot,
  DividerLine,
  DividerWrapper,
  ForgotPassword,
  RegisterDescription,
  SignInButtonWrapper,
  SignInDescription,
  AuthHeading,
  AuthMethod,
  AuthWrapper,
  SecondaryButtonWrapper,
  Container,
  Background,
  AuthPageWrapper,
  LogoWrapper,
  Logo,
  Slash,
  Arrow,
} from "../styles";
import { FormGroup } from "../FormGroup";
import { Toggle } from "../../../components/Toggle";
import { Button } from "../../../components/Button";
import { SignInValidationSchema } from "../../../constants";
import { requestSignIn } from "../../../store/slices/auth";
import { ArrowRight, CheckLg } from "react-bootstrap-icons";

export const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState("regular");

  const formInitialValues = {
    email: "",
    password: "",
  };
  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
  };

  return (
    <Container>
      <Background />
      <AuthPageWrapper>
        <LogoWrapper>
          <Logo onClick={() => navigate("/")} />
        </LogoWrapper>
        <AuthWrapper>
          <AuthMethod>
            <AuthHeading>Sign In</AuthHeading>
            <Toggle
              selectedOption={selectedOption}
              onChange={handleOptionChange}
            />
          </AuthMethod>
          <SignInDescription>
            Enter your credentials to access your account
          </SignInDescription>
          <Formik
            initialValues={formInitialValues}
            validationSchema={SignInValidationSchema}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(requestSignIn({ ...values, navigate }));
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setValues
            }) => (
              <Form onSubmit={handleSubmit}>
                <Credentials>
                  <FormGroup
                    id="email"
                    type="email"
                    name="email"
                    errors={errors.email}
                    placeholder="Email address"
                    values={values.email}
                    handleChange={handleChange}
                    touched={touched.email}
                    handleBlur={handleBlur}
                  />
                  <FormGroup
                    id="password"
                    type="password"
                    name="password"
                    errors={errors.password}
                    placeholder="Password"
                    values={values.password}
                    handleChange={handleChange}
                    touched={touched.password}
                    handleBlur={handleBlur}
                    setValues={setValues}
                  />
                </Credentials>
                <SignInButtonWrapper>
                  <ForgotPassword onClick={() => navigate("/forgot-password")}>
                    <Slash />
                    <Arrow />
                    Forgot Password?
                    <Slash />
                    <Arrow />
                  </ForgotPassword>
                  <Button
                    type="submit"
                    variant="secondary"
                    size="medium"
                    name="Sign In"
                    icon={<CheckLg />}
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  />
                </SignInButtonWrapper>
              </Form>
            )}
          </Formik>
          <DividerWrapper>
            <DividerLine />
            <DividerDot />
            <DividerLine />
          </DividerWrapper>
          <SecondaryButtonWrapper>
            <RegisterDescription>Don't have an account?</RegisterDescription>
            <Button
              onClick={() => navigate("/sign-up")}
              variant="neutral"
              size="medium"
              name="Sign Up"
              icon={<ArrowRight />}
            />
          </SecondaryButtonWrapper>
        </AuthWrapper>
      </AuthPageWrapper>
    </Container>
  );
};
