import React from "react";

import { ToggleInput, ToggleOption, ToggleSwitchWrapper } from "./styles";

type ToggleType = {
  selectedOption?: string;
  onChange?: (option: string) => void;
};

export const Toggle = ({ selectedOption, onChange }: ToggleType) => {
  return (
    <ToggleSwitchWrapper>
      <ToggleOption $selected={selectedOption === "regular"}>
        Regular
        <ToggleInput
          type="radio"
          value="regular"
          checked={selectedOption === "regular"}
          onChange={() => onChange!("regular")}
        />
      </ToggleOption>
      <ToggleOption $selected={selectedOption === "team"}>
        Team
        <ToggleInput
          type="radio"
          value="team"
          checked={selectedOption === "team"}
          onChange={() => onChange!("team")}
        />
      </ToggleOption>
    </ToggleSwitchWrapper>
  );
};
