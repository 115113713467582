import { IFormatting } from "../../../../models/Widgets";

export const getBarChartKeys = (currentWidget: any) => {
  const groupBy = currentWidget?.groupBy?.at(0);
  const uniqueValuesKeys =
    (currentWidget?.uniqueValues &&
      Object.keys(currentWidget?.uniqueValues!)) ||
    [];
  const groupByKey =
    groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);
  return currentWidget?.uniqueValues
    ? currentWidget?.uniqueValues[groupByKey]
    : [];
};

export const getBarChartFormatting = (currentWidget: any): IFormatting[] => {
  const groupBy = Boolean(
    currentWidget && currentWidget.groupBy && currentWidget.groupBy.length
  );

  if (groupBy) {
    const keys = getBarChartKeys(currentWidget);
    return keys?.map((item: string, index: number) => ({
      key: item,
      color: String(index + 1),
    }));
  } else {
    return [{ key: "default", color: "1" }];
  }
};
