import React, { useRef, useState } from "react";
import {
  ContentWrapper,
  OptionsMenuItem,
  OptionsMenuItemsWrapper,
  OptionsMenuWrapper,
  OptionsWrapper,
} from "./styles";
import { ColorPicker } from "../ColorPicker";
import {
  SectionMenuArrowButton,
  SectionMenuClose,
} from "../Storytelling/styles";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { ImagePicker } from "../ImagePicker";

interface StorytellingOptionsMenuInterface {
  item: any;
  fileUrl?: string;
  setContentItem: any;
  setFile: any;
}
export const StorytellingOptionsMenu = ({
  item,
  fileUrl,
  setContentItem,
  setFile,
}: StorytellingOptionsMenuInterface) => {
  const [activeTab, setActiveTab] = useState<"colorPicker" | "image" | "video">(
    "colorPicker"
  );
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
  const ref = useRef(null);
  useOutsideClick(ref, () => {
    setOptionsMenuOpen(false);
  });

  return (
    <>
      <div ref={ref}>
        <SectionMenuArrowButton
          $active={optionsMenuOpen}
          onClick={() => {
            setOptionsMenuOpen(!optionsMenuOpen);
          }}
        />
        {optionsMenuOpen && (
          <SectionMenuClose
            onClick={() => {
              setOptionsMenuOpen(false);
            }}
          />
        )}
        {optionsMenuOpen && (
          <OptionsWrapper $isColorpicker={activeTab === "colorPicker"}>
            <OptionsMenuWrapper>
              <OptionsMenuItemsWrapper>
                <OptionsMenuItem
                  $activeItem={activeTab === "colorPicker"}
                  $width={81}
                  onClick={() => setActiveTab("colorPicker")}
                >
                  Color Picker
                </OptionsMenuItem>
                <OptionsMenuItem
                  onClick={() => setActiveTab("image")}
                  $activeItem={activeTab === "image"}
                  $width={45}
                >
                  Image
                </OptionsMenuItem>
              </OptionsMenuItemsWrapper>
            </OptionsMenuWrapper>
            <ContentWrapper>
              {activeTab === "colorPicker" && (
                <ColorPicker
                  item={item}
                  callback={(color) => {
                    setContentItem({
                      ...item,
                      backgroundColor: color,
                    });
                  }}
                  callbackText={(color) => {
                    setContentItem({
                      ...item,
                      backgroundColor: fileUrl ? "" : item?.backgroundColor,
                      textColor: color,
                    });
                  }}
                />
              )}
              {activeTab === "image" && (
                <ImagePicker
                  image={fileUrl!}
                  callback={(newImage) => {
                    setFile(newImage);
                  }}
                />
              )}
            </ContentWrapper>
          </OptionsWrapper>
        )}
      </div>
    </>
  );
};
