import { ISelect } from "../../Inputs/CustomSelect/types";
import { IMarkers, IPath } from "../types/types";

export const markers: IMarkers[] = ['donut', 'circle', 'square', 'rhombus', 'triangle', 'disabled'];

export const paths:IPath[] =[{
  name: 'Male',
  color: '#938CFF',
  marker: 'donut'
},
{
  name: 'Female',
  color: '#FF8C47',
  marker: 'donut'
}];

export const selectOptions: ISelect[] = [
  {
    option: '[2D] Line',
    value: 'lineChart'
  },
  {
    option: '[2D] Area',
    value: 'areaChart'
  },
  {
    option: '[2D] Stacked Area',
    value: 'stackedAreaChart'
  },
  {
    option: '[2D] Bar',
    value: 'barChart'
  },
  {
    option: '[2D] Stacked Bar',
    value: 'stackedBarChart'
  },
  {
    option: '[2D] Lollipop',
    value: 'lollipopChart'
  },
  {
    option: '[2D] Stacked Lollipop',
    value: 'stackedLollipopChart'
  },
  {
    option: '[2D] Matrix',
    value: 'matrix'
  },
  {
    option: '[2D] Sankey',
    value: 'sankey'
  },
  
];




