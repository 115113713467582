import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { getAiSuggestions } from "../../store/selectors/widgets";
import { CreateMapWidgetDto } from "../../models/Widgets";
import {
  LayerBlock,
  LayerButton,
  LayerButtonBlock,
  LayerLine,
  LayerOverlay,
  LayerWrapper,
  LayerYear,
  LayerYearBlock,
} from "./styles";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";

interface MapYearI {
  years: number[];
  setYear: (year: number) => void;
  selectedYear: number;
}

export const MapYear = ({
  years,
  setYear,
  selectedYear,
}: MapYearI) => {
  const [mapChartData, setMapChartData] = useState<CreateMapWidgetDto>();
  const aiSuggestions = useSelector(getAiSuggestions);

  useEffect(() => {
    if (aiSuggestions) {
      setMapChartData(
        aiSuggestions?.find(
          (el: CreateMapWidgetDto) => el.chartType === "mapChart"
        )
      );
    }
  }, [aiSuggestions, mapChartData]);

  const currentIndex = useMemo(
    () => years.findIndex((element) => element === selectedYear),
    [selectedYear, years]
  );

  // useEffect(() => {
  //   if (selectedYear) {
  //     const activeElement = document.getElementById(selectedYear?.toString());
  //     if (activeElement) {
  //       activeElement.scrollIntoView({
  //         behavior: "smooth",
  //         block: "start",
  //       });
  //     }
  //   }
  // }, [selectedYear]);

  return (
    <LayerWrapper id="year">
      <LayerOverlay>
        <LayerBlock>
          {years?.map((year) => {
            return (
              <LayerYearBlock
                key={year}
                id={year.toString()}
                onClick={() => {
                  setYear(year);
                }}
              >
                <LayerYear $selectedItem={selectedYear === year}>
                  {year}
                </LayerYear>
                <LayerLine $selectedItem={selectedYear === year} />
              </LayerYearBlock>
            );
          })}
        </LayerBlock>
        <LayerButtonBlock>
          <LayerButton
            onClick={() => {
              setYear(years[currentIndex > 1 ? currentIndex - 1 : 0]);
            }}
          >
            <ChevronLeft />
          </LayerButton>
          <LayerButton
            onClick={() => {
              setYear(
                years[currentIndex === years?.length - 1 ? currentIndex : currentIndex + 1]
              );
            }}
          >
            <ChevronRight />
          </LayerButton>
        </LayerButtonBlock>
      </LayerOverlay>
    </LayerWrapper>
  );
};
