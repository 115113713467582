import React, { useState } from "react";

import {
  Checkbox,
  CheckboxLabel,
  CheckboxWrapper,
  Checkmark,
  Container,
  EditButton,
  Input,
  InputField,
  KpiSettingItem,
  KpiSettingItemName,
} from "./styles";
import { AiKpiDto } from "../../models/Widgets";
import { CheckLg, Pencil } from "react-bootstrap-icons";
import { KPIComponentWrapper } from "../KPIComponentWrapper";

type Props = {
  selectedKpis: string[];
  kpi: AiKpiDto;
  handleChangeName: (name: string) => void;
  selectedKpi: () => void;
  nrAvailbleKpi: number;
};

export const KpiSettingsItem: React.FC<Props> = ({
  selectedKpis,
  kpi,
  handleChangeName,
  selectedKpi,
  nrAvailbleKpi,
}: Props) => {
  const [edit, setEdit] = useState(false);

  return (
    <KpiSettingItem>
      <KpiSettingItemName>
        <CheckboxWrapper className="interactivity">
          <Checkbox>
            <Container>
              <Input
                type="checkbox"
                onChange={
                  selectedKpis?.length < nrAvailbleKpi ||
                  selectedKpis?.includes(kpi.query)
                    ? selectedKpi
                    : () => {}
                }
                checked={selectedKpis?.includes(kpi.query)}
              />
              <Checkmark />
            </Container>
          </Checkbox>
          {edit ? (
            <InputField
              autoComplete="off"
              type="text"
              name="name"
              value={kpi.name}
              placeholder={"Name this KPI"}
              onChange={(e) => handleChangeName(e.target.value)}
            />
          ) : (
            <CheckboxLabel>{kpi.name}</CheckboxLabel>
          )}
        </CheckboxWrapper>
        {edit ? (
          <EditButton onClick={() => setEdit(!edit)}>
            <CheckLg />
          </EditButton>
        ) : (
          <EditButton onClick={() => setEdit(!edit)}>
            <Pencil />
          </EditButton>
        )}
      </KpiSettingItemName>
      {selectedKpis?.includes(kpi.query) ? (
        <KPIComponentWrapper
          showNameBlock={false}
          kpi={kpi}
          recommended={false}
          selected={false}
        />
      ) : null}
    </KpiSettingItem>
  );
};
