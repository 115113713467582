import styled from "styled-components";

export const AvailableWidgetsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

export const AvailableWidgetsHeadingWrapper = styled.div`
  display: flex;
  padding: 12px 0;
  align-items: center;
  gap: 8px;
`;

export const AvailableWidgetsHeading = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
`;

export const AvailableWidgetsCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  width: 21px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primaryBackground};
  color: ${({ theme }) => theme.primaryText};
  font-size: 12px;
  font-weight: 500;
`;

export const WidgetsItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-bottom: 16px;
  max-width: 100%;
`;

export const WidgetItem = styled.div<{
  $selected?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 16px;
  width: calc(50% - 8px);
  height: 263px;
  color: ${({ theme }) => theme.primaryText};
`;
