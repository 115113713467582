import React, { useCallback, useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  requestPageWidgets,
  requestPublicPageWidgets,
} from "../../store/slices/projectPages";
import {
  ValueDelimitator,
  FilterLabel,
  FilterLength,
  FilterValueBlock,
  FilterValues,
  FilterWrapper,
  FilterDelimitator,
  FilterValuesBlock,
  FilterValue,
  FilterOption,
  FilterItem,
  FilterItemDelimitator,
  FilterBlockItem,
} from "./styles";
import { useDebounce } from "../../hooks/useDebounce";
import { Loader } from "../Loader";
import { getPageSettings } from "../../store/selectors/projects";
import { getIsPublicMode } from "../../store/selectors/main";

export interface OptionsInterface {
  label: string;
  value: string;
}

interface IDefaultItemRendererProps {
  checked: boolean;
  option: OptionsInterface;
  disabled?: boolean;
  onClick: () => void;
}

export const YearFiltering = ({ years }: { years: OptionsInterface[] }) => {
  const [selected, setSelected] = useState<OptionsInterface[]>([]);
  const pageSettings = useSelector(getPageSettings);
  const isPublicRoute = useSelector(getIsPublicMode);
  const [lastSelected, setLastSelected] = useState<OptionsInterface[]>([]);
  const [opened, setOpened] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { pageId } = useParams();
  const dispatch = useDispatch();
  const debouncedSelected = useDebounce(selected, 600);

  const getWidgets = useCallback(() => {
    const selectedYears = debouncedSelected.map((yearObj) => yearObj.value);
    let filter = [];
    for (let year of selectedYears) {
      const yearToNr = parseFloat(year);
      if (typeof yearToNr === "number" && !Number.isNaN(yearToNr)) {
        filter.push(yearToNr);
      }
    }
    const query =
      selectedYears?.length !== years?.length
        ? JSON.stringify({
            year: { $in: filter },
          })
        : "";
    if (pageId) {
      setIsLoading(true);
      setLastSelected(debouncedSelected);
      if (isPublicRoute) {
        dispatch(
          requestPublicPageWidgets({
            pageId: pageId!,
            includeData: true,
            query: query,
            callbacks: {
              onSuccess: () => {
                setIsLoading(false);
              },
            },
          })
        );
      } else {
        dispatch(
          requestPageWidgets({
            pageId: pageId!,
            includeData: true,
            query: query,
            callbacks: {
              onSuccess: () => {
                setIsLoading(false);
              },
            },
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, debouncedSelected]);

  useEffect(() => {
    if (debouncedSelected.length === 0) {
      setSelected(years as any);
      setLastSelected(years);
      setIsLoading(false);
      return;
    }
  }, [debouncedSelected.length, years]);

  useEffect(() => {
    try {
      if (
        debouncedSelected?.length > 0 &&
        debouncedSelected?.length !== lastSelected?.length
      ) {
        getWidgets();
      }
    } catch (e) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSelected?.length, lastSelected?.length]);

  const handleSelectionChange = (newSelected: any) => {
    if (newSelected.length === 1) {
      toast("At least two years should be selected", { type: "warning" });
      return;
    }
    setSelected(newSelected);
  };

  const valueRenderer = (
    selected: OptionsInterface[],
    options: OptionsInterface[]
  ) => {
    const isAll = selected?.length === options?.length;
    const selectedLabels = selected?.map((s) => s.label);
    selectedLabels?.sort();
    const firstLabel = selectedLabels?.at(0);
    const length = selectedLabels?.length - 1;
    return (
      <FilterValuesBlock>
        <FilterValueBlock>
          <FilterLabel>Year</FilterLabel>
          {isAll ? (
            <FilterValue>All</FilterValue>
          ) : (
            <FilterValues>
              <FilterValue>{firstLabel || ""}</FilterValue>
              <ValueDelimitator $menuOpen={opened} />
              {length ? <FilterLength>+{length}</FilterLength> : null}
            </FilterValues>
          )}
        </FilterValueBlock>
        <FilterDelimitator $menuOpen={opened} />
      </FilterValuesBlock>
    );
  };

  const onMenuToggle = (toggle: boolean) => {
    setOpened(toggle);
  };

  const itemRenderer = ({
    checked,
    option,
    onClick,
    disabled,
  }: IDefaultItemRendererProps) => {
    return (
      <FilterBlockItem key={option.value || "all"}>
        <FilterItem
          $checked={checked}
          className={`item-renderer ${disabled ? "disabled" : ""}`}
        >
          <input
            type="checkbox"
            onChange={onClick}
            checked={checked}
            tabIndex={-1}
            disabled={disabled}
            id={option.value || "all"}
            style={{ opacity: option.value ? 1 : 0 }}
          />
          <FilterOption $isAll={!option.value} htmlFor={option.value || "all"}>
            {option.label}
          </FilterOption>
        </FilterItem>
        {!option.value ? <FilterItemDelimitator /> : null}
      </FilterBlockItem>
    );
  };

  return pageSettings?.showFilters ? (
    <>
      {isLoading && <Loader fullScreen />}
      <FilterWrapper>
        <MultiSelect
          disableSearch
          ClearSelectedIcon={null}
          // hasSelectAll={true}
          overrideStrings={{
            selectAll: "All",
          }}
          options={years}
          value={selected}
          onChange={handleSelectionChange}
          labelledBy="Select"
          valueRenderer={valueRenderer}
          ItemRenderer={itemRenderer}
          onMenuToggle={onMenuToggle}
          className="filter-multi-select"
        />
      </FilterWrapper>
    </>
  ) : null;
};
