import { StoreInterface } from "../../models";

export const getTheme = (store: StoreInterface) =>
  store.appConfig.selectedTheme;

export const getUser = (store: StoreInterface) => store.auth.user;

export const getIsPublicMode = (store: StoreInterface) =>
  store.appConfig.isPublicRoute;

export const getIsEditMode = (store: StoreInterface) => {
  return store.appConfig.isEditMode;
};
