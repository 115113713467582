import styled from "styled-components";

import { X } from "react-bootstrap-icons";

export const CustomBadge = styled.label<{
  $selected?: boolean;
  $customSelected?: boolean;
  $errorDetected?: boolean;
}>`
  user-select: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;
  width: auto;
  gap: 5px;
  border-radius: 4px;
  padding: 3px 6px;
  color: ${({ theme }) => theme.neutralText};
  opacity: ${({ theme, $errorDetected }) => ($errorDetected ? 0.4 : 1)};
  background-color: ${({ $selected, $customSelected, theme }) =>
    !$selected
      ? theme.neutralBackground
      : $customSelected
      ? "none"
      : theme.primaryBackgroundActive};
  border: ${({ $selected, $customSelected, theme, $errorDetected }) =>
    $selected
      ? $customSelected
        ? `1px solid ${theme.primaryBorder}`
        : `1px solid transparent`
      : $errorDetected
      ? `1px dashed ${theme.alertBackgroundStrong}`
      : "1px solid rgba(0, 0, 0, 0)"};

  position: relative;

  img {
    width: 14px;
    height: 14px;
  }

  &:hover {
    background-color: ${({ $selected, theme }) =>
      !$selected ? theme.neutralBackgroundBase : ""};
    box-shadow: 0px 2px 2px 0px rgba(20, 28, 44, 0.04),
      0px 2px 4px 1px rgba(20, 28, 44, 0.04);
    border: ${({ $selected, theme }) =>
      `1px solid ${$selected ? theme.primaryBorder : theme.neutralBorder}`};
  }
  &:active {
    border: ${({ $selected, theme }) =>
      `1px solid ${$selected ? "transparent" : theme.primaryBorder}`};
  }
`;

export const CheckboxName = styled.span`
  max-width: 25ch;
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;
  line-height: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CloseButton = styled(X)`
  position: relative;
  bottom: -1px;
  z-index: 1;
  width: 12px;
  height: 12px;
`;

export const VerticalLine = styled.div<{
  $full?: boolean;
}>`
  border-left: 1px solid ${({ theme }) => theme.neutralBorder};
  height: ${({ $full }) => ($full ? "22px" : "16px")};
  position: relative;
`;
