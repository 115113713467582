import styled, { css } from "styled-components";
import { Globe, ThreeDots } from "react-bootstrap-icons";

const activePage = css`
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 20px;
  box-shadow: 1px 1px 1px rgba(99, 99, 99, 0.1);
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.neutralText};
`;

const hoverStyle = css`
  background-color: ${({ theme }) => theme.neutralBackgroundHover};
  box-shadow: 1px 1px 1px rgba(99, 99, 99, 0.1);
  border-radius: 20px;
  border: 1px solid transparent;
`;

export const PageItem = styled.div<{ $active?: boolean; $menuOpen?: boolean }>`
  display: flex;
  position: relative;
  gap: 4px;
  height: 36px;
  width: 100%;
  align-items: center;
  padding: 0 16px;
  color: ${({ theme }) => theme.neutralTextWeak};
  font-weight: 400;
  border: 1px solid transparent;

  ${({ $active }) => $active && activePage}
  ${({ $menuOpen }) => $menuOpen && hoverStyle}
  
  &:hover {
    ${hoverStyle}
  }
`;

export const PageName = styled.div`
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const PageOptions = styled.div`
  display: flex;
`;

export const OptionsIcon = styled(ThreeDots)`
  cursor: pointer;
`;
export const GlobeIcon = styled(Globe)`
  color: ${({ theme }) => theme.neutralTextDisabled};
`;
