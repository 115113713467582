import styled from "styled-components";
import { OverviewContent } from "../styles";
export const WidgetsItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 20px;
  max-width: 100%;
`;

export const DatasetsWrapper = styled.div`
  margin: 16px 0 0 0;
  padding: 0 20px 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-bottom: 1px solid #d3dbe3;
`;

export const OverviewContentExt = styled(OverviewContent)`
  height: calc(100% - 127px);
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const WidgetsCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.primaryBackgroundActive};
  color: ${({ theme }) => theme.primaryText};
  border-radius: 50%;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
`;

export const RecommendedWidgetItemWrapper = styled.div`
  min-height: 201px;
`;
