import React from "react";
import { Barchart } from "./Barchart";
import { SankeyPlot } from "./Sankey";
import { WrapperLineChart } from "./LineChart";
import { LollipopChart } from "./Lollipop";
import { AreaChart } from "./AreaChart";
import { MatrixChart } from "./MatrixChart";
import { WidgetItem } from "../../models/Widgets";
import { UniversalMap } from "./MapBox";

export interface WrapperWidget {
  currentWidget: WidgetItem;
  storytelling: boolean;
  recommended: boolean;
  showLegend: boolean;
}

export const WIDGETS: {
  [key: string]: (props: WrapperWidget) => JSX.Element;
} = {
  barChart: (props: WrapperWidget) => <Barchart {...props} />,
  sankey: (props: WrapperWidget) => <SankeyPlot {...props} />,
  lineChart: (props: WrapperWidget) => <WrapperLineChart {...props} />,
  lollipopChart: (props: WrapperWidget) => <LollipopChart {...props} />,
  mapChart: (props: WrapperWidget) => <UniversalMap {...props} />,
  areaChart: (props: WrapperWidget) => <AreaChart {...props} />,
  matrix: (props: WrapperWidget) => <MatrixChart {...props} />,
};
