import { IFormatting, IMarkersType } from "../../../../models/Widgets";

export const get_xAxe = (currentWidget: any, lollipopChartSuggestion?: any) => {
  return currentWidget?.xAxe?.length
    ? currentWidget?.xAxe?.at(0)
    : lollipopChartSuggestion.xAxe?.at(0);
};

export const get_yAxe = (currentWidget: any, lollipopChartSuggestion?: any) => {
  return currentWidget?.yAxe?.length
    ? currentWidget?.yAxe?.at(0)
    : lollipopChartSuggestion.yAxe?.at(0);
};

export const get_data = (currentWidget: any, lollipopChartSuggestion?: any) => {
  const xAxe = get_xAxe(currentWidget, lollipopChartSuggestion);
  const yAxe = get_yAxe(currentWidget, lollipopChartSuggestion);

  return currentWidget?.data?.map((l: any) => ({
    x: xAxe ? l[xAxe] : l.year,
    y: yAxe ? l[yAxe] : l.value,
  }));
};

export const getGroupedData = (
  currentWidget: any,
  lollipopChartSuggestion?: any
) => {
  const xAxe = get_xAxe(currentWidget, lollipopChartSuggestion);
  const yAxe = get_yAxe(currentWidget, lollipopChartSuggestion);
  const data = get_data(currentWidget, lollipopChartSuggestion);
  const groupBy = currentWidget?.groupBy?.at(0);

  if (groupBy) {
    return currentWidget?.data?.reduce((acc: any, obj: any) => {
      const group = obj[groupBy];
      acc[group] = acc[group] || [];
      acc[group].push({
        x: xAxe ? obj[xAxe] : obj.year,
        y: yAxe ? obj[yAxe] : obj.value,
      });
      return acc;
    }, {});
  } else {
    return { default: data };
  }
};

export const getLollipopChartMarkers = (
  currentWidget: any,
  lollipopChartSuggestion?: any
): IMarkersType[] => {
  const keys = getGroupedData(currentWidget);
  if (!keys) {
    return [];
  }
  const markers: IMarkersType[] = Object.keys(keys).map((item) => ({
    key: item,
    shape: "rhombus",
  }));

  return currentWidget.markers ?? markers;
};

export const getLollipopChartColors = (
  currentWidget: any,
  lollipopChartSuggestion?: any
): IFormatting[] => {
  const groupBy = currentWidget?.groupBy?.at(0);
  const uniqueValuesKeys =
    (currentWidget?.uniqueValues &&
      Object.keys(currentWidget?.uniqueValues!)) ||
    [];
  const groupByKey =
    groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);
  const keys = currentWidget?.uniqueValues
    ? currentWidget?.uniqueValues[groupByKey]
    : getGroupedData(currentWidget);

  if (!keys) {
    return [];
  }

  if (currentWidget.uniqueValues) {
    const colors: IFormatting[] = keys.map((item: string, index: number) => ({
      key: item,
      color: String(index + 1),
    }));
    return colors;
  } else {
    const colors: IFormatting[] = Object.keys(keys).map((item, index) => {
      return {
        key: item,
        color: String(index + 1),
      };
    });
    return colors;
  }
};
