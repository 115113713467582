import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";

import 'react-tooltip/dist/react-tooltip.css'
import "./index.css";
import { persistor, store } from "./store";
import Layout from "./Layout";
import { Routing } from "./routing";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
      <RecoilRoot>
        <BrowserRouter>
          <Layout>
            <PersistGate loading={null} persistor={persistor}>
              <Routing />
            </PersistGate>
          </Layout>
        </BrowserRouter>
      </RecoilRoot>
    </DndProvider>
  </Provider>
);
reportWebVitals();
