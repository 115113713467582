import { toast } from "react-toastify";

import { ModalOverlay } from "../styles";
import {
  Arrow,
  CopyLinkImg,
  ShareModalContentWrapper,
  ShareModalCopyLinkButton,
  ShareModalHeaderWrapper,
  ShareModalProjectName,
  ShareModalWrapper,
  Slash,
  ShareModalContentTitle,
  ShareModalContentPagesWrapper,
} from "./styles";
import { Close } from "../UploadedFilePreview/styles";
import { useSelector } from "react-redux";
import {
  getAllPages,
  getCurrentProjectData,
  getCurrentProjectId,
} from "../../../store/selectors/projects";
import {
  ModalSubmenu,
  SubmenuItem,
  SubmenuTitle,
} from "../DataVizSettings/styles";
import { PublicLink } from "./components/PublicLink";
import { PageSettingsDTO } from "../../../models/Pages";

export const ShareModal = ({ onClose }: { onClose: () => void }) => {
  
  const { name, isPublic } = useSelector(getCurrentProjectData);
  const id = useSelector(getCurrentProjectId);
  const pages = useSelector(getAllPages);

  const copyLink = () => {
    if (isPublic) {
      try {
        navigator?.clipboard?.writeText(
          `${process.env.REACT_APP_URL}projects/public/${id}`
        );
        toast("Copied successfully", { type: "success" });
      } catch (e) { }
    }
  };

  const sortByCreatedAt = (a: PageSettingsDTO, b: PageSettingsDTO) => {
    const dateA = new Date(a?.createdAt || '');
    const dateB = new Date(b?.createdAt || '');
    return dateA.getTime() - dateB.getTime(); // For ascending order. Use dateB - dateA for descending.
  };
  
  return (
    <ModalOverlay onClick={onClose}>
      <ShareModalWrapper onClick={(e) => e.stopPropagation()}>
        <ShareModalHeaderWrapper>
          <ShareModalProjectName>Share {`[${name}]`}</ShareModalProjectName>
          <ShareModalCopyLinkButton $disabled={!!!isPublic} onClick={copyLink}>
            <Slash />
            <Arrow />
            Copy Link
            <CopyLinkImg />
            <Slash />
            <Arrow />
          </ShareModalCopyLinkButton>
          <Close
            onClick={() => {
              onClose();
            }}
          />
        </ShareModalHeaderWrapper>
        <ModalSubmenu>
          <SubmenuItem $selected={false} onClick={() => { }}>
            <SubmenuTitle>Invite People</SubmenuTitle>
          </SubmenuItem>
          <SubmenuItem $selected={true} onClick={() => { }}>
            <SubmenuTitle>Publish Pages</SubmenuTitle>
          </SubmenuItem>
        </ModalSubmenu>
        <ShareModalContentWrapper>
          <ShareModalContentTitle>Project pages</ShareModalContentTitle>
          <ShareModalContentPagesWrapper>
            {pages?.items?.length && [...pages?.items]?.sort(sortByCreatedAt)?.map((el) => {
              return (<PublicLink item={el} />);
            })}
          </ShareModalContentPagesWrapper>
        </ShareModalContentWrapper>
      </ShareModalWrapper>
    </ModalOverlay>
  );
};
