import styled from "styled-components";
import { X, ChevronCompactDown } from "react-bootstrap-icons";

//SelectContainer

export const Container = styled.div<{
  $width?: string;
}>`
  width: ${({ $width }) => $width || "100%"};
  position: relative;
`;

export const SelectContainer = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  padding: 10px 10px 10px 14px;
  border-radius: 25px;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SelectedValue = styled.p`
  font-size: 14px;
  width: 100%;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

export const DownIcon = styled(ChevronCompactDown) <{
  $close?: boolean;
}>`
  transition: 0.2s;
  transform: rotateX(${({ $close }) => ($close ? "180deg" : "0deg")});
  cursor: pointer;
`;

export const CloseIcon = styled(X)`
  margin-right: 7px;
  border-right: 1px solid ${({ theme }) => theme.neutralBorder};
  padding-right: 7px;
  width: 27px;
  height: 20px;
`;

//DropDownSelect

export const DropDownSelect = styled.div<{ $openTop?: boolean }>`
  ${({ $openTop }) => $openTop ? 'bottom: 45px' : 'top: 45px'};
  
  position: absolute;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 10px;
  background: white;
  z-index: 999;
`;
export const DropDownMenuList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
`;
export const DropDownMenuItem = styled.li`
  padding: 10px 14px;
  transition: 0.2s;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.neutralBackground};
  }
`;
