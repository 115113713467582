import styled, { css } from "styled-components";

import { ReactComponent as DashboardImg } from "../../../assets/dashboard.svg";
import { ReactComponent as StorytellingImg } from "../../../assets/storytelling.svg";
import { ReactComponent as radioButtonActive } from "../../../assets/radioButtonActive.svg";
import { ReactComponent as radioButtonInactive } from "../../../assets/radioButtonInactive.svg";
import { ReactComponent as radioButtonHovered } from "../../../assets/hoveredRadioButton.svg";
import {
  ToggleOff,
  ToggleOn,
  ChevronCompactDown,
  XLg,
} from "react-bootstrap-icons";

// --------------------> Modal general <--------------------

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.neutralText};
`;

export const CloseIcon = styled(XLg)`
  cursor: pointer;
  filter: invert(56%) sepia(23%) saturate(181%) hue-rotate(179deg)
    brightness(89%) contrast(86%);
`;

// --------------------> Project type <--------------------

export const ProjectTypeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  /* justify-content: center; */
  padding-left: 16px;
  padding-right: 22px;
  padding-bottom: 16px;
`;

export const HoveredRadioButton = styled(radioButtonHovered)`
  margin-top: 12px;
  margin-right: 12px;
  display: none;
`;

export const ActiveRadioButton = styled(radioButtonActive)`
  margin-top: 12px;
  margin-right: 12px;
`;

export const InactiveRadioButton = styled(radioButtonInactive)`
  margin-top: 12px;
  margin-right: 12px;
`;

export const ProjectTypeItem = styled.div<{ $selectedItem?: boolean, $fullWidth?: boolean }>`
  width: ${({ $fullWidth }) => $fullWidth ? "100%" : "216px"};
  height: 210px;
  border: 1px solid
    ${({ $selectedItem, theme }) =>
    $selectedItem ? theme.primaryBorder : theme.neutralBorder};
  border-radius: 12px;
  cursor: pointer;
  background: ${({ theme, $selectedItem }) =>
    $selectedItem ? theme.primaryBackground : ""};
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    transition: 0.2s ease-in-out;

    ${HoveredRadioButton} {
      display: ${({ $selectedItem }) => !$selectedItem && "block"};
    }
    ${ActiveRadioButton} {
      display: ${({ $selectedItem }) => !$selectedItem && "none"};
    }

    ${InactiveRadioButton} {
      display: ${({ $selectedItem }) => !$selectedItem && "none"};
    }
  }
`;

export const ProjectTypeIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DashboardIcon = styled(DashboardImg)``;

export const StorytellingIcon = styled(StorytellingImg)``;

export const ProjectTypeDescriptionWrapper = styled.div`
  padding: 0 12px 12px 12px;
`;

export const ProjectTypeTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
  padding-bottom: 8px;
`;

export const ProjectTypeDescription = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

// --------------------> Style section <--------------------

export const StyleSectionWrapper = styled.div`
  padding: 0 20px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
  margin-bottom: 8px;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 18px;
  cursor: pointer;
  position: relative;
  align-items: center;
  border-radius: 24px;

  &:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
  }
`;

export const DropDownIcon = styled(ChevronCompactDown)``;

export const DropdownContainer = styled.div<{ $opened: boolean }>`
  border-radius: 24px;
  position: relative;
  border: ${({ theme, $opened }) =>
    $opened
      ? `1px solid ${theme.primaryBorder}`
      : `1px solid ${theme.neutralBorder}`};
`;

export const DropDownHeader = styled.div`
  width: 166px;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralText};
`;

export const DropDownListContainer = styled.div`
  position: absolute;
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0 0 5px 0;
  border-radius: 12px;
  margin-top: 10px;
  width: 440px;
  z-index: 2;
`;

// --------------------> Interactivity section <--------------------

export const InteractivityWrapper = styled.div`
  padding: 12px 20px;
`;

export const InteractivityHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  height: 24px;
`;

export const InteractivityTitle = styled.div`
  color: ${({ theme }) => theme.neutralText};
`;

export const InteractivityToggle = styled.div<{ $isEnabled?: boolean }>`
  display: flex;
  gap: 8px;
  color: ${({ $isEnabled, theme }) =>
    $isEnabled ? theme.neutralText : theme.neutralTextWeak};
`;

export const InteractivityDescription = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const SwitchedOff = styled(ToggleOff)`
  cursor: pointer;
`;

export const SwitchedOn = styled(ToggleOn)`
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-self: center;
`;

// --------------------> Map Integration section <--------------------

export const MapIntegrationWrapper = styled.div`
  padding: 12px 20px;
`;

export const MapIntegrationHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  height: 24px;
`;

export const MapIntegrationTitle = styled.div`
  color: ${({ theme }) => theme.neutralText};
`;

export const MapIntegrationToggle = styled.div<{ $isEnabled?: boolean }>`
  display: flex;
  gap: 8px;
  color: ${({ $isEnabled, theme }) =>
    $isEnabled ? theme.neutralText : theme.neutralTextWeak};
`;

export const MapIntegrationIconWrapper = styled.div`
  display: flex;
  align-self: center;
`;

export const MapTypeItemContainer = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 6px;
`;

export const MapTypeItemWrapper = styled.div<{ $isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 216px;
  height: 78px;
  border: 1px solid
    ${({ theme, $isSelected }) =>
    $isSelected ? theme.primaryBorder : theme.neutralBorder};
  background-color: white;
  border-radius: 6px;
  padding: 4px 50px 8px 50px;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    transition: 0.2s ease-in-out;
  }
`;

// --------------------> Select template <--------------------

export const SelectTemplateWrapper = styled.div`
  padding: 0 20px 12px;
`;

export const SelectTemplateHeading = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
`;

export const TemplatesWrapper = styled.div<{ $loading: boolean }>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  background: ${({ theme, $loading }) =>
    $loading ? theme.neutralBackground : "transparent"};
  gap: 8px;
  padding-top: 8px;
  min-height: ${({ $loading }) => ($loading ? "80px" : "0")};
  border-radius: ${({ $loading }) => ($loading ? "6px" : "0")};
  position: relative;
`;

export const TemplateIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const TemplateImg = styled.img<{ $selectedItem: boolean }>`
  object-fit: contain;
  width: 100%;
  height: 100%;

  ${({ $selectedItem }) =>
    $selectedItem &&
    css`
      opacity: 100%;
    `}
`;

export const TemplateItem = styled.div<{ $selectedItem: boolean }>`
  display: flex;
  justify-content: center;
  border: 1px solid
    ${({ theme, $selectedItem }) =>
    $selectedItem ? theme.primaryBorder : theme.neutralBorder};
  border-radius: 6px;
  width: 104px;
  height: 64px;
  padding: 4px;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  }
`;

export const InteractivityBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;

export const InteractivityLabel = styled.div`
  display: flex;
  color: ${({ theme }) => theme.neutralText};
`;

export const CheckboxWrapper = styled.label`
  display: flex;
  margin-top: 8px;
  gap: 8px;
  flex-direction: row;
  align-items: center;
`;

export const CheckboxLabel = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.neutralWeak};
`;
