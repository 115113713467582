import { createSlice } from "@reduxjs/toolkit";

export type ChangeThemeAction = {
  payload: string;
};

export interface AppConfig {
  selectedTheme: "light" | "dark";
  isPublicRoute?: boolean;
  isEditMode: boolean;
}

const initialState: AppConfig = {
  selectedTheme: "light",
  isPublicRoute: undefined,
  isEditMode: true,
};

const appSlice = createSlice({
  name: "appConfig",
  initialState,
  reducers: {
    setTheme: (state, { payload }: { payload: "light" | "dark" }) => {
      state.selectedTheme = payload;
    },
    setIsPublic: (state, { payload }: { payload: boolean }) => {
      state.isPublicRoute = payload;
    },
    setIsEditMode: (state, { payload }: { payload: boolean }) => {
      state.isEditMode = payload;
    },
  },
});

export const { setTheme, setIsPublic, setIsEditMode } = appSlice.actions;
export default appSlice.reducer;
