export const disableLayer = (layerName: string, ref: any) => {
  const map = ref.current?.getMap();
  if (map) {
    if (map.getLayer(layerName)) {
      map.removeLayer(layerName);
    }
    if (map.getSource(layerName)) {
      map.removeSource(layerName);
    }
  }
}
export const changeLayerVisibility = (layerName: string,  ref: any, visibility?: 'none' | 'visible',) => {
  const map = ref;
  if (map) {
    map.setLayoutProperty(layerName, 'visibility', visibility || 'none')
  }
}