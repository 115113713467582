import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import { Heading } from "../../styles";
import { List } from "./styles";
import { PageMenu } from "../../components/PageMenu";
import { ProjectItem } from "../../components/ProjectItem";
import { HeadingMenuContainer } from "../../components/NavBar/styles";
import { EmptyProjects } from "../../components/EmptyProjects";
import { setActiveModal } from "../../store/slices/modals";
import { requestAllProjects } from "../../store/slices/projects";
import { Loader } from "../../components/Loader";
import { getProjects, getProjectsSearch } from "../../store/selectors/projects";
import { LOCAL_STORAGE_KEYS } from "../../constants";
import { ProjectItemI } from "../../models/Projects";

export const Projects = () => {
  const dispatch = useDispatch();
  const allProjects = useSelector(getProjects);
  const [projects, setProjects] = useState<ProjectItemI[]>([]);
  const [refresh, setRefresh] = useState<number>(0);
  const search = useSelector(getProjectsSearch);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const [sortBy, setSortBy] = useState(
    localStorage.getItem(LOCAL_STORAGE_KEYS.sort)
      ? localStorage.getItem(LOCAL_STORAGE_KEYS.sort)
      : "createdAt"
  );
  const [orderBy, setOrderBy] = useState(
    localStorage.getItem(LOCAL_STORAGE_KEYS.order)
      ? localStorage.getItem(LOCAL_STORAGE_KEYS.order)
      : "DESC"
  );

  const handleSortChange = (sortOption: string) => {
    setSortBy(sortOption);
  };

  const openCreateProjectModal = (): any =>
    dispatch(setActiveModal({ id: "createProjectModal" }));

  useEffect(() => {
    dispatch(
      requestAllProjects({
        limit,
        skip,
        orderBy: sortBy!,
        orderDirection: orderBy!,
        name: search,
        callbacks: {
          onSuccess: (data) => {
            const newItems = [...projects, ...data.items];
            setProjects(newItems);
            setHasMore(data.count > newItems?.length);
          },
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, refresh]);

  const fetchData = () => {
    setLimit(10);
    setSkip(skip + 10);
    setRefresh(refresh + 1);
  };

  useEffect(() => {
    setProjects([]);
    setSkip(0);
    setLimit(10);
    setRefresh(refresh + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setProjects([]);
    setLimit(skip);
    setSkip(0);
    setRefresh(refresh + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, orderBy]);

  return (
    <>
      <HeadingMenuContainer>
        <Heading>Projects</Heading>
        <PageMenu
          setOrderBy={setOrderBy}
          orderBy={orderBy}
          sortBy={sortBy}
          setSortBy={setSortBy}
          handleSortChange={handleSortChange}
        />
      </HeadingMenuContainer>
      {allProjects.isLoading ? <Loader fullScreen={true} /> : null}
      {!!(projects.length === 0 && !allProjects.isLoading) ? (
        <EmptyProjects openNewProjectModal={openCreateProjectModal} />
      ) : (
        <InfiniteScroll
          dataLength={projects.length}
          next={fetchData}
          hasMore={hasMore}
          loader={allProjects.isLoading && <h4>Loading...</h4>}
          endMessage={null}
        >
          <List>
            {projects?.map((project) => {
              return <ProjectItem project={project} key={project.id} />;
            })}
          </List>
        </InfiniteScroll>
      )}
    </>
  );
};
