// Icon imports
import strongcategorical from './strong/categorical.svg';
import strongdatetime from './strong/datetime.svg';
import stronglatitude from './strong/latitude.svg';
import stronglongitude from './strong/longitude.svg';
import stronglocation from './strong/location.svg';
import strongmeta from './strong/meta.svg';
import strongmetric from './strong/metric.svg';
import strongtext from './strong/text.svg';

import simplecategorical from './simple/categorical.svg';
import simpledatetime from './simple/datetime.svg';
import simplelatitude from './simple/latitude.svg';
import simplelongitude from './simple/longitude.svg';
import simplelocation from './simple/location.svg';
import simplemeta from './simple/meta.svg';
import simplemetric from './simple/metric.svg';
import simpletext from './simple/text.svg';

import disabledcategorical from './disabled/categorical.svg';
import disableddatetime from './disabled/datetime.svg';
import disabledlatitude from './disabled/latitude.svg';
import disabledlongitude from './disabled/longitude.svg';
import disabledlocation from './disabled/location.svg';
import disabledmeta from './disabled/meta.svg';
import disabledmetric from './disabled/metric.svg';
import disabledtext from './disabled/text.svg';

import subdimensioncategorical from './subdimension/categorical.svg';
import subdimensiondatetime from './subdimension/datetime.svg';
import subdimensionlatitude from './subdimension/latitude.svg';
import subdimensionlongitude from './subdimension/longitude.svg';
import subdimensionlocation from './subdimension/location.svg';
import subdimensionmeta from './subdimension/meta.svg';
import subdimensionmetric from './subdimension/metric.svg';
import subdimensiontext from './subdimension/text.svg';

export type IconState = 'disabled' | 'simple' | 'strong' | 'subdimension';

export type Dimensions = {
  categorical: string;
  datetime: string;
  latitude: string;
  longitude: string;
  location: string;
  meta: string;
  metric: string;
  text: string;
};

const iconMap = {
  strong: {
    categorical: strongcategorical,
    datetime: strongdatetime,
    latitude: stronglatitude,
    longitude: stronglongitude,
    location: stronglocation,
    meta: strongmeta,
    metric: strongmetric,
    text: strongtext,
  },
  simple: {
    categorical: simplecategorical,
    datetime: simpledatetime,
    latitude: simplelatitude,
    longitude: simplelongitude,
    location: simplelocation,
    meta: simplemeta,
    metric: simplemetric,
    text: simpletext,
  },
  disabled: {
    categorical: disabledcategorical,
    datetime: disableddatetime,
    latitude: disabledlatitude,
    longitude: disabledlongitude,
    location: disabledlocation,
    meta: disabledmeta,
    metric: disabledmetric,
    text: disabledtext,
  },
  subdimension: {
    categorical: subdimensioncategorical,
    datetime: subdimensiondatetime,
    latitude: subdimensionlatitude,
    longitude: subdimensionlongitude,
    location: subdimensionlocation,
    meta: subdimensionmeta,
    metric: subdimensionmetric,
    text: subdimensiontext,
  },
};

export const dimensionIcons = (state: IconState = 'strong'): Dimensions => {
  return iconMap[state];
};
