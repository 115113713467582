import styled from "styled-components";

export const SettingsTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${({ theme }) => theme.neutralText};
  padding: 20px 0;
`;

export const SideBarDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 18px 16px;
  gap: 26px;
  border-radius: 8px;
  border: 1px;
  opacity: 0px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
`;

export const SideBarDetailsContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SideBarDetailsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SideBarDetailsTextTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => theme.neutralText};
`;
export const SideBarDetailsTextSubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const SideBarDetailsTermsOfUse = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: ${({ theme }) => theme.primaryText};
  cursor: pointer;
`;

export const MainSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const MainSettingsBlockWrapper = styled.div`
  display: flex;
  gap: 24px;
  position: relative;
`;

export const MainSettingsColumnBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const MainSettingsLeftBlockWrapper = styled.div<{ $disablePadding?: boolean; }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  ${({ $disablePadding }) => $disablePadding ? '' : 'padding-top: 18px'};
`;

export const MainSettingsRightBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const MainSettingsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const MainSettingsColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const MainSettingsColorBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const MainSettingsColorSelectWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const InfoText = styled.div`
  display: flex;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  gap: 4px;
  width: 100%;
  color: ${({ theme }) => theme.primaryText};
`;

export const MainSettingsTextTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  display: flex;
  gap: 8px;
  color: ${({ theme }) => theme.neutralText};
`;
export const MainSettingsColorTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  display: flex;
  gap: 8px;
  color: ${({ theme }) => theme.neutralText};
`;

export const MainSettingsTextSubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  display: flex;
  gap: 8px;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const MainSettingsBadge = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  padding: 3px 4px 2px 4px;
  gap: 0px;
  border-radius: 20px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  background: ${({ theme }) => theme.successBackground};
  color: ${({ theme }) => theme.successText};
  border: 1px solid ${({ theme }) => theme.successBackground};
`;

export const MainSettingsLine = styled.div`
  position: absolute;
  right: 42%;
  top: 70px;
`;
export const MainSettingsLineSecond = styled.div`
  position: absolute;
  right: 39%;
  top: 65px;
  transform: scaleY(-1);
`;

export const MainSettingsPalleteColorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const MainSettingsPalleteColorsTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const MainSettingsPalleteColorRow = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  gap: 8px;
  border-top: 1px solid ${({ theme }) => theme.neutralBorder};
`;

export const MainSettingsPalleteColorTitle = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme.neutralText};
`;

export const SquaresWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 2px;
`;

export const SquareColor = styled.div<{ $color?: string; }>`
  width: 20px;
  display: flex;
  gap: 2px;
  aspect-ratio: 1 / 1;
  border-radius: 4px;
  background: ${({ $color }) => $color};
`;
