import styled from "styled-components";

export const KPIWrapperMainContainer = styled.div<{
  $selected?: boolean;
  $recommended?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  height: 100%;
`;

export const KPIWrapperBorder = styled.div<{
  $borderRight?: boolean;
  $borderTop?: boolean;
}>`
  border-right: 1px solid
    ${({ theme, $borderRight }) =>
      $borderRight ? theme.neutralBorder : "transparent"};
  border-top: 1px solid
    ${({ theme, $borderTop }) =>
      $borderTop ? theme.neutralBorder : "transparent"};
  padding: 8px;
  height: 50%;
`;

export const KPIWrapperCol = styled.div<{
  $align: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ $align }) => $align};
`;

export const KPIWrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  width: 100%;
  height: 100%;
`;

export const KPISettings = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
