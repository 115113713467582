import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Submenu } from "../Submenu";
import {
  removePage,
  requestCreatePage,
  requestRemovePage,
  resetCurrentPageData,
} from "../../store/slices/projectPages";
import { GlobeIcon, OptionsIcon, PageItem, PageName, PageOptions } from "./styles";
import { setActiveModal } from "../../store/slices/modals";
import { getAllPages } from "../../store/selectors/projects";
import { getIsEditMode, getIsPublicMode } from "../../store/selectors/main";

interface PageTabInterface {
  name: string;
  tabId: string;
  isPublic?: boolean;
}

export const PageTab = ({ name, tabId, isPublic }: PageTabInterface) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id, pageId } = useParams();

  const isEditMode = useSelector(getIsEditMode);
  const pages = useSelector(getAllPages);
  const isPublicRoute = useSelector(getIsPublicMode);
  const [menuOpen, setMenuOpen] = useState(false);

  const onEdit = () => {
    navigate(`/projects/${id}/${tabId}`);
    setTimeout(() => {
      dispatch(setActiveModal({ id: "projectSettingsModal" }));
    }, 500)
  };

  const onRemove = () => {
    dispatch(removePage(tabId));
    dispatch(requestRemovePage({ id: tabId, navigate }));
  };

  const onDuplicate = () => {
    const duplicatePageSettings = pages.items.find(page => page.id === tabId)
    if (duplicatePageSettings) dispatch(requestCreatePage({ ...duplicatePageSettings, navigate }));
  };

  return (
    <>
      <PageItem $active={tabId === pageId} $menuOpen={menuOpen}>
        {isPublic && <GlobeIcon />}
        <PageName
          onClick={() => {
            dispatch(resetCurrentPageData())
            const path = isPublicRoute
              ? `/projects/public/${id}/${tabId}`
              : `/projects/${id}/${tabId}`;
            navigate(path);
          }}
        >
          {name}
        </PageName>
        {!isPublicRoute && isEditMode ? (
          <PageOptions
            onClick={() => {
              setMenuOpen((prevMenuOpen) => !prevMenuOpen);
            }}
          >
            <OptionsIcon />
          </PageOptions>
        ) : null}
        {menuOpen && (
          <Submenu
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            onEdit={onEdit}
            onRemove={onRemove}
            onDuplicate={onDuplicate}
            pageTabMenu
          />
        )}
      </PageItem>
    </>
  );
};
