import React from "react";
import { StoryTellingSection } from "../..";
import { PageContentGeneratorItemType } from "../../../../../../store/slices/pageContentGenerator";
import { WIDGETS } from "../../../../../Widgets/widgets";
import { PlaceHolder } from "../PlaceHolder";

interface Props {
  item: any;
  setContentItem: React.Dispatch<
    React.SetStateAction<PageContentGeneratorItemType | undefined>
  >;
  draggable?: boolean;
}

const SectionItem: React.FC<Props> = ({ item, setContentItem, draggable }) => {
  const ChartComponent = item && WIDGETS[item.chartType];

  if (item.sectionType === "map") {
    return (
      <StoryTellingSection
        draggable={draggable}
        item={item}
        setContentItem={setContentItem}
        disabledSettings
      >
        {ChartComponent ? (
          <ChartComponent
            key={item.blockId}
            storytelling
            showLegend={true}
            currentWidget={{ ...item, name: "" }}
          />
        ) : (
          <PlaceHolder />
        )}
      </StoryTellingSection>
    );
  }
  if (item.sectionType === "widget") {
    return (
      <StoryTellingSection
        draggable={draggable}
        item={item}
        setContentItem={setContentItem}
      >
        {ChartComponent ? (
          <ChartComponent
            key={item.blockId}
            storytelling
            showLegend={true}
            currentWidget={{ ...item, name: "" }}
          />
        ) : (
          <PlaceHolder />
        )}
      </StoryTellingSection>
    );
  }
  return <PlaceHolder />;
};

export default SectionItem;
