import styled from "styled-components";

export const PaginationButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 4px;
`;

export const PaginationCount = styled.div`
  font-size: 14px;
`;

export const PaginationWrapper = styled.div`
  display: flex; 
  align-items: center; 
  justify-content: center; 
  gap: 10px; 
  margin-top: 20px; 
  margin-bottom: 20px;
`;

export const GridWrapper = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

export const TablePageWrapper = styled.div`
  height: calc(100vh - 64px - 68px - 20px - 60px);
`;