export interface LayoutI {
  blockId?: number;
  rows?: RowI[];
  columns?: ColumnI[];
}

export interface RowI {
  columns: ColumnI[];
}

export interface ColumnI {
  blockId?: number;
  rows?: RowI[];
  columns?: ColumnI[];
}

export const extractBlockIds = (obj: LayoutI | undefined): LayoutI[] => {
  const blocks: LayoutI[] = [];

  function recursiveExtract(obj: LayoutI | undefined): void {
    if (obj?.blockId) {
      blocks.push(obj);
    }
    if (obj?.rows && Array.isArray(obj.rows)) {
      obj.rows.forEach((row: RowI) => {
        row.columns.forEach((column: ColumnI) => {
          recursiveExtract(column);
        });
      });
    }
    if (obj?.columns && Array.isArray(obj.columns)) {
      obj.columns.forEach((column: ColumnI) => {
        recursiveExtract(column);
      });
    }
  }

  recursiveExtract(obj);
  return blocks;
};
