export const areaChart = {
  id: "0af34a6a-40c8-4cc1-b2c2-6b50143fb92f",
  chartType: "areaChart",
  name: "Demographic Insights",
  pageId: "7d4fd91d-bc84-450f-b8eb-01cbb288fc19",
  userId: "04ec2f5a-74da-4955-acc3-8ce35e1b134f",
  datasetId: "236b72f4-a1d8-4e69-a6a6-62719bedec97",
  query:
    '[{"$match": {"datasetId": "236b72f4-a1d8-4e69-a6a6-62719bedec97"}}, {"$project": {"datasetId": "$datasetId", "year": {"$toInt": {"$cond": {"if": {"$eq": ["$data.year", ""]}, "then": null, "else": "$data.year"}}}, "value": {"$toDouble": {"$cond": {"if": {"$eq": ["$data.value", ""]}, "then": null, "else": "$data.value"}}}, "dimension-detail": "$data.dimension-detail", "dimension": "$data.dimension"}}, {"$match": {"dimension": {"$eq": "Delivery_System"}}}, {"$group": {"_id": {"year": "$year", "dimension-detail": "$dimension-detail"}, "value": {"$sum": "$value"}}}, {"$project": {"_id": 0, "data": {"year": "$_id.year", "Delivery_System": "$_id.dimension-detail", "value": "$value"}, "datasetId": "$_id.datasetId"}}]',
  legend: false,
  layout: null,
  background: null,
  search: null,
  layers: null,
  tooltip: false,
  markers: "disabled",
  showBoxPlot: false,
  orientation: "vertical",
  colors: null,
  formatting: null,
  xAxe: ["year"],
  yAxe: ["value"],
  groupBy: ["Delivery_System"],
  display: null,
  arrangeBy: null,
  palette: null,
  dataFormat: null,
  createdAt: "2024-04-17T09:19:01.761Z",
  updatedAt: "2024-04-17T09:19:01.761Z",
  data: [
    {
      year: "2023",
      Delivery_System: "Managed Care",
      value: 9482080,
    },
    {
      year: "2022",
      Delivery_System: "Managed Care",
      value: 6582080,
    },
    {
      year: "2021",
      Delivery_System: "Managed Care",
      value: 2923080,
    },
    {
      year: "2020",
      Delivery_System: "Managed Care",
      value: 3530800,
    },
    {
      year: "2019",
      Delivery_System: "Managed Care",
      value: 1554300,
    },
  ],
};
