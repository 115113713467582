import { StoreInterface } from "../../models";

export const getProjects = (store: StoreInterface) => store.allProjects;

export const getAllPages = (store: StoreInterface) =>
  store.currentProject.pagesData;

export const getLayout = (store: StoreInterface) =>
  store.currentProject.pagesData.currentPage.settings.templateId;

export const getInteractivityState = (store: StoreInterface) =>
  store.currentProject.pagesData.currentPage.settings.interactivity;

export const getPageSettings = (store: StoreInterface) =>
  store.currentProject.pagesData.currentPage.settings;

export const getPageBanner = (store: StoreInterface) =>
  store.currentProject.pagesData.currentPage.settings.header;

export const getPageId = (store: StoreInterface) =>
  store.currentProject.pagesData.currentPage.settings.id;

export const getDraftPageSettings = (store: StoreInterface) =>
  store.currentProject.pagesData.currentPage.draftSettings;

export const getCurrentProjectId = (store: StoreInterface) =>
  store.currentProject.id;

export const getCurrentProjectData = (store: StoreInterface) =>
  store.currentProject.projectData;

export const getCurrentPageWidgets = (store: StoreInterface) =>
  store.currentProject.pagesData.currentPage.widgets;

export const getCurrentPageKpi = (store: StoreInterface) =>
  store.currentProject.pagesData.currentPage.kpis;

export const getCurrentWidget = (store: StoreInterface) =>
  store.currentProject.widget;

export const getCurrentPageWidgetsItems = (store: StoreInterface) =>
  store.currentProject.pagesData.currentPage.widgets.items;

export const getCurrentPageId = (store: StoreInterface) =>
  store.currentProject.pagesData.currentPage;

export const getProjectsSearch = (store: StoreInterface) =>
  store.allProjects.search;

export const getCurrentProject = (store: StoreInterface) =>
  store.currentProject;
