import React, { useEffect, useRef, useState } from "react";

import {
  DropdownContainer,
  DropDownHeader,
  DropDownIcon,
  DropDownListContainer,
  DropdownWrapper,
  StyleSectionWrapper,
  Title,
} from "../styles";
import { colorsPalettes } from "../../../../constants";
import {
  DropDownList,
  DropDownPalettesColorItem,
  DropDownPalettesColorsWrapper,
  DropDownPalettesLabel,
  DropDownPalettesWrapper,
  PaletteColor,
  PaletteWrapper,
  RadioButtonActive,
  RadioButtonHovered,
  RadioButtonInactive,
  SeparatorLine,
} from "./styles";
import { useOutsideClick } from "../../../../hooks/useOutsideClick";
import { ComponentSettings } from "../../../../models/Pages";

export const StyleSection = ({
  settings,
  onUpdateSettings,
}: ComponentSettings) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState(
    settings.styleId || "default"
  );
  const currentColorPalette = colorsPalettes.find(
    (el) => el.id === selectedStyle
  );
  currentColorPalette?.colors.map(({ mainColor }) => mainColor);
  const toggling = () => setIsOpen(!isOpen);

  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (!settings.styleId) {
      onUpdateSettings({ ...settings, styleId: "default" });
    }
  }, [onUpdateSettings, settings]);

  return (
    <StyleSectionWrapper ref={ref}>
      <Title>Style</Title>
      <DropdownContainer $opened={isOpen}>
        <DropdownWrapper onClick={toggling}>
          <DropDownHeader>{currentColorPalette?.label}</DropDownHeader>
          <PaletteWrapper>
            {currentColorPalette?.colors.map(({ mainColor }) => (
              <PaletteColor key={mainColor} $color={mainColor} />
            ))}
          </PaletteWrapper>
          <DropDownIcon />
        </DropdownWrapper>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {colorsPalettes.map((palette, index) => (
                <React.Fragment key={index}>
                  <DropDownPalettesWrapper
                    onClick={() => {
                      setSelectedStyle(palette.id);
                      onUpdateSettings({ ...settings, styleId: palette.id });
                      setIsOpen(false);
                    }}
                    $activeItem={palette.id === selectedStyle}
                  >
                    {palette.id === selectedStyle ? (
                      <RadioButtonActive />
                    ) : (
                      <>
                        <RadioButtonInactive />
                        <RadioButtonHovered />
                      </>
                    )}
                    <DropDownPalettesLabel
                      $activeItem={palette.id === selectedStyle}
                    >
                      {palette.label}
                    </DropDownPalettesLabel>
                    <DropDownPalettesColorsWrapper>
                      {palette.colors.map(({ mainColor }, index) => (
                        <DropDownPalettesColorItem
                          $color={mainColor}
                          key={index}
                        />
                      ))}
                    </DropDownPalettesColorsWrapper>
                  </DropDownPalettesWrapper>
                  {index === 0 && <SeparatorLine />}
                </React.Fragment>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropdownContainer>
    </StyleSectionWrapper>
  );
};
