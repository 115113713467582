import styled from "styled-components";
import { ReactComponent as radioButtonActive } from "../../../../assets/radioButtonActive.svg";
import { ReactComponent as radioButtonInactive } from "../../../../assets/radioButtonInactive.svg";
import { ReactComponent as radioButtonHovered } from "../../../../assets/hoveredRadioButton.svg";

export const RadioButtonActive = styled(radioButtonActive)``;
export const RadioButtonInactive = styled(radioButtonInactive)``;
export const RadioButtonHovered = styled(radioButtonHovered)`
  display: none;
`;

export const PaletteWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
`;

export const PaletteColor = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  height: 12px;
  width: 26px;

  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const DropDownList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralText};
`;

export const DropDownListSeparator = styled.span``;
export const DropDownPalettesWrapper = styled.div<{ $activeItem: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  background-color: ${({ theme, $activeItem }) =>
    $activeItem && theme.neutralBackground};
  padding: 8px 4px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    ${RadioButtonHovered} {
      display: block;
    }
    ${RadioButtonInactive} {
      display: none;
    }

    background-color: ${({ theme }) => theme.neutralBackgroundHover};
  }
`;

export const SeparatorLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  margin: 5.5px 0;
`;
export const DropDownPalettesLabel = styled.div<{ $activeItem: boolean }>`
  color: ${({ theme, $activeItem }) =>
    $activeItem ? theme.neutralText : theme.neutralTextWeak};
  font-size: 14px;
  font-weight: ${({ $activeItem }) => ($activeItem ? 500 : 400)};
  flex: 1;
  padding-left: 8px;
`;

export const DropDownPalettesColorsWrapper = styled.div`
  display: flex;
  gap: 1px;
`;
export const DropDownPalettesColorItem = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  height: 12px;
  width: 16.3px;

  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
