/* eslint-disable array-callback-return */
import { useMemo, useState } from "react";
import { Group } from "@visx/group";
import { BarGroup } from "@visx/shape";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { timeParse, timeFormat } from "@visx/vendor/d3-time-format";
import { Grid } from "@visx/grid";
import useMeasure from "react-use-measure";
import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  SettingsButtonWrapper,
  Title,
} from "../VerticalBarchart/styles";
import { ChartLegend, ChartLegendValue } from "../../ChartLegend";
import { setActiveModal } from "../../../store/slices/modals";
import { useDispatch, useSelector } from "react-redux";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import { ticksFormatter } from "../../../helpers/ticksFormatter";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../store/selectors/projects";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import { AiSuggestionsDto, WidgetItem } from "../../../models/Widgets";
import { Tooltip } from "../Tooltip";
import { Loader } from "../../Loader";
import { calculateNumTicks } from "../widgetHelpers";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { getBarChartKeys } from "./utils/getKeys";
import { getCurrentColor } from "../utils/getCurrentMarker";
import { AVAILABLE_WIDGETS } from "../../../constants/widgetRecomended";
import { getClientPosition } from "../components/LabelTooltip";
import { createPortal } from "react-dom";
import { Rect } from "./styles";
import { getBarPosition } from "./utils/getBarPosition";
import { HeadingNameAndButton } from "../styles";
import { getActiveModal } from "../../../store/selectors/modals";

export type BarGroupProps = {
  storytelling?: boolean;
  currentWidget: WidgetItem;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  preview?: boolean;
};

type DataItem = {
  year: string;
  [key: string]: string;
  value: string;
};

export const GroupedBarChart = ({
  storytelling,
  recommended,
  currentWidget,
  showLegend = true,
  selected = false,
  hideName = false,
  preview = false,
}: BarGroupProps) => {
  const dispatch = useDispatch();

  const [ref, bounds] = useMeasure();
  const [refWidget, boundsWidget] = useMeasure();

  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const aiSuggestions = useSelector(getAiSuggestions);

  const [tooltip, setTooltip] = useState<{
    name?: string;
    data: { [key: string]: string };
    x: number;
    y: number;
  } | null>(null);
  const [hoveredBlock, setHoveredBlock] = useState<null | string>(null);

  const barChartSuggestion = aiSuggestions
    ?.filter((chart: AiSuggestionsDto) => chart.chartType === "barChart")
    .find((el: any) => el.groupBy !== null);

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  if (!(currentWidget && currentWidget?.data)) {
    return (
      <>
        <div style={{ height: "100%", width: "100%" }}>
          <Loader blur={false} />
        </div>
      </>
    );
  }

  const groupBy = currentWidget?.groupBy?.at(0);
  const data: any = currentWidget?.data;
  const margin = { top: 10, right: 5, bottom: 21, left: 40 };
  const width = bounds.width || 1084;
  const height = bounds.height || 300;

  const yMax = height - margin.top - margin.bottom;
  const xMax = width - margin.left - margin.right;

  const uniqueValuesKeys =
    (currentWidget?.uniqueValues &&
      Object.keys(currentWidget?.uniqueValues!)) ||
    [];
  const groupByKey =
    groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);

  const keys =
    uniqueValuesKeys?.length && currentWidget?.uniqueValues
      ? currentWidget?.uniqueValues[groupByKey!]
      : getBarChartKeys(currentWidget);

  const xAxe = currentWidget?.xAxe?.length
    ? currentWidget?.xAxe?.at(0)
    : barChartSuggestion?.xAxe?.at(0);
  const yAxe = currentWidget?.yAxe?.length
    ? currentWidget?.yAxe?.at(0)
    : barChartSuggestion?.yAxe?.at(0);

  const formattedData = data?.map((item: any) => {
    return {
      year: item[xAxe],
      ...keys?.reduce((acc: any, key: any) => {
        acc[key] = item[groupBy!] === key ? Number(item[yAxe]) : 0;
        return acc;
      }, {}),
    };
  });

  const parseDate = timeParse("%Y");
  const format = timeFormat("%Y");
  const formatDate = (date: string) => format(parseDate(date) as Date);
  const getYear = (d: DataItem) => d[xAxe];
  const getValue = (d: DataItem) => Number(d[yAxe]);

  const numBarsInGroup = keys?.length;
  const fixedBarWidth = numBarsInGroup > 2 ? 16 / (numBarsInGroup / 2.1) : 16;
  const fixedBarSpacing = 1;

  const totalSpaceRequiredPerGroup =
    numBarsInGroup * fixedBarWidth + (numBarsInGroup - 1) * fixedBarSpacing;
  const yearScale = scaleBand<string>({
    domain: data?.map(getYear),
    align: 0.5,
    range: [0, width - margin.left - margin.right],
  });

  const keysScale = scaleBand<string>({
    domain: keys,
    range: [0, totalSpaceRequiredPerGroup],
    paddingInner: 0.1,
    paddingOuter: 0,
  });

  const maxYAxe = Math.max(...data?.map(getValue));
  const valueScale = scaleLinear<number>({
    domain: [0, maxYAxe],
    range: [yMax, 0],
    nice: true,
  });

  const legendValues: ChartLegendValue[] = [];

  for (let i = 0; i < keys?.length; i++) {
    const dataKey = keys?.at(i);
    legendValues.push({
      label: dataKey!,
      color: getCurrentColor(currentWidget, dataKey!, styleId),
    });
  }

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: legendValues?.map((r) => r.color),
  });
  const numTicks = calculateNumTicks({ height: height });
  return (
    <>
      <HeaderWrapper ref={refWidget}>
        {!storytelling && (
          <HeadingNameAndButton>
            {!hideName ? <Title>{name}</Title> : <div />}
            {!isPublicRoute && !recommended && isEditMode && !storytelling ? (
              <SettingsButtonWrapper
                $modalOpen={
                  !!activeModal?.length &&
                  modalCurrentWidget?.id === currentWidget?.id
                }
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {AVAILABLE_WIDGETS["barChart"]?.length}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </HeadingNameAndButton>
        )}
        {legendValues?.length > 1 && showLegend && currentWidget.legend && (
          <ChartLegend
            chartWidth={boundsWidget.width}
            legendType="unit"
            legendValues={legendValues}
          />
        )}
      </HeaderWrapper>
      <svg ref={ref} width="100%" height="100%">
        <Grid
          top={margin.top}
          left={margin.left}
          xScale={yearScale}
          yScale={valueScale}
          width={xMax}
          height={yMax}
          stroke="#ccc"
          strokeOpacity={1}
          strokeDasharray="1 2"
          xOffset={yearScale.bandwidth() / 2}
        />

        <Group top={margin.top} left={margin.left}>
          <BarGroup
            data={formattedData}
            keys={keys}
            height={yMax}
            x0={(d) => d[xAxe]}
            x0Scale={yearScale}
            x1Scale={keysScale}
            yScale={valueScale}
            color={colorScale}
          >
            {(barGroups) => {
              const blockWidth = barGroups.find((item) => item.x0)?.x0 ?? 0;

              return barGroups.map((barGroup) => {
                return (
                  <Group
                    key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                    left={barGroup.x0 + blockWidth / 2}
                  >
                    {barGroup.bars.map((bar, index) => {
                      const legend = legendValues.find(
                        (r) => r.label === bar.key
                      );

                      const x =
                        0 -
                        bar.width / 2 +
                        getBarPosition(bar.width, bar.index, keys.length);

                      return (
                        <Rect
                          key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                          x={x}
                          y={bar.y + 1}
                          width={bar.width}
                          height={bar.height}
                          fill={legend?.color}
                          opacity={
                            hoveredBlock
                              ? hoveredBlock ===
                                `bar-group-${barGroup.index}-${barGroup.x0}-${index}`
                                ? 1
                                : 0.2
                              : 1
                          }
                          onMouseMove={(e: any) => {
                            const key = `bar-group-${barGroup.index}-${barGroup.x0}-${index}`;

                            if (
                              (showTooltip || currentWidget.tooltip) &&
                              !recommended
                            ) {
                              setHoveredBlock(key);
                              const { x, y } = getClientPosition(e);
                              const hoveredBarGroup =
                                formattedData[barGroup.index];

                              setTooltip({
                                name: bar.key,
                                data: {
                                  year: hoveredBarGroup[xAxe],
                                  [groupBy!]: keys[bar.index],
                                  value: bar?.value?.toString(),
                                },
                                x: x - 27,
                                y: y - 85,
                              });
                            }
                          }}
                          onMouseLeave={() => {
                            setTooltip(null);
                            setHoveredBlock(null);
                          }}
                        />
                      );
                    })}
                  </Group>
                );
              });
            }}
          </BarGroup>
        </Group>
        <AxisLeft
          scale={valueScale}
          top={margin.top + 1}
          left={margin.left}
          stroke="#e0e0e0"
          strokeDasharray="1,2"
          tickStroke="#e0e0e0"
          numTicks={numTicks}
          tickLineProps={{
            stroke: "#939BA7",
          }}
          tickLabelProps={{
            dx: -30,
            fontSize: 11,
            fill: "#5F6877",
            textAnchor: "start",
            dy: 4,
          }}
          tickFormat={(value: any) => {
            return ticksFormatter(value);
          }}
        />
        <AxisBottom
          top={yMax + margin.top + 1}
          tickFormat={formatDate}
          scale={yearScale}
          tickValues={yearScale.domain()}
          left={margin.left}
          stroke="#e0e0e0"
          tickStroke="#e0e0e0"
          hideTicks
          axisLineClassName="barchartAxisLine"
          tickLabelProps={(val) => {
            return {
              x: yearScale(val)! + yearScale.bandwidth() / 2,
              textAnchor: "middle",
              fontSize: 11,
              fill: "#5F6877",
            };
          }}
        />
      </svg>
      {tooltip &&
        createPortal(
          <Tooltip
            x={tooltip.x}
            y={tooltip.y}
            xAxe={xAxe ? xAxe : ""}
            yAxe={yAxe ? yAxe : ""}
            data={tooltip.data}
            name={tooltip.name}
          />,
          document.body
        )}
    </>
  );
};
