import { useDispatch, useSelector } from "react-redux";
import {
  KPISettings,
  KPIWrapperBorder,
  KPIWrapperCol,
  KPIWrapperMainContainer,
  KPIWrapperRow,
} from "./styles";
import { getIsEditMode, getIsPublicMode } from "../../store/selectors/main";
import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  SettingsButtonWrapper,
} from "../Widgets/VerticalBarchart/styles";
import { getActiveModal } from "../../store/selectors/modals";
import { setActiveModal } from "../../store/slices/modals";
import { useEffect, useState } from "react";
import { AiKpiDto } from "../../models/Widgets";
import { KPIComponentWrapper } from "../KPIComponentWrapper";
import { getAiKpis } from "../../store/selectors/widgets";

export interface KPISettingsWrapperProps {
  recommended: boolean;
  storytelling: boolean;
  kpis: AiKpiDto[];
}

export const KPISettingsWrapper = (props: KPISettingsWrapperProps) => {
  const dispatch = useDispatch();
  const { kpis, recommended, storytelling } = props;
  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const activeModal = useSelector(getActiveModal);
  const aiKpis = useSelector(getAiKpis);
  const [localKpis, setLocalKpis] = useState<AiKpiDto[][]>([]);

  useEffect(() => {
    if (kpis?.length) {
      let result = [];

      for (let i = 0; i < kpis.length; i += 2) {
        result.push(kpis.slice(i, i + 2));
      }
      setLocalKpis(result);
    }
  }, [kpis]);

  return (
    <KPIWrapperMainContainer>
      <KPIWrapperRow>
        {localKpis?.map((kpiRow, index) => {
          return (
            <KPIWrapperCol
              key={index}
              $align={kpis?.length > 1 ? "flex-end" : "flex-start"}
            >
              {kpiRow?.map((kpi, key) => {
                return (
                  <KPIWrapperBorder
                    $borderRight={index === 0 && localKpis?.length > 1}
                    $borderTop={key === kpiRow?.length - 1 && kpis?.length > 1}
                    key={kpi.id}
                  >
                    <KPIComponentWrapper
                      key={kpi.id}
                      kpi={kpi}
                      recommended={false}
                      selected={false}
                      size={key === 0 ? "sm" : "md"}
                    />
                  </KPIWrapperBorder>
                );
              })}
            </KPIWrapperCol>
          );
        })}
      </KPIWrapperRow>
      {!isPublicRoute && !recommended && isEditMode && !storytelling ? (
        <KPISettings>
          <SettingsButtonWrapper
            $modalOpen={!!activeModal?.length}
            onClick={() => {
              dispatch(setActiveModal({ id: "recommendedKpisModal" }));
            }}
          >
            <DatavizRecommendedCount>{aiKpis?.length}</DatavizRecommendedCount>
            <DatavizSettingsIcon />
          </SettingsButtonWrapper>
        </KPISettings>
      ) : null}
    </KPIWrapperMainContainer>
  );
};
