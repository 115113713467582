import React, { useMemo, useState } from "react";
import { Upload, CheckCircle } from "react-bootstrap-icons";

import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../Button";
import {
  ModalFooterWrapper,
  ModalContainer,
  ModalContent,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  Actions,
  OverviewContent,
  BackIcon,
} from "../styles";

import {
  removeAllSelectedFiles,
  resetNewProjectSelectedFile,
  setNewProjectSelectedFiles,
} from "../../../store/slices/datasets";
import {
  closeActiveModal,
  resetActiveModal,
  setActiveModal,
} from "../../../store/slices/modals";
import { DatasetLibraryList } from "../../DatasetLibraryList";
import { LibraryDatasetOverview } from "../LibraryDatasetOverview";
import { Search } from "../../SearchBar";
import {
  getAllDatasets,
  getNewProjectSelectedDatasets,
  getSelectedFiles,
} from "../../../store/selectors/datasets";
import { getModalBackOptions } from "../../../store/selectors/modals";
import { EmptyDatasetsPage } from "../../EmptyDatasets";
import { FileLibraryItem } from "../../../models/Files";

export const DatasetLibraryModal = () => {
  const dispatch = useDispatch();
  const [isClosing, setIsClosing] = useState(false);
  const [libraryDatasetOverviewOpen, setLibraryDatasetOverviewOpen] =
    useState(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [dimensionsSelected, setDimensionsSelected] = useState<any>([]);
  const allDatasets = useSelector(getAllDatasets);
  const selectedFiles = useSelector(getSelectedFiles);
  const projectSelectedDatasets = useSelector(getNewProjectSelectedDatasets);
  const modalBackOption = useSelector(getModalBackOptions);
  const libraryDatasetsIds: string[] = useMemo(() => { return [...selectedFiles.map((i) => i.id), ...projectSelectedDatasets.map((el) => String(el.sourceId))] }, [selectedFiles, projectSelectedDatasets]);

  const newProjectSelectedDatasets = useMemo(() => {
    return allDatasets.items
      .filter((dataset) => libraryDatasetsIds.includes(dataset.id || dataset.sourceId || ""))
      .map((dataset) => {
        const selectedDataset = selectedFiles.find(
          (file) => file.id === dataset.id
        );
        if (selectedDataset) {
          const selectedHeaders = selectedDataset.fields;
          return {
            ...dataset,

            fields: selectedHeaders,
          };
        }
        return null;
      })
      .filter((dataset) => dataset !== null);
  }, [allDatasets.items, libraryDatasetsIds, selectedFiles]);

  const handleOnClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      dispatch(removeAllSelectedFiles());
      dispatch(closeActiveModal());
      dispatch(resetNewProjectSelectedFile());
    }, 400);
  };
  return (
    <ModalOverlay $isClosing={isClosing} onClick={handleOnClose}>
      <ModalWrapper
        $isClosing={isClosing}
        onClick={(e) => e.stopPropagation()}
        $fileOverviewOpen={libraryDatasetOverviewOpen}
      >
        <ModalHeadingWrapper $fileOverviewOpen={libraryDatasetOverviewOpen}>
          <ModalContainer>
            {modalBackOption.includes("datasetLibraryModal") && (
              <BackIcon
                onClick={() => {
                  dispatch(closeActiveModal());
                  dispatch(removeAllSelectedFiles());
                  dispatch(setActiveModal({ id: "createProjectModal" }));
                }}
              />
            )}
            <ModalHeading>Dataset Library</ModalHeading>

          </ModalContainer>
          <Search
            width="xs"
            placeholder="Search"
            onChange={(value: string) => setSearchInput(value)}
          />
        </ModalHeadingWrapper>

        <OverviewContent>
          <ModalContent $fileOverviewOpen={libraryDatasetOverviewOpen}>
            {!allDatasets.items.length && <EmptyDatasetsPage />}
            <DatasetLibraryList
              setFileOverviewOpen={setLibraryDatasetOverviewOpen}
              searchInput={searchInput}
              setDimensionsSelected={setDimensionsSelected}
              libraryDatasetsIds={libraryDatasetsIds}
            />
          </ModalContent>
          {libraryDatasetOverviewOpen && (
            <LibraryDatasetOverview
              setLibraryDatasetOverview={setLibraryDatasetOverviewOpen}
              dimensionsSelected={dimensionsSelected}
            />
          )}
        </OverviewContent>

        <ModalFooterWrapper $fileOverviewOpen={libraryDatasetOverviewOpen}>
          <Button
            name="Cancel"
            onClick={handleOnClose}
            variant="neutral"
            size="medium"
          />
          <Actions>
            <Button
              name="Upload Dataset"
              onClick={() => {
                dispatch(removeAllSelectedFiles());
                dispatch(closeActiveModal());
                dispatch(
                  setActiveModal({ id: "uploadDatasetModal", back: true })
                );
              }}
              variant="primary"
              size="medium"
              icon={<Upload />}
            />
            <Button
              name="Add"
              onClick={() => {
                dispatch(
                  setNewProjectSelectedFiles(
                    newProjectSelectedDatasets.map((item) => {
                      return {
                        name: item?.name,
                        filePath: item?.filePath,
                        fields: item?.fields,
                        sourceId: item?.id,
                      };
                    }) as FileLibraryItem[]
                  )
                );
                dispatch(resetActiveModal());
                dispatch(setActiveModal({ id: "createProjectModal" }));
              }}
              variant={selectedFiles.length ? "secondary" : "disabled"}
              size="medium"
              icon={<CheckCircle />}
            />
          </Actions>
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};
