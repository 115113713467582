import styled, { css } from "styled-components";
import { Field } from "formik";

import backgroundImage from "../../../assets/acount-avatar.svg";

export const SwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const SwitchText = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme.neutralText};
`;
export const GapFlexBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const GapColumnBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
`;

export const GapFlexRowBlock = styled.div`
  display: flex;
  width: 100%;
  gap: 32px;
`;


export const RequiredStar = styled.div`
  position: absolute;
  z-index: 100;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.alertText};
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

export const ErrorMsg = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.alertText};
  padding-left: 10px;
`;

export const FieldWrapper = styled.div<{ $disableMargin?: boolean; }>`
  position: relative;
  width: 100%;
  ${({ $disableMargin }) => $disableMargin ? '' : 'margin-top: 8px'};
`;

export const UpdateModalWrapper = styled.div`
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  border: 1px solid ${({ theme }) => theme.primaryBorderColor};
  border-radius: 12px;
  width: 728px;
  height: 504px;
  position: absolute;
  right: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  box-shadow: 0px 8px 12px 4px rgba(20, 28, 44, 0.12),
    0px 2px 4px 0px rgba(20, 28, 44, 0.12);
  display: flex;
  flex-direction: column;
`;

export const UpdateModalHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const UpdateModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: auto;
  position: relative;
  padding: 16px 20px;
`;

export const AccountBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 20px 0 20px;
`;

export const UpdateTabs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorderColor};
`;

export const AccountAvatar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border-radius: 18px;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.neutralText};
  background-size: cover;
`;

export const AccountFullName = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.16px;
  margin-left: 8px;
  color: ${({ theme }) => theme.neutralText};
`;

export const UpdateTab = styled.div<{
  $selected?: boolean;
}>`
  font-size: 14px;
  font-style: normal;
  font-weight: ${({ $selected }) => ($selected ? "600" : "400")};
  line-height: 16px;
  padding: 10px 0;
  cursor: pointer;
  color: ${({ theme, $selected }) =>
    $selected ? theme.neutralText : theme.neutralWeak};
  border-bottom: 2px solid
    ${({ theme, $selected }) =>
    $selected ? theme.primaryBorder : "transparent"};
  margin-bottom: -1px;
`;

export const UpdateModalFooterWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.primaryBorderColor};
  padding: 16px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const EssentialsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const EssentialsBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
`;

export const FullNameBlock = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

export const FlexBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

export const FullNameBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  &:first-child {
    margin-top: 0;
  }
`;

export const ProfileLabel = styled.label`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.neutralText};
`;
export const TitleWrapper = styled.div<{
  $mt?: string;
}>`
  margin-top: ${({ $mt }) =>
    $mt ? $mt : 0
  };
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TitleText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.neutralText};
`;

export const SubTitleText = styled.div`
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const SlashButton = styled.div`
  display: flex;
  gap: 4px;
  color: ${({ theme }) => theme.primaryText};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  height: 20px;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

const inputStyle = css`
  height: 36px;
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.primaryBorderColor};
  outline: none;
  padding: 0 14px;

  &:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
  }

  &:focus {
    background-color: ${({ theme }) => theme.neutralBackgroundBase};
    border: 1px solid ${({ theme }) => theme.primaryBorder};
  }
`;

const fieldErrorState = css`
  background-color: ${({ theme }) => theme.alertBackground};
  border: 1px solid ${({ theme }) => theme.alertBackground};

  &:hover {
    background: ${({ theme }) => theme.alertBackground};
  }
`;

export const Input = styled(Field) <{ $hasValue?: boolean; $error: boolean; $required?: boolean }>`
  ${inputStyle};
  position: relative;
  background-color: ${({ theme, $hasValue }) =>
    $hasValue ? theme.neutralBackgroundBase : theme.neutralBackground};
  ${({ $error }) => ($error ? fieldErrorState : "")};
  ${({ $required }) => ($required ? 'padding-left: 20px' : "")};
  width: 100%;
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

export const UploadAvatar = styled.div<{
  $avatar?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 96px;
  width: 96px;
  border-radius: 48px;
  background-image: ${({ $avatar }) =>
    $avatar?.length ? `url(${$avatar})` : `url(${backgroundImage})`};
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 116%;
  color: ${({ theme }) => theme.neutralText};
`;

export const UploadButton = styled.label`
  margin-top: 20px;
  display: flex;
  padding: 10px;
  height: 36px;
  width: 120px;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  background-color: ${({ theme }) => theme.primaryBackgroundPublish};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryText};
  cursor: pointer;
`;

export const UploadMime = styled.div`
  margin-top: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  max-width: 120px;
  color: ${({ theme }) => theme.neutralWeak};
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const InputBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const InputBlockFixed = styled.div`
  width: 160px;
`;
