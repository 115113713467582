export function hexToRGBA(hex: string, alpha: number) {
  hex = hex?.replace("#", "");
  let r = parseInt(hex?.substring(0, 2), 16);
  let g = parseInt(hex?.substring(2, 4), 16);
  let b = parseInt(hex?.substring(4, 6), 16);
  alpha = alpha || 1.0;
  if (alpha < 0 || alpha > 1.0) {
    alpha = 1.0;
  }
  if (r || g || b) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else return;
}

export function hexaToRgba(hex: string) {
  hex = hex.replace('#', '');

  let r, g, b, a;

  if (hex.length === 8) {
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
      a = parseInt(hex.substring(6, 8), 16) / 255;
  } else if (hex.length === 6) {
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
      a = 1;
  } else {
      throw new Error('Invalid hex color format. Use #RRGGBB or #RRGGBBAA.');
  }

  return `rgba(${r}, ${g}, ${b}, ${a})`;
}
