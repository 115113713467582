import styled from "styled-components";

export const LegendWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
`;

export const UnitLegend = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
`

export const LegendItemWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const CountLegendModal = styled.div<{ $left: number, $top: number }>`
  position: absolute;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 8px 12px;
  gap: 8px;
  z-index: 1000;
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  width: max-content;
  top: ${({ $top }) => $top+ 'px'} ;
  left: ${({ $left }) => $left + 'px'};
  &::before {
    content: "";
    position: absolute;
    top:-10px;
    z-index: 10;
    left:7px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid white;
  }
  &::after {
    content: "";
    position: absolute;
    top:-12px;
    z-index: 9;
    left:5px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 12px solid ${({ theme }) => theme.neutralBorder};
  }

`
export const CountLegendItems = styled.div`
  background: ${({ theme }) => theme.neutralBackground};
  color: ${({ theme }) => theme.neutralTextWeak};
  padding: 1px 3px;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px; 
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  position: relative;
`

export const LegendItemColor = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  height: 8.75px;
  width: 8.75px;
  border-radius: 10px;
`;
export const LegendItemName = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const LegendPaletteWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 12px;
  font-weight: 400;
  gap: 8px;
  padding-top: 8px;
  padding-bottom: 12px;
`;

export const LegendPalette = styled.div`
  display: flex;
  align-items: center;
`;

export const LegendPaletteColor = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  width: 14px;
  height: 8px;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
