import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./sagas";

import appReducer from "./slices/appConfig";
import datasetsReducer from "./slices/datasets";
import modalReducer from "./slices/modals";
import authReducer from "./slices/auth";
import projectsReducer from "./slices/projects";
import filesReducer from "./slices/files";
import currentProjectReducer from "./slices/projectPages";
import pageContentGenerator from "./slices/pageContentGenerator";
import widgets from "./slices/widgets";

const persistConfig = {
  key: "root",
  storage,
};
const projectsPersistConfig = { ...persistConfig, key: "projects" };
const authPersistConfig = { ...persistConfig, key: "auth" };

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  appConfig: persistReducer(persistConfig, appReducer),
  datasets: datasetsReducer,
  modals: modalReducer,
  allProjects: persistReducer(projectsPersistConfig, projectsReducer),
  currentProject: currentProjectReducer,
  files: filesReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  pageContentGenerator,
  widgets,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware),
});

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
