import { Color } from "react-color";
import styled from "styled-components";
import { Sizes } from "./sizes/Sizes.interface";

export const KPIMainContainer = styled.div`
  display: flex;
  gap: 1px;
  flex-direction: column;
  position: relative;
`;

export const KPIDataContainer = styled.div`
  display: flex;
  align-items: end;
  color: ${({ theme }) => `${theme.neutralText}`};
`;

export const KPILegendContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 2px;
  color: ${({ theme }) => `${theme.neutralTextWeak}`};
  gap: 6px;
`;

export const KPISubValues = styled.div<{
  $margin: string;
}>`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 1px;
  margin-bottom: ${({ $margin }) => $margin};
`;

export const KPILabel = styled.div<{
  $sizes: Sizes["label"];
}>`
  font-size: ${({ $sizes }) => $sizes.fontSize};
  font-weight: 400;
  line-height: ${({ $sizes }) => $sizes.lineHeight};
  letter-spacing: -0.01em;
  text-align: left;
`;

export const KPIValue = styled.div<{
  $sizes: Sizes["value"];
}>`
  font-size: ${({ $sizes }) => $sizes.fontSize};
  font-weight: 600;
  line-height: ${({ $sizes }) => $sizes.lineHeight};
  letter-spacing: -0.05em;
  text-align: left;
`;

export const KPIPercentage = styled.div<{
  $sizes: Sizes["subValues"];
}>`
  font-size: ${({ $sizes }) => $sizes.fontSize};
  font-weight: 400;
  line-height: ${({ $sizes }) => $sizes.lineHeight};
  letter-spacing: -0.01em;
  text-align: left;
  color: ${({ theme }) => `${theme.neutralTextWeak}`};
`;

export const KPILegendDot = styled.div<{
  $color: Color;
}>`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: ${({ $color }) => $color.toString()};
`;

export const KPILegendText = styled.div`
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  color: ${({ theme }) => `${theme.neutralTextWeak}`};
`;

export const KPISubTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  color: ${({ theme }) => `${theme.neutralTextWeak}`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 115px;
`;

export const Dot = styled.div`
  width: 2px;
  height: 2px;
  border-radius: 100%;
  background: #939ba7;
`;

export const KPIDropDown = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const KPIDropDownWrapper = styled.div`
  padding: 8px;
  border-radius: 6px;
  background: ${({ theme }) => `${theme.neutralBackgroundBase}`};
  border: ${({ theme }) => `1px solid ${theme.neutralBorder}`};
`;

export const KPIDropDownItem = styled.option<{
  $active?: boolean;
}>`
  padding: 6px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  background: ${({ theme }) => `${theme.neutralBackgroundBase}`};
`;
