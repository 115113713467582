import { FillLayer, LineLayer } from "mapbox-gl";

export const getLayerStyle = (paintColors: string[]): FillLayer => ({
  id: "county-fill-layer",
  type: "fill",
  source: "counties",
  paint: {
    "fill-color": [
      "match",
      ["get", "county"],
      ...paintColors,
      "rgba(72, 70, 91, 0.15)",
    ],
    "fill-opacity": 1,
  },
});

export const getLayerStrokeStyle = (selectedCountyId: string | null, showTooltip: boolean): LineLayer => ({
  id: "county-stroke-layer",
  type: "line",
  source: "counties",
  ...(showTooltip
    ? {
      paint: {
        "line-color": ["case", ["==", ["get", "county"], selectedCountyId], "#000000", "#FFFFFF"],
        "line-width": ["case", ["==", ["get", "county"], selectedCountyId], 2, 1],
        "line-offset": ["case", ["==", ["get", "county"], selectedCountyId], 1, 0],
        "line-opacity": 1,
      }
    }
    : {
      paint: {
        "line-color": "#FFFFFF",
        "line-width": 1,
      }
    }
  )

});