import styled from "styled-components";

import { ArrowClockwise } from "react-bootstrap-icons";

export const ErrorPageWrapper = styled.div`
  display: flex;
  height: calc(100vh - 64px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ErrorImage = styled.img`
  height: 160px;
  width: 160px;
`;

export const RefreshImage = styled(ArrowClockwise)``;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 443px;
  text-align: center;
  gap: 8px;
  padding: 24px 0;
`;

export const ErrorTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.neutralText};
`;

export const ErrorMessage = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
`;
