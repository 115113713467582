import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import Map, { Source, Layer, FillLayer } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import { MapLegend } from "../../MapLegend";
import { setActiveModal } from "../../../store/slices/modals";
import { getPageSettings } from "../../../store/selectors/projects";
import { colorsPalettes } from "../../../constants";
import { MapYear } from "../../MapYear";
import {
  MarkersVisualisationDataDto,
  WidgetItem,
} from "../../../models/Widgets";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import { ColorRangeI } from "../../../models/Pages";
import { Loader } from "../../Loader";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import dataCounties from "./Config/GeoJson/us-counties.json";
import { DatavizRecommendedCount, DatavizSettingsIcon, SettingsButtonWrapper } from "../VerticalBarchart/styles";
import { DisableBuildingsWrapper, LoaderWrapper, MapWrapper, SelectBageBlock } from "./styles";
import { getLayerStrokeStyle, getLayerStyle } from "./utils/getLayerStyle";
import { getIDofCounty } from "./utils/getIdOfCounty";
import { hexToRGBA } from "../../../helpers/hexToRgba";
import { generateBreakPoints } from "../../../helpers/generateBreakPoints";
import { AVAILABLE_WIDGETS } from "../../../constants/widgetRecomended";
import { SelectBage } from "../SelectBage";
import { calculateValuesAndSum } from "./utils/calculateValuesAndSum";
import { getYears } from "./utils/getYears";
import { getLayerColor } from "./utils/getLayerColor";
import { getGeoJsonData } from "./utils/getGeoJsonData";
import { Popup } from "./components/Popup";
import { getCountyDataFromId } from "./utils/getCountyDataFromId";
import { getValueWithDecimals } from "./utils/getValueWithDecimals";
import SwitchComponent from "../../Inputs/CustomSwitch/Switch";
import { changeLayerVisibility } from "./utils/disableLayer";

interface MapChartInterface {
  showSettings?: boolean;
  showLegend?: boolean;
  recommended?: boolean;
  selected?: boolean;
  isFullScreen?: boolean;
  currentWidget: WidgetItem;
}

export const UniversalMap = ({
  showSettings = true,
  currentWidget,
  recommended,
  selected,
}: MapChartInterface) => {
  const dispatch = useDispatch();
  const mapRef = useRef<any>();
  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const { styleId, showTooltip } = useSelector(getPageSettings);

  const colorPalette = colorsPalettes.find(palette => palette.id === (styleId || "default"));
  const colorPaletteVariations = colorPalette?.colors[0].variations;

  const [layerStyle, setLayerStyle] = useState<FillLayer>(getLayerStyle([]));
  const [layerData, setLayerData] = useState<MarkersVisualisationDataDto[]>([]);
  const [years, setYears] = useState<number[]>([]);
  const [year, setYear] = useState<number | undefined>();
  const [average, setAverage] = useState<number | undefined>();
  const [colorRanges, setColorRanges] = useState<ColorRangeI[]>([]);
  const [popupData, setPopupData] = useState<{ x: number; y: number; countyStateCode?: string; year?: string; value?: string; } | null>(null);
  const [selectedCounty, setSelectedCounty] = useState<string | null>(null);
  const [values, setValues] = useState<number[]>([]);
  const [leftSlider, setLeftSlider] = useState(0);
  const [rightSlider, setRightSlider] = useState(6);
  const [disableBuildings, setDisableBuildings] = useState<boolean>(false);

  const usCounties: GeoJSON.GeoJsonProperties = dataCounties;

  const id = useMemo(() => (recommended ? "recommended_map" : "map"), [recommended]);

  const generateColorRanges = useCallback(() => {
    if (colorPaletteVariations?.length && values.length) {
      const colors = colorPaletteVariations.slice().reverse();
      const sortedValues = [...new Set(values)].sort();
      const steps = generateBreakPoints(sortedValues);
      const newColorRanges = steps?.map((c, index) => {
        const end = index === steps.length - 1 ? c * 3 : steps[index + 1];
        return {
          color: index >= leftSlider && index <= rightSlider ? colors[index] : "#E0DDDC",
          start: parseInt(c.toString()),
          end: parseInt(end.toString()),
        };
      });
      setColorRanges(newColorRanges);
    }
  }, [colorPaletteVariations, values, leftSlider, rightSlider]);

  useEffect(() => {
    if (colorPaletteVariations?.length) generateColorRanges();
  }, [colorPaletteVariations, values, generateColorRanges]);

  useEffect(() => {
    if (currentWidget) {
      const layerData = currentWidget?.layers?.at(0)?.data || [];
      const { values, sum } = calculateValuesAndSum(layerData);
      setValues(values);
      setAverage(sum / (layerData.length || 1));
      setLayerData(layerData);
      getYears(layerData, setYear, setYears);
    }
  }, [currentWidget]);

  const geoJsonData: GeoJSON.FeatureCollection<GeoJSON.Geometry> = useMemo(() => getGeoJsonData(usCounties), [usCounties]);

  useEffect(() => {
    const filteredData = layerData?.filter(d => parseInt(d.year) === year);
    if (filteredData && colorRanges.length) {
      const styles = filteredData.map(county => {
        const id = getIDofCounty(county);
        const color = getLayerColor(colorRanges, county);
        return [id, hexToRGBA(color, 1)];
      }).flat();
      setLayerStyle(getLayerStyle(styles));
    }
  }, [year, layerData, colorRanges]);

  useEffect(() => {
    const map = mapRef?.current?.getMap();
    if (map) {
      map.on('load', () => {
        changeLayerVisibility("building (1)", map)
        changeLayerVisibility("country-label", map)
      })
      const onMouseMove = (e: any) => {
        const features = map.queryRenderedFeatures(e.point, { layers: ["county-fill-layer"] });
        if (features.length) {
          const feature = features[0];
          const countyPropreties = getCountyDataFromId(feature.properties.county);
          const [county, state] = countyPropreties.COUNTY_STATE_NAME.split(', ');
          const countyData = layerData.find(el => el.county === county && el.state === state);

          if (!countyData) {
            setSelectedCounty(null);
            setPopupData(null);
            return;
          }

          const point = map.project(e.lngLat);
          setSelectedCounty(feature.properties.county);
          setPopupData({
            x: point.x,
            y: point.y,
            countyStateCode: countyPropreties.COUNTY_STATE_CODE,
            value: getValueWithDecimals(countyData),
            year: countyData.year,
          });
        }
      };

      const onMouseLeave = () => {
        setSelectedCounty(null);
        setPopupData(null);
      };

      map.on("mousemove", "county-fill-layer", onMouseMove);
      map.on("mouseleave", "county-fill-layer", onMouseLeave);

      return () => {
        map.off("mousemove", "county-fill-layer", onMouseMove);
        map.off("mouseleave", "county-fill-layer", onMouseLeave);
      };
    }
  }, [mapRef, layerData, year]);

  useEffect(() => {
    const map = mapRef?.current?.getMap();
    if (map && map.isStyleLoaded()) {
      if (!disableBuildings) {
        changeLayerVisibility("building (1)", map)
      }
      if (disableBuildings) {
        changeLayerVisibility("building (1)", map, 'visible')
      }
    }
  }, [disableBuildings, mapRef])

  return (
    <>
      <MapWrapper>
        <Map
          id={id}
          ref={mapRef}
          initialViewState={{
            latitude: 35,
            longitude: -120,
            zoom: 4,
          }}
          style={{ position: "absolute", width: "100%", height: "100%", top: 0, left: 0 }}
          mapStyle="mapbox://styles/andyk1987/clnefgdzy01ze01qne2766ihi"
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        >
          <Source id="counties" type="geojson" data={geoJsonData}>
            <Layer beforeId="road" {...layerStyle} />
          </Source>
          <Source id="counties" type="geojson" data={geoJsonData}>
            <Layer beforeId="road" {...getLayerStrokeStyle(selectedCounty, !!showTooltip)} />
          </Source>
        </Map>
      </MapWrapper>
      {layerData?.length ? (
        <>
          {!isPublicRoute && isEditMode && !recommended ? (
            <SettingsButtonWrapper
              $modalOpen={!showSettings}
              $map
              onClick={() => {
                dispatch(setCurrentWidget(currentWidget!));
                dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
              }}
            >
              <DatavizRecommendedCount>
                {AVAILABLE_WIDGETS["mapChart"]?.length}
              </DatavizRecommendedCount>
              <DatavizSettingsIcon />
            </SettingsButtonWrapper>
          ) : null}
          <MapLegend
            colorRanges={colorRanges}
            average={average!}
            leftSlider={leftSlider}
            rightSlider={rightSlider}
            setLeftSlider={setLeftSlider}
            setRightSlider={setRightSlider}
          />
          <DisableBuildingsWrapper $years={!!years?.length}>
            <SwitchComponent
              label={!disableBuildings ? "2D" : "3D"}
              onChange={() => setDisableBuildings(!disableBuildings)}
              values={disableBuildings}
            />
          </DisableBuildingsWrapper>
          {years?.length ? (
            <MapYear years={years} setYear={setYear} selectedYear={year!} />
          ) : null}
          {recommended ? (
            <SelectBageBlock>
              <SelectBage selected={!!selected} />
            </SelectBageBlock>
          ) : null}
          {showTooltip && popupData && mapRef.current?.getContainer() && (
            <Popup popupData={popupData} mapContainer={mapRef.current.getContainer()} />
          )}
        </>
      ) : (
        <LoaderWrapper>
          <Loader blur={false} />
        </LoaderWrapper>
      )}
    </>
  );
};
