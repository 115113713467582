import { generateBreakPoints } from "../../../../helpers/generateBreakPoints";
import { ColorRangeI } from "../../../../models/Pages";

export const generateColorRanges = (
  variations: string[],
  values: number[],
  setColorRanges: React.Dispatch<React.SetStateAction<ColorRangeI[]>>
) => {
  if (!variations?.length || !values?.length) {
    setColorRanges([]);
    return;
  }
  const colorRanges = [];
  const sortedValues = [...new Set(values)].sort();
  const steps = generateBreakPoints(sortedValues);
  for (let i = 0; i < steps?.length; i++) {
    const end = i === steps?.length - 1 ? steps[i] * 3 : steps[i + 1];
    const colorRange = {
      color: variations[i],
      start: parseInt(steps[i]?.toString()),
      end: parseInt(end?.toString()),
    };
    colorRanges.push(colorRange);
  }
  setColorRanges(colorRanges);
};
