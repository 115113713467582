import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Row = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.neutralBorder}`};
  display: flex;
  &:last-child {
    border: none;
  }
`;

export const Dashed = styled.div`
  border-right: 1px dashed #939ba7;
  position: absolute;
  right: 0;
  width: 1px;
  height: 40px;
  top: 70%;
  transform: translateY(-70%);
`;

export const RowItem = styled.div<{
  $full?: boolean;
  $noBorder?: boolean;
  $spaces?: boolean;
}>`
  border-right: ${({ theme, $noBorder }) =>
    $noBorder ? "" : `1px solid ${theme.neutralBorder}`};
  flex: ${({ $full }) => ($full ? 1 : 2)};
  flex-direction: column;
  padding: 12px 16px;
  display: flex;
  position: relative;
  padding-left: ${({ $spaces }) => ($spaces ? "25px" : "16px")};

  &:last-child {
    border: none;
  }
`;

export const Title = styled.h1`
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
`;
export const DataBlocks = styled.div<{
  $space?: boolean;
}>`
  display: flex;
  margin-top: auto;
  padding-left: ${({ $space }) => ($space ? "10px" : "0")};
`;
export const Data = styled.div<{
  $separator?: boolean;
  $full?: boolean;
}>`
  flex: ${({ $full }) => ($full ? 1 : 2)};
  display: flex;
  flex-direction: column;
  border-right: ${({ $separator }) => ($separator ? "1px dashed #939BA7" : "")};
`;

export const MainData = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 5px;
`;

export const MainDataText = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.03em;
  text-align: left;
`;
export const MainDataSymbol = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-bottom: 7px;
`;

export const SubTitle = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
`;

export const ArrowIndicator = styled.div<{
  $left?: boolean;
}>`
  position: absolute;
  right: 3px;
  top: 60%;
  ${({ $left }) => ($left ? "left: 3px;" : "")}
  transform: translateY(-60%) scale(1) rotateY(${({ $left }) =>
    $left ? "3.142rad" : "0rad"});
`;
