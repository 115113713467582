import styled from "styled-components";
import { PlusCircle, XLg } from "react-bootstrap-icons";
import { animateMenuOpen } from "../../styles";

export const Plus = styled(PlusCircle)`
  path {
    fill: ${({ theme }) => theme.primaryText};
  }
`;

export const Close = styled(XLg)`
  path {
    fill: ${({ theme }) => theme.neutralText};
  }
`;

export const AddSectionSeparator = styled.div<{ $menuOpen: boolean, $hover: boolean }>`
  content: "";
  height: 1px;
  width: 50%;
  background: ${({ theme, $hover }) => $hover ? theme.primaryBorder : theme.neutralBorder};
  display: ${({ $menuOpen }) => ($menuOpen ? "block" : "none")};
  transition: all 0.15s linear;
`;

export const AddSectionButton = styled.div<{ $menuOpen: boolean }>`
  flex: none;
  padding: 0 8px;
  display: ${({ $menuOpen }) => ($menuOpen ? "block" : "none")};
`;

export const AddSectionWrapper = styled.div<{ $menuOpen: boolean }>`
  height: ${({ $menuOpen }) => ($menuOpen ? "26px" : "8px")};
  margin-top: 8px;
  display: flex;
  align-items: center;
  transition: all 0.15s linear;
  &:hover {
    height: 26px;
    ${AddSectionSeparator} {
      display: block;
    }
    ${AddSectionButton} {
      display: block;
    }
  }
`;

export const SectionTypeMenuWrapper = styled.div<{
  $menuOpen?: boolean;
}>`
  height: 94px;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  gap: 16px;
  animation: ${({ $menuOpen }) => $menuOpen && animateMenuOpen} 0.3s ease
    forwards;
`;

export const SectionTypeIcon = styled.div`
  height: 72px;
  width: 128px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SectionTypeName = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralText};
`;

export const SectionType = styled.div<{
  $disabled?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: ${({ $disabled }) => $disabled ? 'default' : 'pointer'};
  ${({ $disabled, theme }) => $disabled ? '' : `
    &:hover {
      ${SectionTypeIcon} {
        transform: scale(1.01);
        box-shadow: 0px 2px 4px 1px #141C2C0A;
      }
      ${SectionTypeName} {
        font-weight: 500;
      }
    }

    &:active {
      ${SectionTypeIcon} {
        transform: scale(1.01)
        box-shadow: none;
        border-left: 2px solid ${theme.primaryBorder};
        border-right: 2px solid ${theme.primaryBorder};
        border-top: 1px solid ${theme.primaryBorder};
        border-bottom: 1px solid ${theme.primaryBorder};
        
      }
      ${SectionTypeName} {
        font-weight: 500;
      }
    }
  `}
`;
