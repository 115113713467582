import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Bell, BellFill, Share } from "react-bootstrap-icons";

import {
  HeaderContainer,
  LinkContainer,
  MenuWrapper,
  NavLinkButton,
  NotificationAlert,
  NotificationIcon,
  ProjectNameTitle,
  ProjectNameWrapper,
  ProjectTitleAction,
  VerticalSeparator,
  ProjectTitleWrapper,
  Status,
  StatusTitle,
} from "./styles";
import { Search } from "../SearchBar";
import { Button } from "../Button";
import { Logo } from "../../assets";
import { UserDetails } from "../UserDetails";
import { Submenu } from "../Submenu";
import {
  requestDeleteProject,
  setProjectsSearch,
} from "../../store/slices/projects";
import { getUser } from "../../store/selectors/main";
import { getCurrentProjectData } from "../../store/selectors/projects";
import { setActiveModal } from "../../store/slices/modals";
import SwitchIconComponent from "../Inputs/CustomSwitchIcon/Switch";
import { useDebounce } from "../../hooks/useDebounce";
import { setIsEditMode } from "../../store/slices/appConfig";

export const NavBar = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isEditMode, setProjectEditMode] = useState(false);
  const [searchInput, setSearchInput] = useState<string>("");

  const user = useSelector(getUser);
  const project = useSelector(getCurrentProjectData);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const hiddenRoutePattern = /^\/projects\/.+/;
  const currentId = currentPath.split("/projects/")[1]?.split("/")[0];

  const debounceValue = useDebounce(searchInput, 1000);

  useEffect(() => {
    dispatch(setProjectsSearch(debounceValue));
  }, [debounceValue, dispatch]);

  useEffect(() => {
    setProjectEditMode(true);
    dispatch(setIsEditMode(true));
  }, [currentId, dispatch]);

  const removeProject = () => {
    dispatch(requestDeleteProject({ id: currentId, navigate }));
    navigate("/");
    setMenuOpen(false);
  };

  const onDuplicate = () => { };

  if (location.pathname === "/sign-in") return null;
  if (location.pathname === "/sign-up") return null;
  if (location.pathname === "/forgot-password") return null;

  const onEdit = () => {
    dispatch(
      setActiveModal({ id: "createProjectModal", projectId: currentId })
    );
  };

  const setEditMode = () => {
    const nEditMode = isEditMode
    setProjectEditMode(!nEditMode);
    dispatch(setIsEditMode(!nEditMode));
  };

  return (
    <HeaderContainer>
      <NavLink to="/">
        <img width="138" src={Logo} alt="Logo" />
      </NavLink>
      {hiddenRoutePattern.test(currentPath) && (
        <>
          <ProjectNameWrapper>
            <VerticalSeparator $notVisible={!!!user} />

            <ProjectTitleWrapper>
              <ProjectNameTitle>{project?.name}</ProjectNameTitle>
              {project?.isPublic && user && (
                <Status $size={11}>
                  <StatusTitle>Published</StatusTitle>
                </Status>
              )}
              {isEditMode && user && (
                <ProjectTitleAction
                  onClick={() => {
                    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
                  }}
                />
              )}
              {isEditMode && menuOpen && (
                <Submenu
                  projectMenu
                  menuOpen={menuOpen}
                  onEdit={onEdit}
                  setMenuOpen={setMenuOpen}
                  onRemove={removeProject}
                  onDuplicate={onDuplicate}
                />
              )}
            </ProjectTitleWrapper>
          </ProjectNameWrapper>
          {user && (
            <SwitchIconComponent
              onChange={setEditMode}
              onBlur={() => { }}
              checked={isEditMode}
              icon={isEditMode ? "editActive" : "editDisabled"}
            />
          )}

          <Button
            onClick={() => {
              dispatch(setActiveModal({ id: "shareProjectModal" }));
            }}
            variant="neutral"
            size="medium"
            name="Share"
            icon={<Share />}
          />
        </>
      )}

      {!hiddenRoutePattern.test(currentPath) && (
        <LinkContainer>
          <NavLinkButton to="/projects">Projects</NavLinkButton>
          <NavLinkButton to="/datasets">Datasets</NavLinkButton>
          <NavLinkButton to="/community">Community</NavLinkButton>
        </LinkContainer>
      )}
      {
        user && <MenuWrapper>
          {!hiddenRoutePattern.test(currentPath) && (
            <Search
              width="medium"
              onChange={(value: string) => setSearchInput(value)}
            />
          )}

          <VerticalSeparator $right={-10} />
          <NotificationIcon
            $showAlert={showAlert}
            onClick={() => {
              setShowAlert(!showAlert);
            }}
          >
            {showAlert ? <BellFill /> : <Bell />}
            {showAlert ? <NotificationAlert>8</NotificationAlert> : null}
          </NotificationIcon>
          <UserDetails />
        </MenuWrapper>
      }

    </HeaderContainer>
  );
};
