import { MapboxOptions } from "mapbox-gl";

export const mapConfig: MapboxOptions = {
  container: "map",
  style: "mapbox://styles/andyk1987/clv3kwhbo00ei01qp815c1w24",
  center: [-73.94944251984575, 40.694399420333496],
  zoom: 10,
  pitch: 0,
  maxZoom: 25,
  minZoom: 3,
};
