import styled from "styled-components";

export const ButtonsWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  gap: 8px;
  z-index: 3;
  display: none;
`;

export const ImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50%;
  cursor: pointer;
  border-radius: 12px;
  background: linear-gradient(
    to bottom,
    rgba(20, 28, 44, 0.2) 0%,
    rgba(20, 28, 44, 0) 100%
  );
  transition: opacity 0.4s ease;
  opacity: 0;
`;

export const ImageContainer = styled.img<{
  $showDescription: boolean;
  $blur: boolean;
}>`
  ${({ $blur }) => ($blur ? `filter: blur(3px);` : ``)}

  display: flex;
  flex-flow: column wrap;
  min-height: 192px;
  width: 100%;
  border-radius: 12px;
  align-self: center;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  transition: height 0.4s ease;
  object-fit: cover;
  object-position: top;
  transition: 0.3s;
  ${({ $showDescription }) =>
    $showDescription &&
    `
    min-height: 170px;
  `}
`;

export const ProjectContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 292px;
  overflow: hidden;
  min-width: 477px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 12px;

  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.neutralBorder};
    ${ButtonsWrapper} {
      display: flex;
    }
    ${ImageOverlay} {
      opacity: 1;
    }
  }
  @media (min-width: 1728px) {
    min-width: 440px;
    width: 100%;
  }
`;

export const Description = styled.div<{ $showDescription: boolean }>`
  font-size: 12px;
  font-weight: 400;
  opacity: 0;
  padding-bottom: 30px;
  transition: opacity 0.3s ease;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 28px;
  ${({ $showDescription }) =>
    $showDescription &&
    `
    opacity: 1;
  `};
`;

export const WrapperContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 12px;
  transition: padding 0.3s ease;
  height: 104px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const Title = styled.div<{ $size: number }>`
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${({ $size }) => `${$size}px `};
  font-weight: 600;
  display: flex;
  align-items: center;
  padding-bottom: 2px;
`;

export const Label = styled.div<{ $size: number }>`
  font-size: ${({ $size }) => `${$size}px `};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 0 4px;
  background: ${({ theme }) => theme.neutralBackground};
  color: ${({ theme }) => theme.neutralTextWeak};
  border-radius: 24px;
`;

export const Status = styled.div<{ $size: number }>`
  font-size: ${({ $size }) => `${$size}px `};
  color: ${({ theme }) => theme.primaryText};
  justify-content: center;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.primaryBackground};
  max-width: 87px;
  border-radius: 20px;
  font-weight: 400;
`;

export const CreatedAt = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 8px 0;
  gap: 2px;

  svg {
    margin-right: 2px;
  }
`;

export const CreatedDate = styled.span`
  font-size: 11px;
  font-weight: 400;
  text-align: end;
`;

export const CreatedTime = styled.span`
  font-size: 11px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const StatusTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 20px;
`;
