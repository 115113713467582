import { MarkersVisualisationDataDto } from "../../../../models/Widgets";

export function getValueWithDecimals(countyData: MarkersVisualisationDataDto) {
  const valueD = (countyData?.value && parseFloat(countyData?.value)) || 0;
  const areZecimale = valueD % 1 !== 0;
  let value;

  if (areZecimale) {
    value = valueD?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    value = valueD?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return value;
}