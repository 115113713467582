import styled, { css } from "styled-components";
import { Sliders } from "react-bootstrap-icons";

const MapSettingButtonStyles = css`
  z-index: 1;
  position: absolute;
  top: 14px;
  right: 15px;
`;

export const WidgetWrapper = styled.div<{
  $storytelling?: boolean;
  $map?: boolean;
  $selected?: boolean;
  $recommended?: boolean;
  $isFullTemplate?: boolean;
}>`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: ${({ $storytelling, $map }) => $storytelling || $map ? "" : "12px 16px 12px 16px"};

  border: ${({ theme, $storytelling, $selected, $recommended }) =>
    $selected && !$recommended
      ? `1px solid transparent`
      : !$storytelling && !$selected
        ? `1px solid ${theme.neutralBorder}`
        : `1px solid transparent`};
  border-radius: 8px;
  height: ${({ $storytelling }) => ($storytelling ? "360px" : "100%")};
  width: 100%;
  min-width: 307px;
  background: ${({ theme, $selected, $recommended }) =>
    $selected && $recommended
      ? theme.primaryBackground
      : theme.neutralBackgroundBase};

  & .barchartAxisLine {
    stroke: ${({ theme }) => theme.neutralBorderMedium} !important;
  }
`;

export const HeaderWrapper = styled.div<{
  $isRow?: boolean;
}>`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  flex-direction: ${({ $isRow }) => ($isRow ? "row" : "column")};
  align-items: flex-start;
  gap: 8px;
`;

export const HeadingNameAndButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  align-self: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.neutralText};
  line-height: 20px;
`;

export const DatavizRecommendedCount = styled.div`
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
  display: flex;  
  justify-content: center;
  text-align: center;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  transition: all 0.15s linear;
`;

export const DatavizSettingsIcon = styled(Sliders)`
  height: 14px;
  transition: all 0.15s linear;
`;

export const SettingsButtonWrapper = styled.div<{
  $map?: boolean;
  $modalOpen?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  width: 40px;
  padding: 1px 4px 1px 2px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 20px;
  cursor: pointer;
  background: #fff;
  box-shadow: ${({ $modalOpen }) => $modalOpen ? 'none' : '0px 2px 4px 1px #141C2C0A'};
  transition: all 0.15s linear;

  ${({ $map }) => $map && MapSettingButtonStyles}

  ${DatavizRecommendedCount} {
    background-color: ${({ theme, $modalOpen }) => $modalOpen ? theme.neutralBackground : theme.secondaryButton};
    color: ${({ theme, $modalOpen }) => $modalOpen ? theme.neutralTextDisabled : theme.primaryTextStrong || '#FFFFFF'};
  }
  ${DatavizSettingsIcon} {
    fill: ${({ theme, $modalOpen }) => $modalOpen ? theme.neutralTextDisabled : theme.neutralTextWeak};
  }

  &:hover{
    border: 1px solid ${({ theme }) => theme.neutralBorderMedium};
    ${DatavizSettingsIcon} {
      fill: ${({ theme }) => theme.neutralText};
    }
  }
`;