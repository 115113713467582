import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  DatasetOverviewLoaderWrapper,
  HeadingIcon,
  HeadingText,
  Icon,
  ModalContent,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  Separator,
  Table,
  TableColumn,
  TableHeading,
  TableRow,
  TotalFields,
} from "./styles";
import {
  requestGetDatasetContents,
  resetDatasetContents,
} from "../../../store/slices/datasets";
import { Search } from "../../SearchBar";
import {
  getDatasetContents,
  getDatasetLoading,
  getSelectedDataset,
} from "../../../store/selectors/datasets";
import { CloseIcon } from "../styles";
import { Loader } from "../../Loader";
import { getIconSrc } from "../../../helpers/getIconType";

interface DatasetsOverviewModalProps {
  closeModal: () => void;
}

export const DatasetsOverviewModal = ({
  closeModal,
}: DatasetsOverviewModalProps) => {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState<string>("");
  const selectedDataset = useSelector(getSelectedDataset);
  const datasetContents = useSelector(getDatasetContents);
  
  const isLoading = useSelector(getDatasetLoading);
  const headers = selectedDataset?.fields;
  const filteredHeaders = headers?.filter((item) =>
    item.label.toLowerCase().includes(searchInput.toLowerCase())
  );
  const selectedDatasetContents = datasetContents?.items.map(
    (contentItem: any) => contentItem
  );

  useEffect(() => {
    if(selectedDataset && (selectedDataset.id || selectedDataset.sourceId)) dispatch(requestGetDatasetContents(selectedDataset?.id || selectedDataset?.sourceId || ''));
  }, [dispatch, selectedDataset]);

  return (
    <ModalOverlay onClick={closeModal}>
      <ModalWrapper onClick={(e) => e.stopPropagation()}>
        <ModalHeadingWrapper>
          <ModalHeading>
            {selectedDataset?.name.charAt(0).toUpperCase()! +
              selectedDataset?.name.slice(1, -4)}
            <TotalFields>{selectedDataset?.fields?.length}</TotalFields>
          </ModalHeading>
          <Search
            width="xs"
            placeholder="Search"
            onChange={(value: string) => setSearchInput(value)}
          />
          <CloseIcon
            onClick={() => {
              closeModal();
              dispatch(resetDatasetContents());
            }}
          />
        </ModalHeadingWrapper>
        <Separator $top />
        <ModalContent>
          {isLoading ? (
            <DatasetOverviewLoaderWrapper>
              <Loader />
            </DatasetOverviewLoaderWrapper>
          ) : (
            <Table>
              {filteredHeaders?.map((datasetItem, idx) => {
                return (
                  <TableColumn key={idx}>
                    <TableHeading>
                      <HeadingIcon>
                        <Icon
                          src={getIconSrc({
                            type: datasetItem.type,
                            subtype: datasetItem.subtype,
                          })}
                        />
                      </HeadingIcon>
                      <HeadingText>
                        {datasetItem.label?.charAt(0)?.toUpperCase()! +
                          datasetItem.label?.slice(1)}
                      </HeadingText>
                    </TableHeading>
                    {selectedDatasetContents?.map((item: any, idx: number) => {
                      return (
                        <TableRow key={idx}>
                          {item[datasetItem?.key]
                            ? item[datasetItem?.key]
                            : "No data"}
                        </TableRow>
                      );
                    })}
                  </TableColumn>
                );
              })}
            </Table>
          )}
        </ModalContent>
      </ModalWrapper>
    </ModalOverlay>
  );
};
