export const AVAILABLE_WIDGETS: { [key: string]: string[] } = {
  lollipopChart: [
    "barChart_vertical",
    "barChart_horizontal",
    "lollipopChart_vertical",
    "lollipopChart_horizontal",
    "lineChart",
    "areaChart",
  ],
  lineChart: [
    "barChart_vertical",
    "barChart_horizontal",
    "lollipopChart_vertical",
    "lollipopChart_horizontal",
    "lineChart",
    "areaChart",
  ],
  areaChart: [
    "barChart_vertical",
    "barChart_horizontal",
    "lollipopChart_vertical",
    "lollipopChart_horizontal",
    "lineChart",
    "areaChart",
  ],
  barChart: [
    "barChart_vertical",
    "barChart_horizontal",
    "lollipopChart_vertical",
    "lollipopChart_horizontal",
    "lineChart",
    "areaChart",
  ],
  sankey: ["sankey","matrix"],
  matrix: ["matrix","sankey"],
  mapChart: ["map","sankey_map", "matrix_map"],
};
