export const generateBreakPoints = (
  values: number[],
  numBreaks: number = 7
): number[] => {
  if (numBreaks < 2) {
    throw new Error("Number of break points must be at least 2.");
  }

  values.sort((a, b) => a - b);
  const breakPoints: number[] = [];

  for (let i = 0; i < numBreaks; i++) {
    const quantilePosition = (i / (numBreaks - 1)) * (values.length - 1);
    const lowerIndex = Math.floor(quantilePosition);
    const upperIndex = Math.ceil(quantilePosition);
    const weight = quantilePosition - lowerIndex;

    const breakPoint =
      values[lowerIndex] * (1 - weight) + values[upperIndex] * weight;
    breakPoints.push(breakPoint);
  }

  return breakPoints;
};
