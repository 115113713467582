import styled from "styled-components";
import { ReactComponent as UserImg } from "../../assets/userIcon.svg";
import { InfoCircleFill } from "react-bootstrap-icons";

export const ProfilePageWrapper = styled.div`
  display: flex;
  gap: 35px;
`;
export const UserProfileHeaderWrapper = styled.div`
  display: flex;
  height: 104px;
  align-items: center;
  gap: 12px;
`;

export const UserProfileHeaderIcon = styled(UserImg)`
  height: 64px;
  width: 64px;
`;

export const UserProfileHeaderUserDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const UserProfileHeaderUserName = styled.div`
  color: ${({ theme }) => theme.neutralText};
  font-size: 24px;
  font-weight: 600;
`;

export const UserProfileHeaderUserDescription = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 14px;
  font-weight: 500;
`;

export const UserProfileHeaderUserRoleBadge = styled.div`
  padding: 3px 8px;
  border-radius: 24px;
  background: ${({ theme }) => theme.primaryBackground};
  color: ${({ theme }) => theme.primaryText};
`;

export const InfoIcon = styled(InfoCircleFill)`
  align-self: center;
  margin-left: 4px;
  color: ${({ theme }) => theme.primaryText};
  cursor: pointer;
`;
