import React, { ChangeEvent, useState } from "react";
import {
  CancelInput,
  ErrorMsg,
  FieldLabel,
  FieldWrapper,
  HidePassword,
  Input,
  ShowPassword,
  WrapperIcons,
} from "../styles";
import { FormikErrors } from "formik";

type FormGroupProps = {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: React.ChangeEventHandler;
  placeholder: string;
  type: string;
  name: string;
  values: string | number;
  errors: string | undefined;
  touched: boolean | undefined;
  id: string;
  width?: string;
  setValues?: (values: React.SetStateAction<any>, shouldValidate?: boolean) => Promise<void | FormikErrors<any>>
};

export const FormGroup: React.FC<FormGroupProps> = ({
  handleChange,
  handleBlur,
  placeholder,
  type,
  name,
  values,
  errors,
  touched,
  id,
  width,
  setValues = () => { },
}) => {
  const [hasValue, setHasValue] = useState(!!values);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleShowPassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setHasValue(!!e.target.value);
  };

  const handleResetValue = () => {
    setValues((prev: any) => ({ ...prev, [name]: '' }))
    setHasValue(false)
  }
  return (
    <FieldWrapper $width={width}>
      <FieldLabel $show={hasValue}>{placeholder}</FieldLabel>
      <Input
        $error={!!(errors && touched)}
        $hasValue={hasValue}
        id={id}
        type={type === "password" && passwordVisible ? "text" : type}
        name={name}
        onChange={handleInputChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        value={values}
      />

      {type === "password" && (
        <WrapperIcons >
          {values && (<CancelInput onClick={handleResetValue} />)}
          {passwordVisible ? <HidePassword onClick={handleShowPassword} /> : <ShowPassword onClick={handleShowPassword} />}
        </WrapperIcons>
      )}
      {errors && touched && <ErrorMsg>{errors}</ErrorMsg>}
    </FieldWrapper>
  );
};
