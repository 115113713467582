import { getIcon } from './icons/getIcon';
import { IconType } from './icons/icons.type';
import { Slider, Switch, SwitchInput, SwitchLabel, SwitchWrapper } from './style';

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur?: React.ChangeEventHandler,
  values?: any,
  checked?: boolean,
  name?: string,
  disabled?: boolean,
  errors?: string;
  touched?: boolean;
  label?: string
  icon?: IconType
}

const SwitchIconComponent: React.FC<Props> = (props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e)
  }

  return (
    <SwitchWrapper>
      {!!props.label && <SwitchLabel>{props.label}</SwitchLabel>}
      <Switch>
        <SwitchInput
          $error={!!(props.errors && props.touched)}
          disabled={props.disabled}
          onChange={handleChange}
          onBlur={props.onBlur}
          type="checkbox"
          name={props.name}
          checked={props.checked}
        >
        </SwitchInput>
        <Slider $icon={props.icon === 'editActive' ? getIcon(props.icon) : getIcon(props.icon)} />
      </Switch>
    </SwitchWrapper>
  );
};

export default SwitchIconComponent;
