import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircle } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

import { CloseIcon, Heading } from "./styles";
import { ProjectType } from "./ProjectType";
import { StyleSection } from "./Style";
import { Interactivity } from "./Interactivity";
import {
  ModalContent,
  ModalFooterWrapper,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import { SelectTemplate } from "./SelectTemplate";
import { Button } from "../../Button";

import { CustomInput } from "../../CustomInput";
import {
  requestAllPages,
  requestAllPublicPages,
  requestCreatePage,
  requestPageById,
  requestPageWidgets,
  requestUpdatePage,
  resetDraftSettings,
  setDraftPageSettings,
  setPageSettings,
  setPageWidgets,
} from "../../../store/slices/projectPages";
import { resetActiveModal } from "../../../store/slices/modals";
import { PageSettingsDTO } from "../../../models/Pages";
import {
  getCurrentPageWidgets,
  getCurrentProjectId,
  getDraftPageSettings,
  getPageSettings,
} from "../../../store/selectors/projects";
import {
  getActiveModal,
  getModalCreateOptions,
} from "../../../store/selectors/modals";
import { LAYOUTS } from "../../ProjectPageLayouts/config";
import { adjustArray } from "../../../helpers/ajustArray";
import { findFirstCommonElement } from "../../../helpers/firstCommonElement";
import { extractBlockIds, LayoutI } from "../../../helpers/extractBlockIds";
import { defaultHeader } from "../../AddStorytellingSection";
import { UpdateWidgetDTO } from "../../../models/Widgets";
import { defaultNewWidget } from "../../../pages/ProjectPage/constants";
import { requestUpdateWidgets } from "../../../store/slices/widgets";
import { Loader } from "../../Loader";
import { getIsPublicMode } from "../../../store/selectors/main";

interface ProjectSettingsModalProps {
  closeModal: () => void;
}
export const ProjectSettingsModal = ({
  closeModal,
}: ProjectSettingsModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isPublicRoute = useSelector(getIsPublicMode);
  const pageSettings = useSelector(getPageSettings);
  const pageDraftSettings = useSelector(getDraftPageSettings);
  const activeModal = useSelector(getActiveModal);
  const createNewProject = useSelector(getModalCreateOptions);
  const currentProjectId = useSelector(getCurrentProjectId);
  const modalOpen = activeModal.includes("projectSettingsModal");
  const widgets = useSelector(getCurrentPageWidgets);
  const settings = createNewProject ? pageDraftSettings : pageSettings;
  const [name, setName] = useState(settings.name);

  const resetPage = () => {
    dispatch(requestPageById({ id: settings.id! }));
    dispatch(requestPageWidgets({ pageId: settings.id!, includeData: true }));
  };

  const handleOnClose = () => {
    setIsClosing(true);
    // setIsLoading(false);
    pageDraftSettings && dispatch(resetDraftSettings());
    if (settings.id) {
      resetPage();
    }
    setTimeout(() => {
      closeModal();
    }, 400);
  };

  const noLayout = settings.dashType === "dashboard" && !settings.templateId;

  const handleSettingsUpdate = (newSettings: PageSettingsDTO) => {
    const newPage = { ...newSettings, header: defaultHeader };
    dispatch(
      createNewProject
        ? setDraftPageSettings(newPage)
        : setPageSettings(newPage)
    );
    if (
      newSettings.templateId &&
      newSettings.templateId !== settings.templateId &&
      !createNewProject
    ) {
      const layout = LAYOUTS.find((l) => l.id === newSettings.templateId);

      let blocks: any = extractBlockIds(layout?.arranging as LayoutI);
      let restWidgets = [...widgets?.items];
      let mapedWidgets = [...widgets?.items];
      if (blocks.length > restWidgets?.length) {
        blocks = adjustArray(blocks, restWidgets?.length);
      }
      let chartTypes = restWidgets.map((r: any) => r.chartType);

      for (let i = 0; i < blocks?.length; i++) {
        const block = blocks[i];
        const chartType = findFirstCommonElement(chartTypes, block.widgets);
        const index = chartTypes.findIndex((c: string) => c === chartType);
        if (index !== -1) {
          chartTypes.splice(index, 1);
        }
        const chart = restWidgets?.find(
          (widget: any) => widget.chartType === chartType
        );
        if (chart) {
          const indexSuggestion = restWidgets?.findIndex(
            (widget: any) => widget.chartType === chartType
          );
          if (indexSuggestion !== -1) {
            restWidgets?.splice(indexSuggestion, 1);
          }
          mapedWidgets = mapedWidgets?.map((w) => {
            if (w.id === chart.id) {
              return { ...w, blockId: block?.blockId?.toString() };
            }
            return w;
          });
        }
      }
      mapedWidgets.sort((a, b) => parseInt(a.blockId!) - parseInt(b.blockId!));
      dispatch(setPageWidgets({ count: widgets.count, items: mapedWidgets }));
    }
  };

  const handleEditPage = (
    widgetDescriptions?: {
      story: string;
      widget_id: string;
    }[]
  ) => {
    const { ...requestUpdatePageSettings } = pageSettings;
    let barChartRequestData: UpdateWidgetDTO[] = [];
    let lineChartRequestData: UpdateWidgetDTO[] = [];
    let lollipopChartRequestData: UpdateWidgetDTO[] = [];
    let sankeyChartRequestData: UpdateWidgetDTO[] = [];
    let mapChartRequestData: UpdateWidgetDTO[] = [];
    let areaChartRequestData: UpdateWidgetDTO[] = [];
    let matrixChartRequestData: UpdateWidgetDTO[] = [];
    for (let chart of widgets.items) {
      const descriptionData = widgetDescriptions?.find(
        (w) => w.widget_id === chart.id
      );
      let descriptionChartData = {
        ...defaultNewWidget,
        description: chart?.description || defaultNewWidget.description,
        insights: chart?.insights || defaultNewWidget.insights,
      };
      if (descriptionData) {
        descriptionChartData = {
          ...descriptionChartData,
          description:
            descriptionData.story || descriptionChartData.description,
        };
      }

      switch (chart?.chartType) {
        case "mapChart":
          mapChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        case "areaChart":
          areaChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        case "lineChart":
          lineChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        case "barChart":
          barChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        case "lollipopChart":
          lollipopChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        case "sankey":
          sankeyChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        case "matrix":
          matrixChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        default:
      }
    }
    const existCharts =
      barChartRequestData?.length +
      lineChartRequestData?.length +
      lollipopChartRequestData?.length +
      sankeyChartRequestData?.length +
      areaChartRequestData?.length +
      matrixChartRequestData?.length;

    if (existCharts > 0) {
      dispatch(setPageWidgets({ items: [], count: 0 }));
      dispatch(
        requestUpdateWidgets({
          barChart: barChartRequestData,
          lineChart: lineChartRequestData,
          lollipopChart: lollipopChartRequestData,
          sankeyChart: sankeyChartRequestData,
          areaChart: areaChartRequestData,
          matrixChart: matrixChartRequestData,
          mapChart: mapChartRequestData,
          pageId: requestUpdatePageSettings.id!,
        })
      );
    }
    // dispatch(setPageSettings(requestUpdatePageSettings));
    let header = requestUpdatePageSettings.header;
    if (!header) {
      header = defaultHeader;
    }

    handleSettingsUpdate({ ...pageSettings, name: name });

    dispatch(
      requestUpdatePage({
        ...requestUpdatePageSettings,
        name: name,
        header,
        callbacks: {
          onSuccess: () => {
            if (isPublicRoute) {
              dispatch(requestAllPublicPages({ projectId: currentProjectId }));
            } else {
              dispatch(requestAllPages({ projectId: currentProjectId }));
            }
          },
        },
      })
    );
    handleOnClose();
  };

  const handleCreatePage = () => {
    if (createNewProject) {
      const { ...requestCreatePageSettings } = pageDraftSettings;
      const newPage = {
        ...requestCreatePageSettings,
        header: defaultHeader,
        projectId: currentProjectId,
        interactivity: ["tooltip"],
        navigate,
      };
      dispatch(requestCreatePage(newPage));
      dispatch(resetActiveModal());
    } else if (!createNewProject && pageSettings.id) {
      setIsLoading(true);
      handleEditPage();
    }
  };

  return (
    <ModalOverlay onClick={handleOnClose} $isClosing={isClosing} $noBlur>
      <ModalWrapper
        onClick={(e) => e.stopPropagation()}
        $projectSettingsOpen={modalOpen}
        $isClosing={isClosing}
      >
        {isLoading && <Loader fullScreen />}
        <ModalHeadingWrapper>
          <Heading>
            {createNewProject ? "Create new page" : "Page configuration"}
          </Heading>
          <CloseIcon
            onClick={() => {
              handleOnClose();
            }}
          />
        </ModalHeadingWrapper>
        <OverviewContent>
          <ModalContent>
            <CustomInput
              name="Page name"
              type="text"
              value={name}
              heading="Name the page"
              onChange={(e) => {
                setName(e);
                if (createNewProject)
                  handleSettingsUpdate({ ...settings, name: e });
              }}
              maxLength={24}
            />
            <ProjectType
              settings={settings}
              onUpdateSettings={handleSettingsUpdate}
            />
            {settings.dashType === "dashboard" && (
              <SelectTemplate
                settings={settings}
                onUpdateSettings={handleSettingsUpdate}
              />
            )}
            <StyleSection
              settings={settings}
              onUpdateSettings={handleSettingsUpdate}
            />
            <Interactivity />
          </ModalContent>
        </OverviewContent>
        <ModalFooterWrapper>
          <Button
            onClick={handleOnClose}
            variant="neutral"
            size="medium"
            name="Close"
          />
          <Button
            onClick={handleCreatePage}
            icon={<CheckCircle />}
            variant={
              !!!settings.name?.trim().length ||
                !!!name?.trim().length ||
                !(!!settings.dashType || noLayout)
                ? "disabled"
                : "secondary"
            }
            size="medium"
            disabled={
              !!!settings.name?.trim().length ||
              !!!name?.trim().length ||
              !(!!settings.dashType || noLayout)
            }
            name={createNewProject ? "Create page" : "Save changes"}
          />
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};
