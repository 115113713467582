import styled, { keyframes } from "styled-components";

const Spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoaderWrapper = styled.div<{
  $fullScreen?: boolean;
  $blur?: boolean;
}>`
  position: ${({ $fullScreen }) => ($fullScreen ? "fixed" : "absolute")};
  backdrop-filter: ${({ $blur }) => $blur && "blur(6px)"};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
`;
export const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

export const LoadingSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 8px solid ${({ theme }) => theme.primaryBackground};
  border-top: 8px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 50%;
  animation: 1.5s ${Spinner} linear infinite;
`;
