export const barChart = {
  id: "7d675fb5-781b-4998-a373-5c3b65baae52",
  chartType: "barChart",
  name: "Price Statistics",
  pageId: "7d4fd91d-bc84-450f-b8eb-01cbb288fc19",
  userId: "04ec2f5a-74da-4955-acc3-8ce35e1b134f",
  datasetId: "236b72f4-a1d8-4e69-a6a6-62719bedec97",
  query:
    '[{"$match": {"datasetId": "236b72f4-a1d8-4e69-a6a6-62719bedec97"}}, {"$project": {"datasetId": "$datasetId", "year": {"$toInt": {"$cond": {"if": {"$eq": ["$data.year", ""]}, "then": null, "else": "$data.year"}}}, "value": {"$toDouble": {"$cond": {"if": {"$eq": ["$data.value", ""]}, "then": null, "else": "$data.value"}}}, "dual-eligibility-status": "$data.dual-eligibility-status"}}, {"$group": {"_id": {"dual-eligibility-status": "$dual-eligibility-status"}, "value": {"$sum": "$value"}}}, {"$project": {"_id": 0, "data": {"dual-eligibility-status": "$_id.dual-eligibility-status", "value": "$value"}, "datasetId": "$_id.datasetId"}}]',
  legend: false,
  layout: null,
  background: null,
  search: null,
  layers: null,
  tooltip: false,
  markers: "disabled",
  showBoxPlot: false,
  orientation: "vertical",
  colors: null,
  formatting: null,
  xAxe: ["dual-eligibility-status"],
  yAxe: ["value"],
  groupBy: null,
  display: null,
  arrangeBy: null,
  palette: null,
  dataFormat: null,
  createdAt: "2024-04-17T09:19:00.218Z",
  updatedAt: "2024-04-17T09:19:00.218Z",
  data: [
    {
      "dual-eligibility-status": 2019,
      value: "1100000",
    },
    {
      "dual-eligibility-status": 2020,
      value: "1194521",
    },
    {
      "dual-eligibility-status": 2021,
      value: "1254521",
    },
    {
      "dual-eligibility-status": 2022,
      value: "1824521",
    },
    {
      "dual-eligibility-status": 2023,
      value: "2454521",
    },
  ],
};
