import axios from "./axios";
import { AxiosError, AxiosResponse } from "axios";

import {
  CREATE_AREA_CHART_WIDGET,
  CREATE_BAR_CHART_WIDGET,
  CREATE_KPIS,
  CREATE_LINE_CHART_WIDGET,
  CREATE_LOLLIPOP_CHART_WIDGET,
  CREATE_MAP_CHART_WIDGET,
  CREATE_MAP_WIDGET,
  CREATE_MATRIX_CHART_WIDGET,
  CREATE_SANKEY_CHART_WIDGET,
  GET_WIDGETS,
  GET_WIDGETS_BANNER,
  GET_WIDGETS_STORIES,
  GET_WIDGETS_SUGGESTIONS,
} from "./constants";
import {
  CreateAreaChartWidgetDto,
  CreateBarWidgetDto,
  CreateLineChartWidgetDto,
  CreateLollipopChartWidgetDto,
  CreateMapWidgetDto,
  CreateMatrixChartWidgetDto,
  CreateSankeyChartWidgetDto,
  ICreateKpi,
  IUpdateKpi,
  QueryGetKpis,
  QueryGetWidgets,
  UpdateWidgetDTO,
} from "../models/Widgets";
import { generateQueryString } from "../helpers/queryGenerator";
import { RequestCallbacks } from "../models";

export type WidgetsSuggestionsQuery = {
  projectId: string;
  pageId?: string;
  includeData: boolean;
  withStorytelling?: boolean;
  withKpi?: boolean;
  callbacks?: RequestCallbacks<any>;
};

export type PageStoriesQuery = {
  projectId: string;
  pageId?: string;
  filter?: string;
  callbacks?: RequestCallbacks<any>;
};

export const getAllWidgets = async (query: QueryGetWidgets) => {
  const queryParams = generateQueryString<QueryGetWidgets>({
    ...query,
    limit: query?.limit || 20,
  });

  return axios
    .get(`${GET_WIDGETS}${queryParams}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const getAllPublicWidgets = async (query: QueryGetWidgets) => {
  const queryParams = generateQueryString<QueryGetWidgets>({
    ...query,
    limit: query?.limit || 20,
  });

  return axios
    .get(`/public${GET_WIDGETS}${queryParams}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const getWidgetSuggestions = async ({
  projectId,
  pageId,
  includeData,
  withStorytelling,
  withKpi
}: WidgetsSuggestionsQuery) => {
  const queryParams = generateQueryString<WidgetsSuggestionsQuery>({
    projectId,
    pageId,
    includeData,
    withStorytelling,
    withKpi
  });

  return axios
    .get(`${GET_WIDGETS_SUGGESTIONS}${queryParams}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const createMapWidget = (mapWidget: CreateMapWidgetDto) =>
  axios
    .post(CREATE_MAP_WIDGET, mapWidget)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const createAreaChartWidget = (
  areaChartWidget: CreateAreaChartWidgetDto
) =>
  axios
    .post(CREATE_AREA_CHART_WIDGET, areaChartWidget)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const createLineChartWidget = (
  lineChartWidget: CreateLineChartWidgetDto
) =>
  axios
    .post(CREATE_LINE_CHART_WIDGET, lineChartWidget)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const createLollipopChartWidget = (
  lollipopChartWidget: CreateLollipopChartWidgetDto
) =>
  axios
    .post(CREATE_LOLLIPOP_CHART_WIDGET, lollipopChartWidget)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const createSankeyChartWidget = (
  sankeyChartWidget: CreateSankeyChartWidgetDto
) =>
  axios
    .post(CREATE_SANKEY_CHART_WIDGET, sankeyChartWidget)
    .then((response: AxiosResponse) => {
      return { response };
    })
    .catch((error: AxiosError) => ({ error }));

export const createMatrixChartWidget = (
  matrixChartWidget: CreateMatrixChartWidgetDto
) =>
  axios
    .post(CREATE_MATRIX_CHART_WIDGET, matrixChartWidget)
    .then((response: AxiosResponse) => {
      return { response };
    })
    .catch((error: AxiosError) => {
      return { error };
    });

export const createMapChartWidget = (mapChartWidget: CreateMapWidgetDto) =>
  axios
    .post(CREATE_MAP_CHART_WIDGET, mapChartWidget)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const createBarChartWidget = (barChartWidget: CreateBarWidgetDto) =>
  axios
    .post(CREATE_BAR_CHART_WIDGET, barChartWidget)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const removeWidget = (id: string) =>
  axios
    .delete(`/widgets/${id}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const updateMapWidget = (widget: UpdateWidgetDTO) =>
  axios
    .patch(`${CREATE_MAP_WIDGET}/${widget.id}`, widget)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const updateAreaChartWidget = (widget: UpdateWidgetDTO) =>
  axios
    .patch(`${CREATE_AREA_CHART_WIDGET}/${widget.id}`, widget)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const updateLineChartWidget = (widget: UpdateWidgetDTO) =>
  axios
    .patch(`${CREATE_LINE_CHART_WIDGET}/${widget.id}`, widget)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const updateLollipopChartWidget = (widget: UpdateWidgetDTO) =>
  axios
    .patch(`${CREATE_LOLLIPOP_CHART_WIDGET}/${widget.id}`, widget)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const updateSankeyChartWidget = (widget: UpdateWidgetDTO) =>
  axios
    .patch(`${CREATE_SANKEY_CHART_WIDGET}/${widget.id}`, widget)
    .then((response: AxiosResponse) => {
      return { response };
    })
    .catch((error: AxiosError) => ({ error }));

export const updateMatrixChartWidget = (widget: UpdateWidgetDTO) =>
  axios
    .patch(`${CREATE_MATRIX_CHART_WIDGET}/${widget.id}`, widget)
    .then((response: AxiosResponse) => {
      return { response };
    })
    .catch((error: AxiosError) => {
      return { error };
    });

export const updateBarChartWidget = (widget: UpdateWidgetDTO) =>
  axios
    .patch(`${CREATE_BAR_CHART_WIDGET}/${widget.id}`, widget)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const getPageStories = async ({
  projectId,
  pageId,
  filter,
}: PageStoriesQuery) => {
  const queryParams = generateQueryString<PageStoriesQuery>({
    projectId,
    pageId,
    filter,
  });

  return axios
    .get(`${GET_WIDGETS_STORIES}${queryParams}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const getPageBanner = async ({
  projectId,
  pageId,
  filter,
}: PageStoriesQuery) => {
  const queryParams = generateQueryString<PageStoriesQuery>({
    projectId,
    pageId,
    filter,
  });

  return axios
    .get(`${GET_WIDGETS_BANNER}${queryParams}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const getAllKpis = async (query: QueryGetKpis) => {
  const queryParams = generateQueryString<QueryGetKpis>({
    ...query,
    limit: query?.limit || 20,
  });

  return axios
    .get(`${CREATE_KPIS}${queryParams}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const createKpi = async (data: ICreateKpi) => {
  return axios
    .post(`${CREATE_KPIS}`, data)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const updateKpi = async (data: IUpdateKpi) => {
  return axios
    .patch(`${CREATE_KPIS}/${data.id}`, data)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const deleteKpi = async (id: string) => {
  return axios
    .delete(`${CREATE_KPIS}/${id}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};
