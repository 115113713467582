import blueEditIcon from "./blueEditIcon.svg"
import editIcon from "./editIcon.svg"

import { IconType } from "./icons.type";

export const getIcon = (icon?: IconType) => {
  switch (icon) {
    case 'editActive':
      return editIcon
      
    case 'editDisabled':
      return blueEditIcon

    default:
      break;
  }
}