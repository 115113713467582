import React, { useEffect } from "react";

import { Heading } from "../../styles";
import { HeadingMenuContainer } from "../../components/NavBar/styles";
import { PageMenu } from "../../components/PageMenu";
import { DatasetsList } from "../../components/DatasetsList";
import { useDispatch, useSelector } from "react-redux";
import { EmptyDatasetsPage } from "../../components/EmptyDatasets";
import { requestGetAllDatasets } from "../../store/slices/datasets";
import { Loader } from "../../components/Loader";
import { getAllDatasets } from "../../store/selectors/datasets";

export const Datasets = () => {
  const allDatasets = useSelector(getAllDatasets);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestGetAllDatasets({}));
  }, [dispatch]);

  return (
    <>
      <HeadingMenuContainer>
        <Heading>Dataset Library</Heading>
        <PageMenu />
      </HeadingMenuContainer>
      {allDatasets.isLoading ? (
        <Loader />
      ) : !allDatasets.items.length ? (
        <EmptyDatasetsPage />
      ) : (
        <DatasetsList items={allDatasets.items} />
      )}
    </>
  );
};
