export const searchFullTrue = (obj: any) => {
  if (obj === null || typeof obj !== "object") {
    return false;
  }

  if (obj.full === true) {
    return true;
  }

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (searchFullTrue(obj[key])) {
        return true;
      }
    }
  }
  return false;
};
