import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { PageWrapper } from "./styles";
import {
  AddStorytellingSection,
  defaultHeader,
  defaultSection,
} from "../AddStorytellingSection";
import {
  addWidgets,
  editSection,
  PageContentGeneratorItemType,
} from "../../store/slices/pageContentGenerator";
import { getPageContentGeneratorData } from "../../store/selectors/contentGenerator";
import {
  getCurrentPageKpi,
  getCurrentPageWidgets,
  getCurrentProjectId,
  getPageBanner,
  getPageId,
  getPageSettings,
} from "../../store/selectors/projects";
import SectionItem from "./components/StoryTellingSection/components/SectionItem/SectionItem";
import { getIsEditMode, getIsPublicMode } from "../../store/selectors/main";
import {
  requestCreateAndUpdateWidgets,
  requestPageBanner,
  requestPageStories,
  requestUpdateWidget,
  requestUpdateWidgets,
} from "../../store/slices/widgets";
import { TextImage } from "./components/StoryTellingSection/components/TextImage/TextImage";
import { useDebounceCallback } from "usehooks-ts";
import {
  requestCreatePageSections,
  requestDeletePageSections,
  requestPageById,
  requestPageSections,
  requestPageWidgets,
  requestUpdatePage,
  requestUpdatePageSections,
  setPageWidgets,
} from "../../store/slices/projectPages";
import { UpdateWidgetDTO } from "../../models/Widgets";
import { defaultNewWidget } from "../../pages/ProjectPage/constants";
import { setActiveModal } from "../../store/slices/modals";
import { ISection, IUpdateSection } from "../../api/sections";
import SectionTextImage from "./components/StoryTellingSection/components/SectionTextImage/SectionTextImage";
import { KPISettingsWrapperStory } from "../KPISettingsWrapperStory";

export const StoryTelling = () => {
  const dispatch = useDispatch();
  const pageWidgets = useSelector(getCurrentPageWidgets);
  const pageContentGenerator = useSelector(getPageContentGeneratorData);
  const header = useSelector(getPageBanner);
  const isEditMode = useSelector(getIsEditMode);
  const isPublicMode = useSelector(getIsPublicMode);
  const currentPageId = useSelector(getPageId);
  const currentProjectId = useSelector(getCurrentProjectId);
  const currentPage = useSelector(getPageSettings);
  const aiKpi = useSelector(getCurrentPageKpi);
  const [firstLoad, setFirstLoad] = useState<string | undefined>();
  const [localItems, setLocalItems] = useState<any[]>([]);
  const [sections, setSections] = useState<any[]>([]);
  const { items } = pageContentGenerator;

  const [contentItem, setContentItem] = useState<
    PageContentGeneratorItemType | undefined
  >(currentPage?.header);

  const getAllWidgets = () => {
    dispatch(
      requestPageWidgets({
        pageId: currentPage.id!,
        includeData: true,
      })
    );
  };

  const getAllSections = () => {
    dispatch(
      requestPageSections({
        pageId: currentPage.id!,
        limit: 20,
        skip: 0,
        callbacks: {
          onSuccess: (data) => {
            setSections(data?.items || []);
          },
        },
      })
    );
  };

  useEffect(() => {
    getAllSections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedGetAll = useDebounceCallback(getAllWidgets, 500);

  const generateBanner = useCallback(() => {
    dispatch(
      requestPageBanner({
        pageId: currentPageId,
        projectId: currentProjectId,
        callbacks: {
          onSuccess: (resp) => {
            setContentItem({
              ...header,
              description: resp?.banner_description,
              title: resp?.banner_title,
            });
            setTimeout(() => {
              dispatch(requestPageById({ id: currentPageId! }));
            }, 600);
          },
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageId, currentProjectId, header]);

  const checkUpdatedHeader = useCallback(() => {
    const haveDescriptions = pageWidgets?.items?.filter(
      (w) => !w.description || w.description === "Add here description"
    )?.length;
    const noHavePageDescription = !(header?.description && header?.title);
    if (
      pageWidgets?.count > 0 &&
      firstLoad !== currentPageId &&
      !haveDescriptions &&
      noHavePageDescription
    ) {
      setFirstLoad(currentPageId);
      generateBanner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    header,
    currentPageId,
    firstLoad,
    pageWidgets?.count,
    pageWidgets?.items,
  ]);

  useEffect(() => {
    checkUpdatedHeader();
  }, [checkUpdatedHeader]);

  const handleEditPage = useCallback(
    (
      widgetDescriptions?: {
        story: string;
        widget_id: string;
        insights: string[];
      }[]
    ) => {
      let barChartRequestData: UpdateWidgetDTO[] = [];
      let lineChartRequestData: UpdateWidgetDTO[] = [];
      let lollipopChartRequestData: UpdateWidgetDTO[] = [];
      let sankeyChartRequestData: UpdateWidgetDTO[] = [];
      let mapChartRequestData: UpdateWidgetDTO[] = [];
      let areaChartRequestData: UpdateWidgetDTO[] = [];
      let matrixChartRequestData: UpdateWidgetDTO[] = [];
      if (widgetDescriptions?.length) {
        for (let chart of pageWidgets?.items) {
          const descriptionData = widgetDescriptions?.find(
            (w) => w.widget_id === chart.id
          );
          let descriptionChartData = {
            ...defaultNewWidget,
            description: chart?.description || defaultNewWidget.description,
            insights: chart?.insights || defaultNewWidget.insights,
          };
          if (descriptionData) {
            descriptionChartData = {
              ...descriptionChartData,
              description:
                descriptionData.story || descriptionChartData.description,
              insights:
                descriptionData.insights?.join(" ") ||
                descriptionChartData.insights,
            };
          }

          switch (chart?.chartType) {
            case "mapChart":
              mapChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "areaChart":
              areaChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "lineChart":
              lineChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "barChart":
              barChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "lollipopChart":
              lollipopChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "sankey":
              sankeyChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "matrix":
              matrixChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            default:
          }
        }
      }
      const existCharts =
        barChartRequestData?.length +
        lineChartRequestData?.length +
        lollipopChartRequestData?.length +
        sankeyChartRequestData?.length +
        areaChartRequestData?.length +
        matrixChartRequestData?.length;

      if (existCharts > 0) {
        dispatch(
          requestUpdateWidgets({
            barChart: barChartRequestData,
            lineChart: lineChartRequestData,
            lollipopChart: lollipopChartRequestData,
            sankeyChart: sankeyChartRequestData,
            areaChart: areaChartRequestData,
            matrixChart: matrixChartRequestData,
            mapChart: mapChartRequestData,
            pageId: currentPageId!,
          })
        );
      }
      const noHavePageDescription = !(header?.description && header?.title);
      if (noHavePageDescription) {
        setTimeout(() => {
          generateBanner();
        }, 600);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPageId, header, pageWidgets?.items]
  );

  const checkCreateDescriptions = useCallback(() => {
    const noHaveDescriptions = pageWidgets?.items?.some(
      (w) => !w.description || w.description === "Add here description"
    );

    if (!noHaveDescriptions) {
      return;
    }

    if (firstLoad !== currentPageId && pageWidgets?.count > 0) {
      setFirstLoad(currentPageId);
    }

    dispatch(
      requestPageStories({
        pageId: currentPageId,
        projectId: currentProjectId,
        callbacks: {
          onSuccess: (resp) => {
            handleEditPage(resp?.widget_descriptions);
            if (
              pageWidgets?.items?.length !== resp?.widget_descriptions?.length
            ) {
              setTimeout(() => {
                checkCreateDescriptions();
              }, 500);
            }
          },
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    header,
    currentPageId,
    currentProjectId,
    firstLoad,
    pageWidgets?.count,
    pageWidgets?.items,
  ]);

  useEffect(() => {
    checkCreateDescriptions();
  }, [checkCreateDescriptions]);

  useEffect(() => {
    if (pageWidgets.items) {
      const widgets = pageWidgets?.items
        ?.map((el, i): PageContentGeneratorItemType => {
          return {
            ...el,
            blockId: (Number(el.blockId) || 1) as any,
            sectionType: el.chartType === "mapChart" ? "map" : "widget",
          };
        })
        .sort(
          (a, b) =>
            //@ts-ignore
            a?.blockId - b?.blockId
        );

      dispatch(addWidgets(widgets));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageWidgets]);

  const handleSave = (item: any) => {
    dispatch(
      requestUpdateWidget({
        ...item,
        uniqueValues: Array.isArray(item.uniqueValues) ? item.uniqueValues : [],
        blockId: String(item?.blockId),
      } as any)
    );
    debouncedGetAll();
  };

  useEffect(() => {
    if (contentItem) {
      dispatch(editSection(contentItem));
    }
    if (contentItem && contentItem.id) {
      handleSave(contentItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentItem]);

  const setBLockId = (
    currentBlockId: number,
    fromPosition: number,
    toPosition: number
  ): number => {
    const isPositive = fromPosition > toPosition;
    let minPosition = Math.min(fromPosition, toPosition);
    let maxPosition = Math.max(fromPosition, toPosition);

    if (currentBlockId >= minPosition && currentBlockId <= maxPosition) {
      if (currentBlockId === fromPosition) {
        return toPosition;
      } else {
        return currentBlockId + (isPositive ? 1 : -1);
      }
    }

    return currentBlockId;
  };

  const updatePromiseSection = async (
    section: IUpdateSection,
    blockId: string
  ) => {
    return new Promise((resolve) => {
      dispatch(
        requestUpdatePageSections({
          ...section,
          blockId: blockId,
          callbacks: {
            onSuccess: (data) => {
              return resolve(data);
            },
          },
        })
      );
    });
  };

  const handleOnDragEnd = async (result: any) => {
    if (!result.destination) return;
    const from = result?.source?.index;
    const to = result?.destination?.index;
    if (from !== to) {
      let barChartRequestData: UpdateWidgetDTO[] = [];
      let lineChartRequestData: UpdateWidgetDTO[] = [];
      let lollipopChartRequestData: UpdateWidgetDTO[] = [];
      let sankeyChartRequestData: UpdateWidgetDTO[] = [];
      let mapChartRequestData: UpdateWidgetDTO[] = [];
      let areaChartRequestData: UpdateWidgetDTO[] = [];
      let matrixChartRequestData: UpdateWidgetDTO[] = [];
      const newWidgets = [];
      for (let i = 0; i < pageWidgets?.items?.length; i++) {
        const chart = pageWidgets?.items[i];
        const curentChartBlockId = parseInt(chart.blockId!);
        const blockId: number = setBLockId(curentChartBlockId, from, to);

        newWidgets.push({ ...chart, blockId: blockId?.toString() });
        switch (chart?.chartType) {
          case "mapChart":
            mapChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "areaChart":
            areaChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "lineChart":
            lineChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "barChart":
            barChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "lollipopChart":
            lollipopChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "sankey":
            sankeyChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "matrix":
            matrixChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          default:
        }
      }

      const promises = [];

      for (let i = 0; i < sections?.length; i++) {
        const section = sections[i];
        const curentBlockId = parseInt(section.blockId!);
        const blockId: number = setBLockId(curentBlockId, from, to);

        if (blockId !== curentBlockId) {
          promises.push(updatePromiseSection(section, blockId?.toString()));
        }
      }

      if (promises?.length) {
        await Promise.all(promises);
        getAllSections();
      }

      const existCharts =
        barChartRequestData?.length +
        lineChartRequestData?.length +
        lollipopChartRequestData?.length +
        sankeyChartRequestData?.length +
        areaChartRequestData?.length +
        matrixChartRequestData?.length +
        mapChartRequestData?.length;

      if (existCharts > 0) {
        dispatch(
          setPageWidgets({ items: newWidgets, count: newWidgets?.length })
        );
        dispatch(
          requestUpdateWidgets({
            barChart: barChartRequestData,
            lineChart: lineChartRequestData,
            lollipopChart: lollipopChartRequestData,
            sankeyChart: sankeyChartRequestData,
            areaChart: areaChartRequestData,
            matrixChart: matrixChartRequestData,
            mapChart: mapChartRequestData,
            pageId: currentPage.id!,
          })
        );
      }
    }
  };

  const combineItems = useCallback(() => {
    const newItems = [...items, ...sections];

    const filteredItems = newItems.sort(
      (a, b) => parseInt(a.blockId) - parseInt(b.blockId)
    );
    setLocalItems(filteredItems);
  }, [items, sections]);

  useEffect(() => {
    combineItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, sections]);

  const updateWidgetsStorytelling = (positionItem: number, addPosition = 1) => {
    let barChartUpdateData: UpdateWidgetDTO[] = [];
    let lineChartUpdateData: UpdateWidgetDTO[] = [];
    let lollipopChartUpdateData: UpdateWidgetDTO[] = [];
    let sankeyChartUpdateData: UpdateWidgetDTO[] = [];
    let mapChartUpdateData: UpdateWidgetDTO[] = [];
    let areaChartUpdateData: UpdateWidgetDTO[] = [];
    let matrixChartUpdateData: UpdateWidgetDTO[] = [];

    for (let widget of pageWidgets.items) {
      const blockId =
        widget?.blockId && parseInt(widget?.blockId) >= positionItem
          ? parseInt(widget?.blockId) + addPosition
          : parseInt(widget?.blockId!);
      switch (widget.chartType) {
        case "mapChart":
          mapChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "areaChart":
          areaChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "lineChart":
          lineChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "barChart":
          barChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "lollipopChart":
          lollipopChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "sankey":
          sankeyChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "matrix":
          matrixChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        default:
      }
    }

    const existCharts =
      barChartUpdateData?.length +
      lineChartUpdateData?.length +
      lollipopChartUpdateData?.length +
      sankeyChartUpdateData?.length +
      areaChartUpdateData?.length +
      matrixChartUpdateData?.length +
      mapChartUpdateData?.length;

    if (currentPage?.id && existCharts > 0) {
      if (existCharts > 0) {
        dispatch(
          requestCreateAndUpdateWidgets({
            createBarChart: [],
            createLineChart: [],
            createLollipopChart: [],
            createMapChart: [],
            createSankeyChart: [],
            createAreaChart: [],
            createMatrixChart: [],
            barChart: barChartUpdateData,
            lineChart: lineChartUpdateData,
            lollipopChart: lollipopChartUpdateData,
            sankeyChart: sankeyChartUpdateData,
            areaChart: areaChartUpdateData,
            matrixChart: matrixChartUpdateData,
            mapChart: mapChartUpdateData,
            pageId: currentPage?.id,
          })
        );
      }
    }
  };

  const addNewSection = (blockId: number) => {
    dispatch(
      requestCreatePageSections({
        ...defaultSection,
        pageId: currentPage.id!,
        blockId: blockId.toString(),
        callbacks: {
          onSuccess: () => {
            updateWidgetsStorytelling(blockId);
            getAllSections();
          },
        },
      })
    );
  };

  const updateSection = (section: IUpdateSection) => {
    const newItems = sections?.map((s) => {
      if (s.id === section.id) {
        return { ...s, ...section };
      }
      return s;
    });
    setSections(newItems || []);
    dispatch(
      requestUpdatePageSections({
        ...section,
      })
    );
  };

  const updateSections = (newSections: ISection[], positionItem: number) => {
    for (let section of newSections) {
      const blockId =
        section?.blockId && parseInt(section?.blockId) >= positionItem
          ? parseInt(section?.blockId) - 1
          : parseInt(section?.blockId!);
      updateSection({ ...section, blockId: blockId.toString() });
    }
  };

  const deleteItem = (section: ISection) => {
    const newItems = sections?.filter((s) => {
      return s.id !== section.id;
    });
    setSections(newItems || []);
    dispatch(
      requestDeletePageSections({
        id: section.id,
        callbacks: {
          onSuccess: () => {
            updateWidgetsStorytelling(parseInt(section.blockId), -1);
            updateSections(newItems, parseInt(section.blockId));
          },
        },
      })
    );
  };

  return (
    <PageWrapper id={currentPageId}>
      {isEditMode && !currentPage.header && (
        <AddStorytellingSection
          addSection={() => {
            dispatch(
              requestUpdatePage({ ...currentPage, header: defaultHeader })
            );
          }}
          addWidget={() => {
            dispatch(
              setActiveModal({
                id: "pageRecommendedWidgetsModal",
                firstItem: 1,
              })
            );
          }}
        />
      )}

      {currentPage.header && !!localItems?.length && (
        <TextImage contentItem={contentItem} setContentItem={setContentItem} />
      )}

      {aiKpi.items?.length ? (
        <KPISettingsWrapperStory kpis={aiKpi.items} />
      ) : null}

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable-1">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {!!localItems?.length &&
                localItems?.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={parseInt(item.blockId!)}
                    isDragDisabled={!!!isEditMode || isPublicMode}
                  >
                    {(provided, snapshot) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            userSelect: "none",
                            minHeight: "50px",
                            margin: "8px",
                            ...provided.draggableProps.style,
                          }}
                        >
                          {item?.chartType ? (
                            <>
                              {isEditMode && !isPublicMode && (
                                <AddStorytellingSection
                                  addSection={() =>
                                    addNewSection(parseInt(item.blockId))
                                  }
                                  addWidget={() => {
                                    dispatch(
                                      setActiveModal({
                                        id: "pageRecommendedWidgetsModal",
                                        firstItem: parseInt(item.blockId),
                                      })
                                    );
                                  }}
                                />
                              )}
                              <SectionItem
                                draggable={snapshot.isDragging}
                                key={`${item.id}_${index}`}
                                setContentItem={handleSave}
                                item={item}
                              />
                            </>
                          ) : (
                            <SectionTextImage
                              draggable={snapshot.isDragging}
                              contentItem={item}
                              key={`${item.id}_${index}`}
                              setContentItem={updateSection}
                              deleteItem={() => deleteItem(item)}
                            />
                          )}
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </PageWrapper>
  );
};
