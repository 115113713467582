import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getAiSuggestions } from "../../store/selectors/widgets";
import { CreateMapWidgetDto } from "../../models/Widgets";
import {
  ColorsWrapper,
  LayerHeading,
  LayerWrapper,
  LegendAccentHeading,
  LegendDetailsDescription,
  LegendDetailsHeading,
  LegendDetailsWrapper,
  MapLegendColor,
  MapLegendColorsWrapper,
  MapLegendDescription,
  MapLegendDescriptionWrapper,
  MapLegendWrapper,
  RangeWrapper,
} from "./styles";
import { ColorRangeI } from "../../models/Pages";
import RangeInput from "../Inputs/CustomRange/RangeInput";

export const MapLegend = ({
  colorRanges,
  average,
  leftSlider,
  rightSlider,
  setLeftSlider = () => {},
  setRightSlider = () => {},
}: {
  colorRanges: ColorRangeI[];
  average: number;
  leftSlider: number;
  rightSlider: number;
  setLeftSlider: any;
  setRightSlider: any;
}) => {
  const [mapChartData, setMapChartData] = useState<CreateMapWidgetDto>();
  const aiSuggestions = useSelector(getAiSuggestions);

  useEffect(() => {
    if (aiSuggestions) {
      setMapChartData(
        aiSuggestions?.find(
          (el: CreateMapWidgetDto) => el.chartType === "mapChart"
        )
      );
    }
  }, [aiSuggestions, mapChartData]);

  const formatValue = (value: number) => {
    const abbreviations = ["B", "M", "K"];

    for (let i = 0; i < abbreviations.length; i++) {
      const unit = Math.pow(1000, abbreviations.length - i);
      if (Math.abs(value) >= unit) {
        return (value / unit).toFixed(1) + " " + abbreviations[i];
      }
    }

    return value.toFixed(0);
  };

  const renderValue = () => {
    if (average) {
      const areZecimale = average % 1 !== 0;

      if (areZecimale) {
        return average?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return average?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return average;
  };

  return (
    <LayerWrapper>
      <LayerHeading>Members per county</LayerHeading>

      <LegendDetailsWrapper>
        <LegendDetailsHeading>
          <LegendAccentHeading>{renderValue()}</LegendAccentHeading> ppl
        </LegendDetailsHeading>
        <LegendDetailsDescription>Avg., per county</LegendDetailsDescription>
        <MapLegendWrapper>
          <MapLegendColorsWrapper>
            <RangeWrapper>
              <RangeInput
                leftSlider={leftSlider}
                rightSlider={rightSlider}
                setLeftSlider={setLeftSlider}
                setRightSlider={setRightSlider}
              />
            </RangeWrapper>

            <ColorsWrapper>
              {colorRanges?.map((range, index) => (
                <MapLegendColor
                  $color={range.color}
                  key={index}
                ></MapLegendColor>
              ))}
            </ColorsWrapper>
          </MapLegendColorsWrapper>
          <MapLegendDescriptionWrapper>
            {colorRanges?.map((range, index) => (
              <MapLegendDescription key={index}>
                {range.start &&
                range.start !== Infinity &&
                range.start !== -Infinity &&
                !Number.isNaN(range.start)
                  ? formatValue(range.start)
                  : 0}
              </MapLegendDescription>
            ))}
          </MapLegendDescriptionWrapper>
        </MapLegendWrapper>
      </LegendDetailsWrapper>
    </LayerWrapper>
  );
};
