import React from "react";
import { ArrowRight } from "react-bootstrap-icons";

import {
  Container,
  Data,
  DataBlocks,
  MainData,
  MainDataSymbol,
  MainDataText,
  Row,
  RowItem,
  SubTitle,
  ArrowIndicator,
  Title,
  Dashed,
} from "./styles";
import { KPIComponent } from "../KPIComponent";

type Props = {};

export const KPI: React.FC<Props> = (props: Props) => {
  return (
    <Container>
      <Row>
        <RowItem>
          <Title>Population</Title>
          <DataBlocks>
            <KPIComponent
              key="one"
              settings={{
                dropDownOptions: [
                  { value: "value", label: "label" },
                  { value: "value", label: "label" },
                ],
                onDropDownOptionClick: (e: any) => {},
              }}
              size={"xl"}
              subtitle="Total number (current year)"
              indicator={"45K"}
              label={{ position: "right", text: "K" }}
              percentage={15}
              value={"165"}
            />
          </DataBlocks>
        </RowItem>
        <RowItem>
          <Title>Rent Demand</Title>
          <DataBlocks>
            <Data>
              <MainData>
                <MainDataText>32</MainDataText>
                <MainDataSymbol>%</MainDataSymbol>
              </MainData>
              <SubTitle>Rate</SubTitle>
            </Data>
          </DataBlocks>
        </RowItem>
      </Row>
      <Row>
        <RowItem $noBorder>
          <Title>Rent Prices</Title>
          <DataBlocks>
            <Data>
              <MainData>
                <MainDataSymbol>$</MainDataSymbol>
                <MainDataText>40,600</MainDataText>
              </MainData>
              <SubTitle>Highest price</SubTitle>
            </Data>
          </DataBlocks>
          <ArrowIndicator>
            <ArrowRight />
          </ArrowIndicator>
          <Dashed />
        </RowItem>
        <RowItem>
          <DataBlocks $space>
            <Data>
              <MainData>
                <MainDataSymbol>$</MainDataSymbol>
                <MainDataText>1,200</MainDataText>
              </MainData>
              <SubTitle>Lowest Price</SubTitle>
            </Data>
          </DataBlocks>
          <ArrowIndicator $left>
            <ArrowRight />
          </ArrowIndicator>
        </RowItem>
      </Row>
      <Row>
        <RowItem $noBorder>
          <Title>Properties</Title>
          <DataBlocks>
            <Data>
              <MainData>
                <MainDataText>10,650</MainDataText>
              </MainData>

              <SubTitle>Total number</SubTitle>
            </Data>
          </DataBlocks>
          <ArrowIndicator>
            <ArrowRight />
          </ArrowIndicator>
          <Dashed />
        </RowItem>
        <RowItem>
          <DataBlocks $space>
            <Data>
              <MainData>
                <MainDataText>1,241</MainDataText>
              </MainData>
              <SubTitle>Available</SubTitle>
            </Data>
          </DataBlocks>
          <ArrowIndicator $left>
            <ArrowRight />
          </ArrowIndicator>
        </RowItem>
      </Row>
    </Container>
  );
};
