import React, { useEffect, useState } from "react";
import {
  CountLegendItems,
  CountLegendModal,
  LegendItemColor,
  LegendItemName,
  LegendItemWrapper,
  LegendPalette,
  LegendPaletteColor,
  LegendPaletteWrapper,
  LegendWrapper,
  UnitLegend,
} from "./styles";
import { useSelector } from "react-redux";
import { getPageSettings } from "../../store/selectors/projects";
import { colorsPalettes } from "../../constants";
import { ColorRangeI } from "../../models/Pages";
import { createPortal } from "react-dom";
import { getRelativePosition } from "../Widgets/components/LabelTooltip";

export interface ChartLegendValue {
  label: string;
  color: string;
  key?: string;
}
interface ChartLegendInterface {
  legendType: "unit" | "palette";
  legendValues?: ChartLegendValue[];
  colorRanges?: ColorRangeI[];
  chartWidth?: number;
}
export const ChartLegend = ({
  legendType,
  legendValues,
  colorRanges,
  chartWidth,
}: ChartLegendInterface) => {
  const { styleId } = useSelector(getPageSettings);
  const colorPalette = colorsPalettes.find((palette) => palette.id === styleId);
  const unitColor2 = colorPalette?.colors[1].mainColor;
  const colorPaletteVariations = colorPalette?.colors.find(
    (color) => color.mainColor === unitColor2
  )?.variations;

  const calculateLabelWidth = (label: string): number => {
    return label.length * 7 + 17;
  };

  const [data, setData] = useState<ChartLegendValue[]>([]);
  const [hovered, setHovered] = useState<any>(false);

  useEffect(() => {
    const result: ChartLegendValue[] = [];
    let currentWidth = 40;

    legendValues?.reduce((accumulatedWidth, item) => {
      const labelWidth = calculateLabelWidth(item.label);

      if (accumulatedWidth + labelWidth < chartWidth!) {
        result.push(item);
        return accumulatedWidth + labelWidth;
      }
      return accumulatedWidth;
    }, currentWidth);

    setData(result.sort((a, b) => {
      const priorityLabels = ["Other", "All"];

      const aPriority = priorityLabels.includes(a.label) ? 1 : 0;
      const bPriority = priorityLabels.includes(b.label) ? 1 : 0;

      if (aPriority === bPriority) {
        return 0;
      }

      return aPriority - bPriority;
    }));
  }, [legendValues, chartWidth]);

  return (
    <LegendWrapper>
      {legendType === "unit" && (
        <UnitLegend>
          {data?.map((legendValue, index) => {
            return (
              <LegendItemWrapper key={index}>
                <LegendItemColor $color={legendValue.color}></LegendItemColor>
                <LegendItemName>{legendValue.label}</LegendItemName>
              </LegendItemWrapper>
            );
          })}
          {legendValues?.length! > data?.length! && (
            <CountLegendItems
              onMouseMove={(e) => {
                const position = getRelativePosition(document.body, e);
                setHovered(position)
              }}
              onMouseLeave={() => { setHovered(false) }}
            >
              +{legendValues?.length! - data?.length!}
              {
                hovered && (
                  createPortal(
                    <CountLegendModal $top={hovered.y + 20} $left={hovered.x - 5}>
                      {legendValues?.map((legendValue, index) => {
                        if (!data?.includes(legendValue)) {
                          return (
                            <LegendItemWrapper key={index}>
                              <LegendItemColor
                                $color={legendValue.color}
                              ></LegendItemColor>
                              <LegendItemName>{legendValue.label}</LegendItemName>
                            </LegendItemWrapper>
                          );
                        }
                        return null;
                      })}
                    </CountLegendModal>,
                    document.body
                  )
                )
              }

            </CountLegendItems>
          )}
        </UnitLegend>
      )}
      {legendType === "palette" && !colorRanges?.length ? (
        <LegendPaletteWrapper>
          Low
          <LegendPalette>
            {colorPaletteVariations?.map((color: string, index) => (
              <LegendPaletteColor
                $color={color}
                key={index}
              ></LegendPaletteColor>
            ))}
          </LegendPalette>
          High
        </LegendPaletteWrapper>
      ) : null}
      {legendType === "palette" && colorRanges?.length ? (
        <LegendPaletteWrapper>
          Low
          <LegendPalette>
            {colorRanges?.map((range: ColorRangeI, index) => (
              <LegendPaletteColor
                $color={range.color}
                key={index}
              ></LegendPaletteColor>
            ))}
          </LegendPalette>
          High
        </LegendPaletteWrapper>
      ) : null}
    </LegendWrapper>
  );
};
