import { MarkersVisualisationDataDto } from "../../../../models/Widgets";

export function getYears(layerData: MarkersVisualisationDataDto[] | undefined, setYear: React.Dispatch<React.SetStateAction<number | undefined>>, setYears: React.Dispatch<React.SetStateAction<[] | number[]>>) {
  const mapedYears = layerData?.reduce(
    (t: string[], l: MarkersVisualisationDataDto) => {
      if (!t.includes(l.year)) {
        return [l.year, ...t];
      }
      return t;
    },
    []
  );
  const years = mapedYears
    ?.map((y) => parseInt(y))
    ?.filter(
      (y) => y && y !== Infinity && y !== -Infinity && !Number.isNaN(y)
    );
  years?.sort();
  if (years?.length) {
    setYear(years[0]);
    setYears(years || []);
  }
}