import styled, { css, keyframes } from "styled-components";

// Common styles
export const Container = styled.div`
  padding: 0 20px;
  position: relative;
  min-height: calc(100dvh - 64px);
  overflow-y: auto;
`;

export const Heading = styled.h2`
  font-size: 24px;
  font-weight: 600;
  height: 76px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.neutralText};
`;

export const commonSeparatorStyle = css`
  content: "";
  position: absolute;
  left: 0;
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.neutralBorder};
`;

// Animation section
export const fadeInDown = keyframes`
 0% {
   opacity: 0;
   transform: translate3d(0, -10%, 0);
 }
 100% {
   opacity: 1;
   transform: translate3d(0, 0, 0);
 }
`;

export const fadeInRight = keyframes`
 0% {
   opacity: 0.2;
   transform: translate3d(30%, 0, 0);
 }
 100% {
   opacity: 1;
   transform: translate3d(0, 0, 0);
 }
`;

export const fadeOutRight = keyframes`
 0% {
   opacity: 1;
   transform: translate3d(0, 0, 0);
 }
 100% {
   opacity: 0;
   transform: translate3d(50%, 0, 0);
 }
`;

export const backDropFadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const backDropFadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const textFadeInUp = keyframes`
  from {
    opacity: 0.9;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const animateMenuOpen = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
