import React from "react";

import {
  ActiveRadioButton,
  DashboardIcon,
  HoveredRadioButton,
  InactiveRadioButton,
  ProjectTypeDescription,
  ProjectTypeDescriptionWrapper,
  ProjectTypeIconWrapper,
  ProjectTypeItem,
  ProjectTypeTitle,
  ProjectTypeWrapper,
  StorytellingIcon,
} from "../styles";
import { ComponentSettings } from "../../../../models/Pages";

export const ProjectType = ({
  settings,
  onUpdateSettings,
}: ComponentSettings) => {
  const handleItemClick = (item: "dashboard" | "storytelling" | 'table') => {
    onUpdateSettings({
      ...settings,
      dashType: item,
      templateId:
        settings.dashType === "dashboard" ||
          settings.dashType === "storytelling" || settings.dashType === "table"
          ? "default"
          : settings.templateId,
    });
  };

  return (
    <ProjectTypeWrapper>
      <ProjectTypeItem
        onClick={() => handleItemClick("dashboard")}
        $selectedItem={settings.dashType === "dashboard"}
      >
        <ProjectTypeIconWrapper>
          <DashboardIcon />
          {settings.dashType === "dashboard" ? (
            <ActiveRadioButton />
          ) : (
            <>
              <HoveredRadioButton />
              <InactiveRadioButton />
            </>
          )}
        </ProjectTypeIconWrapper>
        <ProjectTypeDescriptionWrapper>
          <ProjectTypeTitle>Dashboard</ProjectTypeTitle>
          <ProjectTypeDescription>
            Present data from different sources in a visually appealing and easily understandable manner. Interact with dashboard elements to drill down into details.
          </ProjectTypeDescription>
        </ProjectTypeDescriptionWrapper>
      </ProjectTypeItem>
      <ProjectTypeItem
        onClick={() => {
          handleItemClick("storytelling");
        }}
        $selectedItem={settings.dashType === "storytelling"}
      >
        <ProjectTypeIconWrapper>
          <StorytellingIcon />
          {settings.dashType === "storytelling" ? (
            <ActiveRadioButton />
          ) : (
            <>
              <HoveredRadioButton />
              <InactiveRadioButton />
            </>
          )}
        </ProjectTypeIconWrapper>
        <ProjectTypeDescriptionWrapper>
          <ProjectTypeTitle>Storytelling</ProjectTypeTitle>
          <ProjectTypeDescription>
            A storytelling dashboard is a specialized type of dashboard designed
            to present data and information in a narrative or storytelling
            format.
          </ProjectTypeDescription>
        </ProjectTypeDescriptionWrapper>
      </ProjectTypeItem>
      <ProjectTypeItem
        onClick={() => handleItemClick("table")}
        $selectedItem={settings.dashType === "table"}
        $fullWidth={true}
      >
        <ProjectTypeIconWrapper>
          <DashboardIcon />
          {settings.dashType === "table" ? (
            <ActiveRadioButton />
          ) : (
            <>
              <HoveredRadioButton />
              <InactiveRadioButton />
            </>
          )}
        </ProjectTypeIconWrapper>
        <ProjectTypeDescriptionWrapper>
          <ProjectTypeTitle>Table</ProjectTypeTitle>
          <ProjectTypeDescription>
            Present data from different sources in a visually appealing and easily understandable manner. Interact with table elements to drill down into details.
          </ProjectTypeDescription>
        </ProjectTypeDescriptionWrapper>
      </ProjectTypeItem>
    </ProjectTypeWrapper>
  );
};
