import styled from "styled-components";

export const DatasetsItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 20px 0 20px;
  max-height: 400px;
  overflow: auto;
  padding-bottom: 16px;
   {
    /*//todo After MVP Release*/
  }
  // background-image: linear-gradient(
  //   to right,
  //   ${({ theme }) => theme.neutralBorder} 33%,
  //   rgba(255, 255, 255, 0) 0%
  // );
  background-position: bottom;
  background-size: 8px 1px;
  background-repeat: repeat-x;
`;

// todo after MVP release
export const MeasuresWrapper = styled.div`
  margin: 16px 0;
`;
export const MeasuresItemsWrapper = styled.div`
  margin: 8px 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;
export const MeasuresHeading = styled.div`
  margin: 0 20px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText}}
`;
