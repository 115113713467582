import {
  PopupRating,
  PopupRow,
  PopupRowData,
  PopupRowTitle,
  PopupRowWrapper,
  PopupSecondTitle,
  PopupTitle,
  PopupTitleRowWrapper,
} from "./PopupComponents";

export const Popup = () => {
  return (
    <>
      <PopupTitleRowWrapper>
        <PopupRow>
          <PopupTitle>Hospital</PopupTitle>
          <PopupRating>4.8</PopupRating>
        </PopupRow>
        <PopupSecondTitle>Northwell Health Hospital</PopupSecondTitle>
      </PopupTitleRowWrapper>
      <PopupRowWrapper>
        <PopupRowTitle>Working Hours</PopupRowTitle>
        <PopupRowData>Monday-Sunday: 8:00 AM - 7:00 PM</PopupRowData>
      </PopupRowWrapper>
      <PopupRowWrapper>
        <PopupRowTitle>Services Provided</PopupRowTitle>
        <PopupRowData>
          Emergency Care General Surgery Maternity Services Radiology and
          Imaging
        </PopupRowData>
      </PopupRowWrapper>
      <PopupRowWrapper>
        <PopupRowTitle>Public Transportation Access</PopupRowTitle>
        <PopupRowData>
          Hospital is located near the Main Street Bus Stop.
        </PopupRowData>
      </PopupRowWrapper>
    </>
  );
};
