export function getGeoJsonData(usCounties: { [name: string]: any; }): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
  return {
    type: "FeatureCollection",
    features: usCounties.features.map((feature: { properties: { GEOID: any; }; geometry: any; }) => ({
      type: "Feature",
      properties: {
        county: feature.properties.GEOID,
      },
      geometry: feature.geometry,
    })),
  };
}
