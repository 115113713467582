import styled from "styled-components";

export const ProjectCardUsersWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  height: 40px;
  width: 160px;
  display: flex;
  z-index: 1;
`;

export const UserIconWrapper = styled.div<{ $user: string }>`
  height: 34px;
  width: 34px;
  margin-left: -10px;
  border-radius: 16px;
  background: rgb(154, 148, 248);
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    margin-left: 0;
  }
`;

export const UserIcon = styled.div`
  background: ${({ theme }) => theme.primaryBackgroundActive};
  border-radius: 40px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  font-size: 12px;
  font-weight: 400;

  &:hover {
    border: 2px solid ${({ theme }) => theme.primaryBorder};
  }
`;
