import React, { useEffect, useMemo, useRef, useState } from "react";
import { Group } from "@visx/group";
import { scaleLinear, scaleBand } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import useMeasure from "react-use-measure";
import { useDispatch, useSelector } from "react-redux";

import {
  getIsEditMode,
  getIsPublicMode,
} from "../../../../store/selectors/main";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../../store/selectors/projects";
import { getAiSuggestions } from "../../../../store/selectors/widgets";
import { AiSuggestionsDto, WidgetItem } from "../../../../models/Widgets";
import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  HeadingNameAndButton,
  SettingsButtonWrapper,
  Title,
} from "../../VerticalBarchart/styles";
import { ChartLegend, ChartLegendValue } from "../../../ChartLegend";
import { setActiveModal } from "../../../../store/slices/modals";
import { hexToRGBA } from "../../../../helpers/hexToRgba";
import { calculateNumTicks } from "../../widgetHelpers";
import { ticksFormatter } from "../../../../helpers/ticksFormatter";
import { Tooltip } from "../../Tooltip";
import _ from "lodash";
import { Loader } from "../../../Loader";
import { setCurrentWidget } from "../../../../store/slices/projectPages";
import { SelectBage } from "../../SelectBage";
import { replaceWords } from "../../../../helpers/replaceName";
import { LollipopMarkersHorizontal } from "../../utils/getMarker";
import {
  get_data,
  get_xAxe,
  get_yAxe,
  getGroupedData,
} from "../utils/getLollipopChartMarkers";
import {
  getCurrentColor,
  getCurrentMarker,
} from "../../utils/getCurrentMarker";
import { AVAILABLE_WIDGETS } from "../../../../constants/widgetRecomended";
import {
  getClientPosition,
  LabelTooltip,
  TickLabel,
} from "../../components/LabelTooltip";
import { createPortal } from "react-dom";
import { getPosition } from "../utils/getLollipopAlign";
import { getActiveModal } from "../../../../store/selectors/modals";

interface LollipopInterface {
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  currentWidget: WidgetItem;
  hideName?: boolean;
}

export const HorizontalLollipopChart = ({
  storytelling,
  recommended,
  showLegend = true,
  selected = false,
  currentWidget,
  hideName = false,
}: LollipopInterface) => {
  const dispatch = useDispatch();

  const [ref, bounds] = useMeasure();
  const [refWidget, boundsWidget] = useMeasure();

  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const aiSuggestions = useSelector(getAiSuggestions);

  const divRef = useRef<HTMLDivElement | null>(null);
  const [groupedData, setGroupedData] = useState<{
    [keey: string]: { x: number; y: number }[];
  }>({});
  const [data, setData] = useState<{ x: number; y: number }[]>([]);
  const [yAxes, setYAxes] = useState([]);
  const [xAxe, setXAxe] = useState<string>();
  const [yAxe, setYAxe] = useState<string>();
  const [refHeight, setRefHeight] = useState<number>();
  const [legendValues, setLegendValues] = useState<ChartLegendValue[]>([]);
  const [tooltip, setTooltip] = useState<{
    name?: string;
    data: { [key: string]: string };
    x: number;
    y: number;
  } | null>(null);
  const [hoveredElement, setHoveredElement] = useState<null | string>(null);
  const [labelTooltip, setLabelTooltip] = useState<{
    data: string;
    x: number;
    y: number;
  } | null>(null);

  const margin = { top: 5, bottom: 30, left: 40, right: 0 };
  const width = bounds.width || 1084;
  const height = bounds.height || 163;

  const heightCheck =
    (legendValues.length * 2 - 1) * 3 + (legendValues.length * 2 - 1) * 40;

  useEffect(() => {
    setRefHeight(divRef?.current?.parentElement?.clientHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    divRef,
    divRef?.current?.parentElement?.clientHeight,
    window?.innerHeight,
  ]);

  const totalSpacePerGroup =
    heightCheck < refHeight! ? refHeight! : heightCheck;

  const chartValues =
    (currentWidget &&
      currentWidget.formatting &&
      currentWidget.formatting.length) ??
    1;

  const yScale = scaleBand({
    range: [
      margin.top,
      chartValues + data?.map((d: any) => d.x).length > 7
        ? totalSpacePerGroup
        : height - margin.bottom,
    ],
    domain: data?.map((d: any) => d.x),
    padding: 0,
  });

  const xScale = scaleLinear({
    domain: [0, Math.max(...yAxes)],
    nice: true,
    range: [margin.left, width - margin.right],
  });

  useEffect(() => {
    const lollipopChartSuggestion = aiSuggestions?.filter(
      (chart: AiSuggestionsDto) => chart.chartType === "lollipopChart"
    )[0];

    if (currentWidget) {
      const xAxe = get_xAxe(currentWidget, lollipopChartSuggestion);
      setXAxe(xAxe);

      const yAxe = get_yAxe(currentWidget, lollipopChartSuggestion);
      setYAxe(yAxe);

      const data = get_data(currentWidget, lollipopChartSuggestion);
      setData(data || []);

      const yAxes =
        data?.reduce((t: any, l: any) => {
          const y = parseFloat(l.y);
          if (typeof y === "number" && !isNaN(y)) {
            return [...t, y];
          }
          return t;
        }, []) || [];
      setYAxes(yAxes);

      let groupedData: any = getGroupedData(
        currentWidget,
        lollipopChartSuggestion
      );

      const newLegendValues = [];
      const groupBy = currentWidget?.groupBy?.at(0);

      const uniqueValuesKeys =
        (currentWidget?.uniqueValues &&
          Object.keys(currentWidget?.uniqueValues!)) ||
        [];
      const groupByKey =
        groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);

      const chartGroupKeys =
        uniqueValuesKeys?.length && currentWidget?.uniqueValues
          ? currentWidget?.uniqueValues[groupByKey!]
          : Object.keys(groupedData);

      if (groupedData && chartGroupKeys?.length) {
        for (let i = 0; i < chartGroupKeys?.length; i++) {
          const dataKey = chartGroupKeys?.at(i);
          const color = getCurrentColor(currentWidget, dataKey, styleId);
          newLegendValues.push({ label: dataKey!, color });
        }
      }
      setLegendValues(newLegendValues);
      setGroupedData(groupedData);
    }
  }, [aiSuggestions, currentWidget, styleId]);

  const numTicks = useMemo(
    () => calculateNumTicks({ height: height, width: width }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [height]
  );

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  if (_.isEmpty(groupedData)) {
    return (
      <>
        <div style={{ height: "100%", width: "100%" }}>
          <Loader blur={false} />
        </div>
      </>
    );
  }

  const gKeys = Object.keys(groupedData);
  const gdata = [...gKeys, ...yScale.domain()];

  return (
    <>
      <HeaderWrapper ref={refWidget}>
        {!storytelling && (
          <HeadingNameAndButton>
            {!hideName ? <Title>{name}</Title> : <div />}
            {!isPublicRoute && !recommended && isEditMode ? (
              <SettingsButtonWrapper
                $modalOpen={
                  !!activeModal?.length &&
                  modalCurrentWidget?.id === currentWidget?.id
                }
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {AVAILABLE_WIDGETS["lollipopChart"]?.length}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </HeadingNameAndButton>
        )}
        {legendValues?.length > 1 &&
          chartValues > 1 &&
          showLegend &&
          currentWidget.legend && (
            <ChartLegend
              chartWidth={boundsWidget.width}
              legendType="unit"
              legendValues={legendValues}
            />
          )}
      </HeaderWrapper>
      <div
        ref={divRef}
        style={
          gdata.length > 7
            ? {
                height:
                  (refHeight || 240) - (!!storytelling ? 0 : 40 * 2) || 160,
                minHeight: 50,
                overflowY: "auto",
              }
            : {
                height: `${
                  (refHeight || 180) - (!!storytelling ? 0 : 14 + 14 + 14)
                }px`,
              }
        }
      >
        <svg
          width="100%"
          height={gdata.length > 7 ? totalSpacePerGroup : "100%"}
          ref={ref}
        >
          <Group
            top={margin.top}
            left={
              Object.values(groupedData)[0]?.some(
                (el) => String(el?.x)?.length > 4
              )
                ? 30
                : -9
            }
          >
            <Group>
              {yScale.domain().map((value, index) => (
                <line
                  key={`${value}-${index}`}
                  x1={margin.left}
                  y1={yScale(value)!}
                  x2={width - margin.right}
                  y2={yScale(value)!}
                  stroke="#ccc"
                  strokeDasharray="1 2"
                />
              ))}
            </Group>
            <Group>
              {xScale.ticks(numTicks).map((value, index) => (
                <line
                  key={`${value}-${index}`}
                  x1={xScale(value)}
                  y1={margin.top}
                  x2={xScale(value)}
                  y2={
                    gdata.length > 7
                      ? totalSpacePerGroup
                      : height - margin.bottom
                  }
                  stroke="#ccc"
                  strokeDasharray="1 2"
                />
              ))}
            </Group>
            <Group style={{ minHeight: 500 }}>
              {groupedData &&
                Object.keys(groupedData)?.map((key: string, val: number) => {
                  const groupData = groupedData[key] as any;

                  const yVal = getPosition(val, chartValues);
                  return groupData?.map((d: any, index: number) => {
                    const color = getCurrentColor(currentWidget, key, styleId);

                    const barKey = `${d.x}-${index}-${val}`;

                    return (
                      <g
                        key={barKey}
                        style={{ transition: "0.3s" }}
                        onMouseMove={(e: any) => {
                          if (
                            (showTooltip || currentWidget.tooltip) &&
                            !recommended
                          ) {
                            const { x, y } = getClientPosition(e);

                            setHoveredElement(barKey);

                            setTooltip({
                              name: key !== "default" ? key : undefined,
                              data: {
                                [xAxe as string]: d.x,
                                [yAxe as string]: d.y,
                              },
                              x: x - 28,
                              y: y - 85,
                            });
                          }
                        }}
                        onMouseLeave={() => {
                          setTooltip(null);
                          setHoveredElement(null);
                        }}
                        opacity={
                          hoveredElement
                            ? hoveredElement === barKey
                              ? 1
                              : 0.4
                            : 1
                        }
                      >
                        <line
                          x1={xScale(d.y)}
                          y1={yScale(d.x)! + yScale.bandwidth() / 2 + yVal}
                          x2={margin.left}
                          y2={yScale(d.x)! + yScale.bandwidth() / 2 + yVal}
                          stroke={hexToRGBA(color!, 0.5)}
                          strokeWidth={3}
                        />

                        {!!color &&
                          LollipopMarkersHorizontal({
                            markerType: getCurrentMarker(
                              currentWidget,
                              key,
                              "rhombus"
                            ),
                            yScale:
                              yScale(d.x)! +
                              yScale.bandwidth() / 2 -
                              (legendValues.length - legendValues.length / 3 ||
                                1),
                            yVal: yVal,
                            yScaleBand: yScale.bandwidth() / 2 / 10,
                            color: color,
                            xScale: xScale(d.y) + 1,
                          })}
                      </g>
                    );
                  });
                })}
            </Group>
            <AxisLeft
              left={margin.left}
              scale={yScale}
              top={margin.top}
              hideTicks
              hideAxisLine
              label={""}
              numTicks={gdata.length}
              tickFormat={(value: any) => {
                return value;
              }}
              tickLabelProps={(value) => ({
                dx: value.length >= 5 || xAxe !== "year" ? -68 : -32,
                fontSize: 11,
                width: 30,
                fill: "#5F6877",
                textAnchor: "start",
                dy: -1,
              })}
              tickComponent={(props) => (
                <TickLabel
                  {...props}
                  setTooltip={setLabelTooltip}
                  length={8}
                  offsetY={15}
                />
              )}
            />
            {!(gdata.length > 7) && (
              <AxisBottom
                scale={xScale}
                top={height - margin.bottom}
                hideTicks
                numTicks={numTicks}
                tickFormat={(value: any) => {
                  return ticksFormatter(value);
                }}
                tickLabelProps={(_, index, values) => {
                  const isFirstTick = index === 0;
                  const isLastTick = index === values.length - 1;
                  const textAnchor =
                    (isFirstTick && "start") ||
                    (isLastTick && "end") ||
                    "middle";

                  return {
                    fontSize: 10,
                    fill: "#5F6877",
                    textAnchor,
                    dx: 0,
                  };
                }}
                axisLineClassName="barchartAxisLine"
              />
            )}
          </Group>
        </svg>
      </div>
      {gdata.length > 7 && (
        <svg height={"25"} width={"100%"}>
          <AxisBottom
            scale={xScale}
            top={1}
            left={margin.left - 50}
            hideTicks
            numTicks={numTicks}
            tickFormat={(value: any) => {
              return ticksFormatter(value);
            }}
            tickLabelProps={(_, index, values) => {
              const isFirstTick = index === 0;
              const isLastTick = index === values.length - 1;
              const textAnchor =
                (isFirstTick && "start") || (isLastTick && "end") || "middle";

              return {
                fontSize: 10,
                fill: "#5F6877",
                textAnchor,
                dx: 0,
              };
            }}
            axisLineClassName="barchartAxisLine"
          />
        </svg>
      )}
      {tooltip &&
        xAxe &&
        yAxe &&
        createPortal(
          <Tooltip
            x={tooltip.x}
            y={tooltip.y}
            xAxe={xAxe}
            yAxe={yAxe}
            data={tooltip.data}
            name={tooltip.name}
          />,
          document.body
        )}
      {labelTooltip && (
        <LabelTooltip
          x={labelTooltip?.x}
          y={labelTooltip?.y}
          data={labelTooltip?.data}
        />
      )}
    </>
  );
};
