import { createPortal } from "react-dom";
import { useRef } from "react";

import {
  CheckboxLabel,
  CheckboxText,
  DropdownMenuWrapper,
  DropdownTitle,
} from "./styles";
import { ISection } from "../../../../../../../api/sections";
import { CheckBox } from "../../../../../../Inputs/CustomCheckbox/CheckBox";
import { useOutsideClick } from "../../../../../../../hooks/useOutsideClick";

interface SectionDropdownMenuProps {
  item: ISection;
  open: boolean;
  isOpen: boolean;
  top: number;
  left: number;
  setContentItem: (item: ISection) => void;
  closeMenu: () => void;
}

export const SectionDropdownMenu: React.FC<SectionDropdownMenuProps> = ({
  item,
  isOpen,
  open,
  top,
  left,
  setContentItem,
  closeMenu,
}) => {
  const ref = useRef(null);
  useOutsideClick(ref, () => {
    closeMenu();
  });
  return open && isOpen ? (
    createPortal(
      <DropdownMenuWrapper
        ref={ref}
        $isOpen={!!(!!open && !!isOpen)}
        $top={top}
        $left={left}
      >
        <DropdownTitle>Show</DropdownTitle>
        <CheckboxLabel $active={!!item?.showTitle}>
          <CheckBox
            checked={!!item?.showTitle}
            status={!!item?.showTitle ? "checked" : "none"}
            onChange={() =>
              setContentItem({
                ...item,
                showTitle: !item?.showTitle,
              })
            }
          />
          <CheckboxText>
            <span>Title</span>
          </CheckboxText>
        </CheckboxLabel>
        <CheckboxLabel $active={!!item?.showDescription}>
          <CheckBox
            checked={!!item?.showDescription}
            status={!!item?.showDescription ? "checked" : "none"}
            onChange={(e) =>
              setContentItem({
                ...item,
                showDescription: !item.showDescription,
              })
            }
          />
          <CheckboxText>
            <span>Text</span>
          </CheckboxText>
        </CheckboxLabel>

        <CheckboxLabel $active={!!item?.showImage}>
          <CheckBox
            checked={!!item?.showImage}
            status={!!item?.showImage ? "checked" : "none"}
            onChange={(e) =>
              setContentItem({
                ...item,
                showImage: !item.showImage,
              })
            }
          />
          <CheckboxText>
            <span>Image</span>
          </CheckboxText>
        </CheckboxLabel>

        <CheckboxLabel $active={!!item?.showKpi}>
          <CheckBox
            checked={!!item?.showKpi}
            status={!!item?.showKpi ? "checked" : "none"}
            onChange={(e) =>
              setContentItem({
                ...item,
                showKpi: !item.showKpi,
              })
            }
          />
          <CheckboxText>
            <span>KPI</span>
          </CheckboxText>
        </CheckboxLabel>
      </DropdownMenuWrapper>,
      document.body
    )
  ) : (
    <></>
  );
};
