import styled from "styled-components";

export const PopupWrapper = styled.div`
  border: 1px #d3dbe3 solid;
  position: absolute;
  left: 16px;
  bottom: 16px;
  padding: 10px;
  background-color: #ffffff;
  z-index: 1000;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 184px;
`;

export const PopupRow = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const PopupTitleRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const PopupRating = styled.div`
  color: #b59755;
  background: #e8c26d33;
  border: 1px #e8c26d33 solid;
  font-size: 11px;
  line-height: 14px;
  border-radius: 12px;
  padding: 0 4px;
`;
export const PopupTitle = styled.div`
  color: ${({ theme }) => theme.neutralText};
  font-size: 12px;
  line-height: 14px;
`;

export const PopupSecondTitle = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 14px;
  line-height: 16px;
`;

export const PopupRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const PopupRowTitle = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 12px;
  line-height: 14px;
`;

export const PopupRowData = styled.div`
  color: ${({ theme }) => theme.neutralText};
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
`;
