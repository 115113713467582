import styled from "styled-components";

import { ReactComponent as MainLogo } from "../../assets/logo.svg";
import { ReactComponent as DashboardImage } from "../../assets/dashboard.svg";
import background from "../../assets/mobileView.svg";

export const MobileViewWrapper = styled.div`
  position: absolute;
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% -60px;
  display: flex;
  padding: 40px;
  flex-direction: column;
  height: calc(100% - 120px);

  @media (min-width: 550px) {
    background-position: 50% 30%;
  }
`;

export const LogoWrapper = styled(MainLogo)`
  flex: none;
`;

export const MobileViewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 116px;
`;

export const MobileViewImage = styled(DashboardImage)`
  width: 130px;
  height: 130px;
`;

export const MobileViewDataWrapper = styled.div`
  text-align: center;
`;

export const MobileViewTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.neutralText};
  padding-top: 24px;
  padding-bottom: 8px;
`;

export const MobileViewDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
`;
