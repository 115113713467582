export const getPosition = (idx: number, keys: number) => {
  const currentIdx = idx + 1;
  const step = 6;
  const halfKeys = keys / 2;

  if (keys % 2) {
    const centerIdx = Math.ceil(halfKeys);

    if (currentIdx < centerIdx) {
      return -step * currentIdx;
    } else if (currentIdx === centerIdx) {
      return 0;
    } else {
      return step * (currentIdx - centerIdx);
    }
  } else {
    const centerIdx = halfKeys + 0.5;

    if (currentIdx < centerIdx) {
      if (currentIdx + 0.5 === centerIdx) {
        return -3;
      } else {
        return -(3 + currentIdx * step);
      }
    } else {
      if (currentIdx - 0.5 === centerIdx) {
        return 3;
      } else {
        return 3 + currentIdx * step;
      }
    }
  }
};