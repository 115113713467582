import React from "react";
import { useDispatch } from "react-redux";

import { Button } from "../Button";
import { ContainerWrapper, Description, Heading, InfoWrapper } from "./styles";
import { closeActiveModal, setActiveModal } from "../../store/slices/modals";
import { setNewProjectDetails } from "../../store/slices/datasets";
import { PlusCircle } from "react-bootstrap-icons";

interface AddDatasetsContainerInterface {
  name: string;
  description: string;
  disabledButton: boolean;
}
export const AddDatasetsContainer = ({
  name,
  description,
  disabledButton,
}: AddDatasetsContainerInterface) => {
  const dispatch = useDispatch();

  return (
    <ContainerWrapper>
      <InfoWrapper>
        <Heading>
          Datasets
          <Button
            onClick={() => {
              dispatch(setNewProjectDetails({ name, description }));
              dispatch(closeActiveModal());
              dispatch(
                setActiveModal({ id: "datasetLibraryModal", back: true })
              );
            }}
            variant="primary"
            size="small"
            name="Add Data"
            disabled={disabledButton}
            icon={<PlusCircle />}
          />
        </Heading>
        <Description>
          Select a dataset or specific data you would like to showcase.
        </Description>
      </InfoWrapper>
    </ContainerWrapper>
  );
};
