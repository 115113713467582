import React from "react";
import { LoaderWrapper, LoadingSpinner, SpinnerContainer } from "./styles";

interface LoaderInterface {
  fullScreen?: boolean;
  blur?: boolean;
}
export const Loader = ({
  fullScreen = false,
  blur = true,
}: LoaderInterface) => {
  return (
    <LoaderWrapper $fullScreen={fullScreen} $blur={blur}>
      <SpinnerContainer>
        <LoadingSpinner />
      </SpinnerContainer>
    </LoaderWrapper>
  );
};
