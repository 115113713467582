import React from "react";
import { useNavigate } from "react-router-dom";

import {
  ActionWrapper,
  AuthPageWrapper,
  AuthWrapper,
  Background,
  ConfirmationHeading,
  ConfirmationsDescription,
  ConfirmationWrapper,
  Container,
  Email,
  EmailVerificationSVG,
  Logo,
  LogoWrapper,
} from "../../styles";
import { Button } from "../../../../components/Button";
import { Check } from "react-bootstrap-icons";

type Props = {
  setStep: () => void;
  email: string;
}

export const PasswordResetConfirmation: React.FC<Props> = ( { setStep, email }: Props) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Background />
      <AuthPageWrapper>
        <LogoWrapper>
          <Logo onClick={() => navigate("/")} />
        </LogoWrapper>
        <AuthWrapper>
          <ConfirmationWrapper>
            <EmailVerificationSVG />
            <ConfirmationHeading>Check your email</ConfirmationHeading>
            <ConfirmationsDescription>
              We have just sent an email with reset link to 
              <Email>
                {email}.
              </Email>
            </ConfirmationsDescription>
            <ActionWrapper>
              <Button
                onClick={() => {}}
                variant="neutral"
                size="medium"
                name="Resend"
              />
              <Button
                onClick={setStep}
                variant="secondary"
                size="medium"
                name="Got it"
                icon={<Check />}
              />
            </ActionWrapper>
          </ConfirmationWrapper>
        </AuthWrapper>
      </AuthPageWrapper>
    </Container>
  );
};
