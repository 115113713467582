import styled from "styled-components";

export const StyledImagePickerWrapper = styled.div`
  overflow-y: unset !important;
  width: 256px !important;
  height: 209px !important;
  margin: 16px 15px 30px 15px !important;
`;

export const Image = styled.img`
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  object-fit: contain;
`;

export const BackgroundImage = styled.div`
  background-color: ${({ theme }) => theme.neutralBorder};
  height: 100%;
  border-radius: 6px;
  border: ${({ theme }) => `1px solid ${theme.neutralBorder}`};
`;

export const UploadInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const GenerateLabel = styled.label`
  transition: 0.2s;
  background-color: ${({ theme }) => theme.neutralBackground};
  color: ${({ theme }) => theme.neutralTextWeak};
  width: 100%;
  border-radius: 4px;
  display: block;
  padding: 8px;
  margin-top: 16px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
    color: ${({ theme }) => theme.neutralText};

  }
  &:active {
    background-color: ${({ theme }) => theme.neutralBackgroundActive};
    color: ${({ theme }) => theme.neutralText};
  }
`;

export const UploadLabel = styled.label`
  transition: 0.2s;
  background-color: ${({ theme }) => theme.primaryBackground};
  color: ${({ theme }) => theme.primaryText};
  width: 100%;
  border-radius: 4px;
  display: block;
  padding: 8px;
  margin-top: 16px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.primaryBackgroundMedium};
  color: ${({ theme }) => theme.primaryTextHover};

  }
  &:active {
    background-color: ${({ theme }) => theme.primaryBackgroundActive};
  color: ${({ theme }) => theme.primaryTextHover};
  }
`;
