import { XLg } from "react-bootstrap-icons";
import styled from "styled-components";

export const KPIWrapperMainContainer = styled.div<{
  $selected?: boolean;
  $recommended?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  height: 100%;
  margin: 8px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 8px;
`;

export const KPIWrapperBorder = styled.div`
  border-right: 1px solid ${({ theme }) => theme.neutralBorder};
  padding: 15px;
  &:last-child {
    border-right: none;
  }
`;

export const KPIWrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  width: 100%;
  height: 100%;
`;

export const KPISettings = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const CloseWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 15px;
  top: 14px;
`;

export const CloseIcon = styled(XLg)`
  cursor: pointer;
  filter: invert(56%) sepia(23%) saturate(181%) hue-rotate(179deg)
    brightness(89%) contrast(86%);
  margin-left: 16px;
`;
