import styled from "styled-components";

export const OptionsWrapper = styled.div<{ $isColorpicker?: boolean }>`
  position: absolute;
  top: 40px;
  right: 16px;
  height: ${({ $isColorpicker }) => ($isColorpicker ? "400px" : "390px")};
  width: auto;
  z-index: 2;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
`;

export const ContentWrapper = styled.div`
  height: calc(100% - 48px);
  overflow-y: auto;
  overflow-x: visible !important;
`;

export const OptionsMenuWrapper = styled.div`
  width: 283px;
  height: 36px;
  margin-top: 8px;
`;

export const OptionsMenuItemsWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding-left: 16px;
  height: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
`;
export const OptionsMenuItem = styled.div<{
  $activeItem?: boolean;
  $width?: number;
}>`
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme, $activeItem }) =>
    $activeItem ? theme.neutralText : theme.neutralTextWeak};

  &:after {
    display: ${({ $activeItem }) => ($activeItem ? "block" : "none")};
    content: "";
    height: 2px;
    width: ${({ $width }) => `${$width}px`};
    margin-top: 37px;
    background: ${({ theme }) => theme.primaryBorder};
    position: absolute;
  }
`;
