import { ColorPaletteInterfaceV2 } from "./types/types";

export const colorsArray = [
  {
    id: "default",
    colors: [
      { key: 1, hex: "#473DD9" },
      { key: 2, hex: "#938CFF" },
      { key: 3, hex: "#36C4A5" },
      { key: 4, hex: "#ADF4E5" },
      { key: 5, hex: "#BDBCC8" },
      { key: 6, hex: "#56546D" },
      { key: 7, hex: "#FF8C47" },
      { key: 8, hex: "#3C33B5FF" },
      { key: 9, hex: "#938CFF" },
      { key: 10, hex: "#36C4A5" },
      { key: 11, hex: "#89E8D4" },
      { key: 12, hex: "#BDBCC8" },
      { key: 13, hex: "#56546D" },
      { key: 14, hex: "#FF8C47" },
      { key: 15, hex: "#473DD9" },
      { key: 16, hex: "#89A3F7FF" },
      { key: 17, hex: "#238F7D" },
      { key: 18, hex: "#ADF4E5" },
      { key: 19, hex: "#A09FA8" },
      { key: 20, hex: "#43425C" },
      { key: 21, hex: "#FFA36CFF" },
      { key: 22, hex: "#5448FFFF" },
      { key: 23, hex: "#88B9F6FF" },
      { key: 24, hex: "#2A8A70" },
      { key: 25, hex: "#94FFD3" },
      { key: 26, hex: "#91909A" },
      { key: 27, hex: "#36355E" },
      { key: 28, hex: "#FFBA91FF" },
      { key: 29, hex: "#7369FFFF" },
      { key: 30, hex: "#8CCBF1FF" },
      { key: 31, hex: "#49A588" },
      { key: 32, hex: "#A9FFDC" },
      { key: 33, hex: "#A6A5AB" },
      { key: 34, hex: "#48476E" },
      { key: 35, hex: "#FFD1B5FF" },
      { key: 36, hex: "#938CFFFF" },
      { key: 37, hex: "#98DAEBFF" },
      { key: 38, hex: "#6BC3A2" },
      { key: 39, hex: "#BEFFE4" },
      { key: 40, hex: "#BBB9C1" },
      { key: 41, hex: "#5B5980" },
      { key: 42, hex: "#FFE8DAFF" },
      { key: 43, hex: "#B5B0FFFF" },
      { key: 44, hex: "#A8E7E7FF" },
      { key: 45, hex: "#8FDDBF" },
      { key: 46, hex: "#D4FFED" },
      { key: 47, hex: "#D0CFD6" },
      { key: 48, hex: "#6F6D8A" },
      { key: 49, hex: "#D9D3FFFF" },
      { key: 50, hex: "#ADF4E5FF" },
      { key: 51, hex: "#B3E8D8" },
      { key: 52, hex: "#E9FFF6" },
      { key: 53, hex: "#E5E4EB" },
      { key: 54, hex: "#84829A" },
    ],
  },
  {
    id: "palette1",
    colors: [
      { key: 1, hex: "#213B34" },
      { key: 2, hex: "#2F584F" },
      { key: 3, hex: "#BFD3C5" },
      { key: 4, hex: "#C6ED62" },
      { key: 5, hex: "#B59869" },
      { key: 6, hex: "#D6CDB6" },
      { key: 7, hex: "#EAE3D1" },
      { key: 8, hex: "#659681" },
      { key: 9, hex: "#789F92" },
      { key: 10, hex: "#E5F1EC" },
      { key: 11, hex: "#F1FFB5" },
      { key: 12, hex: "#D9C3A1" },
      { key: 13, hex: "#F2EAD7" },
      { key: 14, hex: "#FFFFFF" },
      { key: 15, hex: "#4B7F6C" },
      { key: 16, hex: "#649684" },
      { key: 17, hex: "#D0E9E2" },
      { key: 18, hex: "#DEFF8F" },
      { key: 19, hex: "#C5B98E" },
      { key: 20, hex: "#DFE0C4" },
      { key: 21, hex: "#F6F6F6" },
      { key: 22, hex: "#347655" },
      { key: 23, hex: "#508775" },
      { key: 24, hex: "#BBE0D9" },
      { key: 25, hex: "#CBFF6A" },
      { key: 26, hex: "#B2AF7A" },
      { key: 27, hex: "#CCE0B2" },
      { key: 28, hex: "#ECECEC" },
      { key: 29, hex: "#1F6440" },
      { key: 30, hex: "#3D7666" },
      { key: 31, hex: "#A7D8D0" },
      { key: 32, hex: "#B8FF43" },
      { key: 33, hex: "#9FA667" },
      { key: 34, hex: "#B9DFA0" },
      { key: 35, hex: "#E1E1E1" },
      { key: 36, hex: "#095E2E" },
      { key: 37, hex: "#296657" },
      { key: 38, hex: "#92CFCA" },
      { key: 39, hex: "#A5FF1F" },
      { key: 40, hex: "#8C9D54" },
      { key: 41, hex: "#A7DF8E" },
      { key: 42, hex: "#D7D7D7" },
      { key: 43, hex: "#213B34" },
      { key: 44, hex: "#2F584F" },
      { key: 45, hex: "#BFD3C5" },
      { key: 46, hex: "#C6ED62" },
      { key: 47, hex: "#B59869" },
      { key: 48, hex: "#D6CDB6" },
      { key: 49, hex: "#EAE3D1" },
      { key: 50, hex: "#0A4D49" },
      { key: 51, hex: "#194F46" },
      { key: 52, hex: "#9FC8BD" },
      { key: 53, hex: "#A9FF29" },
      { key: 54, hex: "#948640" },
      { key: 55, hex: "#B8D094" },
      { key: 56, hex: "#D0CFC0" },
    ],
  },
  {
    id: "palette2",
    colors: [
      { key: 1, hex: "#F64844" },
      { key: 2, hex: "#012938" },
      { key: 3, hex: "#00465E" },
      { key: 4, hex: "#047474" },
      { key: 5, hex: "#01A085" },
      { key: 6, hex: "#41CC97" },
      { key: 7, hex: "#84EEA7" },
      { key: 8, hex: "#FFB5B3" },
      { key: 9, hex: "#3C5E55" },
      { key: 10, hex: "#3C6673" },
      { key: 11, hex: "#4B8C8C" },
      { key: 12, hex: "#4BC2B3" },
      { key: 13, hex: "#8EF6CF" },
      { key: 14, hex: "#D4FFDF" },
      { key: 15, hex: "#FF9A98" },
      { key: 16, hex: "#2A5148" },
      { key: 17, hex: "#2A5966" },
      { key: 18, hex: "#3A7E7E" },
      { key: 19, hex: "#3AAFA0" },
      { key: 20, hex: "#7DE7BE" },
      { key: 21, hex: "#C1FFCE" },
      { key: 22, hex: "#FF7F7C" },
      { key: 23, hex: "#194D5A" },
      { key: 24, hex: "#297171" },
      { key: 25, hex: "#297171" },
      { key: 26, hex: "#299A91" },
      { key: 27, hex: "#6CD8AD" },
      { key: 28, hex: "#AEFFBD" },
      { key: 29, hex: "#FF6460" },
      { key: 30, hex: "#07362D" },
      { key: 31, hex: "#073F4D" },
      { key: 32, hex: "#186464" },
      { key: 33, hex: "#178584" },
      { key: 34, hex: "#5BC99C" },
      { key: 35, hex: "#9DFFAC" },
      { key: 36, hex: "#FF4945" },
      { key: 37, hex: "#002920" },
      { key: 38, hex: "#004142" },
      { key: 39, hex: "#075757" },
      { key: 40, hex: "#067076" },
      { key: 41, hex: "#4BBB8C" },
      { key: 42, hex: "#8BFF9B" },
      { key: 43, hex: "#F64844" },
      { key: 44, hex: "#012938" },
      { key: 45, hex: "#00465E" },
      { key: 46, hex: "#047474" },
      { key: 47, hex: "#01A085" },
      { key: 48, hex: "#41CC97" },
      { key: 49, hex: "#84EEA7" },
      { key: 50, hex: "#FF2E2A" },
      { key: 51, hex: "#001C2B" },
      { key: 52, hex: "#00364F" },
      { key: 53, hex: "#035757" },
      { key: 54, hex: "#007E70" },
      { key: 55, hex: "#35B884" },
      { key: 56, hex: "#73DB96" },
    ],
  },
  {
    id: "palette3",
    colors: [
      { key: 1, hex: "#CFAF65" },
      { key: 2, hex: "#7B9579" },
      { key: 3, hex: "#2E509A" },
      { key: 4, hex: "#F0BEBA" },
      { key: 5, hex: "#E25828" },
      { key: 6, hex: "#322F36" },
      { key: 7, hex: "#B8D7D2" },
      { key: 8, hex: "#F7E6B8" },
      { key: 9, hex: "#AFC2A3" },
      { key: 10, hex: "#587CB3" },
      { key: 11, hex: "#FFEBE9" },
      { key: 12, hex: "#FF9260" },
      { key: 13, hex: "#5D5A62" },
      { key: 14, hex: "#E7F5F2" },
      { key: 15, hex: "#EFDFA7" },
      { key: 16, hex: "#9EB592" },
      { key: 17, hex: "#476E9F" },
      { key: 18, hex: "#FFDFDB" },
      { key: 19, hex: "#FF8050" },
      { key: 20, hex: "#4C4952" },
      { key: 21, hex: "#D4EBE6" },
      { key: 22, hex: "#E7D996" },
      { key: 23, hex: "#8CAE81" },
      { key: 24, hex: "#365F8A" },
      { key: 25, hex: "#FFD2CD" },
      { key: 26, hex: "#FF6F40" },
      { key: 27, hex: "#3B3843" },
      { key: 28, hex: "#C1E1DA" },
      { key: 29, hex: "#DFD285" },
      { key: 30, hex: "#7BA770" },
      { key: 31, hex: "#254F76" },
      { key: 32, hex: "#FFC5BF" },
      { key: 33, hex: "#FF5D30" },
      { key: 34, hex: "#2B282F" },
      { key: 35, hex: "#ADE7CE" },
      { key: 36, hex: "#D7CB75" },
      { key: 37, hex: "#6A9F60" },
      { key: 38, hex: "#133F61" },
      { key: 39, hex: "#FFB9B1" },
      { key: 40, hex: "#FF4C21" },
      { key: 41, hex: "#1A171F" },
      { key: 42, hex: "#9AEFC2" },
      { key: 43, hex: "#CFAF65" },
      { key: 44, hex: "#7B9579" },
      { key: 45, hex: "#2E509A" },
      { key: 46, hex: "#F0BEBA" },
      { key: 47, hex: "#E25828" },
      { key: 48, hex: "#322F36" },
      { key: 49, hex: "#B8D7D2" },
      { key: 50, hex: "#C79A54" },
      { key: 51, hex: "#68856B" },
      { key: 52, hex: "#1B456F" },
      { key: 53, hex: "#E5AFAA" },
      { key: 54, hex: "#C8451E" },
      { key: 55, hex: "#211E28" },
      { key: 56, hex: "#A3CDC1" },
    ],
  },
  {
    id: "palette4",
    colors: [
      { key: 1, hex: "#F56600" },
      { key: 2, hex: "#F69A02" },
      { key: 3, hex: "#1C0F49" },
      { key: 4, hex: "#222359" },
      { key: 5, hex: "#7BA3C9" },
      { key: 6, hex: "#01A2A6" },
      { key: 7, hex: "#E9E8E1" },
      { key: 8, hex: "#FFA645" },
      { key: 9, hex: "#FFD03F" },
      { key: 10, hex: "#4D3C72" },
      { key: 11, hex: "#4D4B73" },
      { key: 12, hex: "#A8C9E0" },
      { key: 13, hex: "#4BCED2" },
      { key: 14, hex: "#FFFFFF" },
      { key: 15, hex: "#FF9632" },
      { key: 16, hex: "#FFC02F" },
      { key: 17, hex: "#3B2F61" },
      { key: 18, hex: "#3C3A63" },
      { key: 19, hex: "#97B9D5" },
      { key: 20, hex: "#3ABCC1" },
      { key: 21, hex: "#F6F6F6" },
      { key: 22, hex: "#FF861F" },
      { key: 23, hex: "#FFB01E" },
      { key: 24, hex: "#2A2251" },
      { key: 25, hex: "#2B2953" },
      { key: 26, hex: "#86A8CA" },
      { key: 27, hex: "#29AAB0" },
      { key: 28, hex: "#ECECEC" },
      { key: 29, hex: "#FF750C" },
      { key: 30, hex: "#FF9F0E" },
      { key: 31, hex: "#191641" },
      { key: 32, hex: "#1A1843" },
      { key: 33, hex: "#7597BF" },
      { key: 34, hex: "#1898A0" },
      { key: 35, hex: "#E1E1E1" },
      { key: 36, hex: "#FF6500" },
      { key: 37, hex: "#FF8E00" },
      { key: 38, hex: "#080932" },
      { key: 39, hex: "#090733" },
      { key: 40, hex: "#6486B4" },
      { key: 41, hex: "#078690" },
      { key: 42, hex: "#D7D7D7" },
      { key: 43, hex: "#F56600" },
      { key: 44, hex: "#F69A02" },
      { key: 45, hex: "#1C0F49" },
      { key: 46, hex: "#222359" },
      { key: 47, hex: "#7BA3C9" },
      { key: 48, hex: "#01A2A6" },
      { key: 49, hex: "#E9E8E1" },
      { key: 50, hex: "#E35400" },
      { key: 51, hex: "#E78900" },
      { key: 52, hex: "#0E0840" },
      { key: 53, hex: "#13104C" },
      { key: 54, hex: "#688FAF" },
      { key: 55, hex: "#007C80" },
      { key: 56, hex: "#D0CFC0" },
    ],
  },
];

export const colorsPalettesV2: ColorPaletteInterfaceV2[] = [
  {
    id: "default",
    label: "Brand (Default)",
    colors: [
      {
        mainColor: { key: 1, hex: "#473DD9" },
        variations: [
          { key: 8, hex: "#3C33B5FF" },
          { key: 15, hex: "#473DD9" },
          { key: 22, hex: "#5448FFFF" },
          { key: 29, hex: "#7369FFFF" },
          { key: 36, hex: "#938CFFFF" },
          { key: 43, hex: "#B5B0FFFF" },
          { key: 49, hex: "#D9D3FFFF" },
        ],
      },
      {
        mainColor: { key: 2, hex: "#938CFF" },
        variations: [
          { key: 9, hex: "#938CFF" },
          { key: 16, hex: "#89A3F7FF" },
          { key: 23, hex: "#88B9F6FF" },
          { key: 30, hex: "#8CCBF1FF" },
          { key: 37, hex: "#98DAEBFF" },
          { key: 44, hex: "#A8E7E7FF" },
          { key: 50, hex: "#ADF4E5FF" },
        ],
      },
      {
        mainColor: { key: 3, hex: "#36C4A5" },
        variations: [
          { key: 10, hex: "#36C4A5" },
          { key: 17, hex: "#238F7D" },
          { key: 24, hex: "#2A8A70" },
          { key: 31, hex: "#49A588" },
          { key: 38, hex: "#6BC3A2" },
          { key: 45, hex: "#8FDDBF" },
          { key: 51, hex: "#B3E8D8" },
        ],
      },
      {
        mainColor: { key: 4, hex: "#ADF4E5" },
        variations: [
          { key: 11, hex: "#89E8D4" },
          { key: 18, hex: "#ADF4E5" },
          { key: 25, hex: "#94FFD3" },
          { key: 32, hex: "#A9FFDC" },
          { key: 39, hex: "#BEFFE4" },
          { key: 46, hex: "#D4FFED" },
          { key: 52, hex: "#E9FFF6" },
        ],
      },
      {
        mainColor: { key: 5, hex: "#BDBCC8" },
        variations: [
          { key: 12, hex: "#BDBCC8" },
          { key: 19, hex: "#A09FA8" },
          { key: 26, hex: "#91909A" },
          { key: 33, hex: "#A6A5AB" },
          { key: 40, hex: "#BBB9C1" },
          { key: 47, hex: "#D0CFD6" },
          { key: 53, hex: "#E5E4EB" },
        ],
      },
      {
        mainColor: { key: 6, hex: "#56546D" },
        variations: [
          { key: 13, hex: "#56546D" },
          { key: 20, hex: "#43425C" },
          { key: 27, hex: "#36355E" },
          { key: 34, hex: "#48476E" },
          { key: 41, hex: "#5B5980" },
          { key: 48, hex: "#6F6D8A" },
          { key: 54, hex: "#84829A" },
        ],
      },
      {
        mainColor: { key: 7, hex: "#FF8C47" },
        variations: [
          { key: 14, hex: "#FF8C47" },
          { key: 21, hex: "#FFA36CFF" },
          { key: 28, hex: "#FFBA91FF" },
          { key: 35, hex: "#FFD1B5FF" },
          { key: 42, hex: "#FFE8DAFF" },
        ],
      },
    ],
  },
  {
    id: "palette1",
    label: "Palette 1",
    colors: [
      {
        mainColor: { key: 1, hex: "#213B34" },
        variations: [
          { key: 8, hex: "#659681" },
          { key: 15, hex: "#4B7F6C" },
          { key: 22, hex: "#347655" },
          { key: 29, hex: "#1F6440" },
          { key: 36, hex: "#095E2E" },
          { key: 43, hex: "#213B34" },
          { key: 50, hex: "#0A4D49" },
        ],
      },
      {
        mainColor: { key: 2, hex: "#2F584F" },
        variations: [
          { key: 9, hex: "#789F92" },
          { key: 16, hex: "#649684" },
          { key: 23, hex: "#508775" },
          { key: 30, hex: "#3D7666" },
          { key: 37, hex: "#296657" },
          { key: 44, hex: "#2F584F" },
          { key: 51, hex: "#194F46" },
        ],
      },
      {
        mainColor: { key: 3, hex: "#BFD3C5" },
        variations: [
          { key: 10, hex: "#E5F1EC" },
          { key: 17, hex: "#D0E9E2" },
          { key: 24, hex: "#BBE0D9" },
          { key: 31, hex: "#A7D8D0" },
          { key: 38, hex: "#92CFCA" },
          { key: 45, hex: "#BFD3C5" },
          { key: 52, hex: "#9FC8BD" },
        ],
      },
      {
        mainColor: { key: 4, hex: "#C6ED62" },
        variations: [
          { key: 11, hex: "#F1FFB5" },
          { key: 18, hex: "#DEFF8F" },
          { key: 25, hex: "#CBFF6A" },
          { key: 32, hex: "#B8FF43" },
          { key: 39, hex: "#A5FF1F" },
          { key: 46, hex: "#C6ED62" },
          { key: 53, hex: "#A9FF29" },
        ],
      },
      {
        mainColor: { key: 5, hex: "#B59869" },
        variations: [
          { key: 12, hex: "#D9C3A1" },
          { key: 19, hex: "#C5B98E" },
          { key: 26, hex: "#B2AF7A" },
          { key: 33, hex: "#9FA667" },
          { key: 40, hex: "#8C9D54" },
          { key: 47, hex: "#B59869" },
          { key: 54, hex: "#948640" },
        ],
      },
      {
        mainColor: { key: 6, hex: "#D6CDB6" },
        variations: [
          { key: 13, hex: "#F2EAD7" },
          { key: 20, hex: "#DFE0C4" },
          { key: 27, hex: "#CCE0B2" },
          { key: 34, hex: "#B9DFA0" },
          { key: 41, hex: "#A7DF8E" },
          { key: 48, hex: "#D6CDB6" },
          { key: 55, hex: "#B8D094" },
        ],
      },
      {
        mainColor: { key: 7, hex: "#EAE3D1" },
        variations: [
          { key: 14, hex: "#FFFFFF" },
          { key: 21, hex: "#F6F6F6" },
          { key: 28, hex: "#ECECEC" },
          { key: 35, hex: "#E1E1E1" },
          { key: 42, hex: "#D7D7D7" },
          { key: 49, hex: "#EAE3D1" },
          { key: 56, hex: "#D0CFC0" },
        ],
      },
    ],
  },
  {
    id: "palette2",
    label: "Palette 2",
    colors: [
      {
        mainColor: { key: 1, hex: "#F64844" },
        variations: [
          { key: 8, hex: "#FFB5B3" },
          { key: 15, hex: "#FF9A98" },
          { key: 22, hex: "#FF7F7C" },
          { key: 29, hex: "#FF6460" },
          { key: 36, hex: "#FF4945" },
          { key: 43, hex: "#F64844" },
          { key: 50, hex: "#FF2E2A" },
        ],
      },
      {
        mainColor: { key: 2, hex: "#012938" },
        variations: [
          { key: 9, hex: "#3C5E55" },
          { key: 16, hex: "#2A5148" },
          { key: 23, hex: "#19433A" },
          { key: 30, hex: "#07362D" },
          { key: 37, hex: "#002920" },
          { key: 44, hex: "#012938" },
          { key: 51, hex: "#001C2B" },
        ],
      },
      {
        mainColor: { key: 3, hex: "#00465E" },
        variations: [
          { key: 10, hex: "#3C6673" },
          { key: 17, hex: "#2A5966" },
          { key: 24, hex: "#194D5A" },
          { key: 31, hex: "#073F4D" },
          { key: 38, hex: "#004142" },
          { key: 45, hex: "#00465E" },
          { key: 52, hex: "#00364F" },
        ],
      },
      {
        mainColor: { key: 4, hex: "#047474" },
        variations: [
          { key: 11, hex: "#4B8C8C" },
          { key: 18, hex: "#3A7E7E" },
          { key: 25, hex: "#297171" },
          { key: 32, hex: "#186464" },
          { key: 39, hex: "#075757" },
          { key: 46, hex: "#047474" },
          { key: 53, hex: "#035757" },
        ],
      },
      {
        mainColor: { key: 5, hex: "#01A085" },
        variations: [
          { key: 12, hex: "#4BC2B3" },
          { key: 19, hex: "#3AAFA0" },
          { key: 26, hex: "#299A91" },
          { key: 33, hex: "#178584" },
          { key: 40, hex: "#067076" },
          { key: 47, hex: "#01A085" },
          { key: 54, hex: "#007E70" },
        ],
      },
      {
        mainColor: { key: 6, hex: "#41CC97" },
        variations: [
          { key: 13, hex: "#8EF6CF" },
          { key: 20, hex: "#7DE7BE" },
          { key: 27, hex: "#6CD8AD" },
          { key: 34, hex: "#5BC99C" },
          { key: 41, hex: "#4BBB8C" },
          { key: 48, hex: "#41CC97" },
          { key: 55, hex: "#35B884" },
        ],
      },
      {
        mainColor: { key: 7, hex: "#84EEA7" },
        variations: [
          { key: 14, hex: "#D4FFDF" },
          { key: 21, hex: "#C1FFCE" },
          { key: 28, hex: "#AEFFBD" },
          { key: 35, hex: "#9DFFAC" },
          { key: 42, hex: "#8BFF9B" },
          { key: 49, hex: "#84EEA7" },
          { key: 56, hex: "#73DB96" },
        ],
      },
    ],
  },
  {
    id: "palette3",
    label: "Palette 3",
    colors: [
      {
        mainColor: { key: 1, hex: "#CFAF65" },
        variations: [
          { key: 8, hex: "#F7E6B8" },
          { key: 15, hex: "#EFDFA7" },
          { key: 22, hex: "#E7D996" },
          { key: 29, hex: "#DFD285" },
          { key: 36, hex: "#D7CB75" },
          { key: 43, hex: "#CFAF65" },
          { key: 50, hex: "#C79A54" },
        ],
      },
      {
        mainColor: { key: 2, hex: "#7B9579" },
        variations: [
          { key: 9, hex: "#AFC2A3" },
          { key: 16, hex: "#9EB592" },
          { key: 23, hex: "#8CAE81" },
          { key: 30, hex: "#7BA770" },
          { key: 37, hex: "#6A9F60" },
          { key: 44, hex: "#7B9579" },
          { key: 51, hex: "#68856B" },
        ],
      },
      {
        mainColor: { key: 3, hex: "#2E509A" },
        variations: [
          { key: 10, hex: "#587CB3" },
          { key: 17, hex: "#476E9F" },
          { key: 24, hex: "#365F8A" },
          { key: 31, hex: "#254F76" },
          { key: 38, hex: "#133F61" },
          { key: 45, hex: "#2E509A" },
          { key: 52, hex: "#1B456F" },
        ],
      },
      {
        mainColor: { key: 4, hex: "#F0BEBA" },
        variations: [
          { key: 11, hex: "#FFEBE9" },
          { key: 18, hex: "#FFDFDB" },
          { key: 25, hex: "#FFD2CD" },
          { key: 32, hex: "#FFC5BF" },
          { key: 39, hex: "#FFB9B1" },
          { key: 46, hex: "#F0BEBA" },
          { key: 53, hex: "#E5AFAA" },
        ],
      },
      {
        mainColor: { key: 5, hex: "#E25828" },
        variations: [
          { key: 12, hex: "#FF9260" },
          { key: 19, hex: "#FF8050" },
          { key: 26, hex: "#FF6F40" },
          { key: 33, hex: "#FF5D30" },
          { key: 40, hex: "#FF4C21" },
          { key: 47, hex: "#E25828" },
          { key: 54, hex: "#C8451E" },
        ],
      },
      {
        mainColor: { key: 6, hex: "#322F36" },
        variations: [
          { key: 13, hex: "#5D5A62" },
          { key: 20, hex: "#4C4952" },
          { key: 27, hex: "#3B3843" },
          { key: 34, hex: "#2B282F" },
          { key: 41, hex: "#1A171F" },
          { key: 48, hex: "#322F36" },
          { key: 55, hex: "#211E28" },
        ],
      },
      {
        mainColor: { key: 7, hex: "#B8D7D2" },
        variations: [
          { key: 14, hex: "#E7F5F2" },
          { key: 21, hex: "#D4EBE6" },
          { key: 28, hex: "#C1E1DA" },
          { key: 35, hex: "#ADE7CE" },
          { key: 42, hex: "#9AEFC2" },
          { key: 49, hex: "#B8D7D2" },
          { key: 56, hex: "#A3CDC1" },
        ],
      },
    ],
  },
  {
    id: "palette4",
    label: "Palette 4",
    colors: [
      {
        mainColor: { key: 1, hex: "#F56600" },
        variations: [
          { key: 8, hex: "#FFA645" },
          { key: 15, hex: "#FF9632" },
          { key: 22, hex: "#FF861F" },
          { key: 29, hex: "#FF750C" },
          { key: 36, hex: "#FF6500" },
          { key: 43, hex: "#F56600" },
          { key: 50, hex: "#E35400" },
        ],
      },
      {
        mainColor: { key: 2, hex: "#F69A02" },
        variations: [
          { key: 9, hex: "#FFD03F" },
          { key: 16, hex: "#FFC02F" },
          { key: 23, hex: "#FFB01E" },
          { key: 30, hex: "#FF9F0E" },
          { key: 37, hex: "#FF8E00" },
          { key: 44, hex: "#F69A02" },
          { key: 51, hex: "#E78900" },
        ],
      },
      {
        mainColor: { key: 3, hex: "#1C0F49" },
        variations: [
          { key: 10, hex: "#4D3C72" },
          { key: 17, hex: "#3B2F61" },
          { key: 24, hex: "#2A2251" },
          { key: 31, hex: "#191641" },
          { key: 38, hex: "#080932" },
          { key: 45, hex: "#1C0F49" },
          { key: 52, hex: "#0E0840" },
        ],
      },
      {
        mainColor: { key: 4, hex: "#222359" },
        variations: [
          { key: 11, hex: "#4D4B73" },
          { key: 18, hex: "#3C3A63" },
          { key: 25, hex: "#2B2953" },
          { key: 32, hex: "#1A1843" },
          { key: 39, hex: "#090733" },
          { key: 46, hex: "#222359" },
          { key: 53, hex: "#13104C" },
        ],
      },
      {
        mainColor: { key: 5, hex: "#7BA3C9" },
        variations: [
          { key: 12, hex: "#A8C9E0" },
          { key: 19, hex: "#97B9D5" },
          { key: 26, hex: "#86A8CA" },
          { key: 33, hex: "#7597BF" },
          { key: 40, hex: "#6486B4" },
          { key: 47, hex: "#7BA3C9" },
          { key: 54, hex: "#688FAF" },
        ],
      },
      {
        mainColor: { key: 6, hex: "#01A2A6" },
        variations: [
          { key: 13, hex: "#4BCED2" },
          { key: 20, hex: "#3ABCC1" },
          { key: 27, hex: "#29AAB0" },
          { key: 34, hex: "#1898A0" },
          { key: 41, hex: "#078690" },
          { key: 48, hex: "#01A2A6" },
          { key: 55, hex: "#007C80" },
        ],
      },
      {
        mainColor: { key: 7, hex: "#E9E8E1" },
        variations: [
          { key: 14, hex: "#FFFFFF" },
          { key: 21, hex: "#F6F6F6" },
          { key: 28, hex: "#ECECEC" },
          { key: 35, hex: "#E1E1E1" },
          { key: 42, hex: "#D7D7D7" },
          { key: 49, hex: "#E9E8E1" },
          { key: 56, hex: "#D0CFC0" },
        ],
      },
    ],
  },
];
// default palette1 palette2 palette3 palette4
export const Qualitative2Colors1 = {
  default: {
    id: "Qualitative2Colors1",
    colors: [
      {
        key: "1",
        hex: "#473DD9",
      },
      {
        key: "2",
        hex: "#36C4A5",
      },
    ],
  },
  palette1: {
    id: "Qualitative2Colors1",
    colors: [
      {
        key: "1",
        hex: "#2039B4",
      },
      {
        key: "2",
        hex: "#88B802",
      },
    ],
  },
  palette2: {
    id: "Qualitative2Colors1",
    colors: [
      {
        key: "1",
        hex: "#852738",
      },
      {
        key: "2",
        hex: "#DE6A1C",
      },
    ],
  },
  palette3: {
    id: "Qualitative2Colors1",
    colors: [
      {
        key: "1",
        hex: "#4B625C",
      },
      {
        key: "2",
        hex: "#C29E1D",
      },
    ],
  },
  palette4: {
    id: "Qualitative2Colors1",
    colors: [
      {
        key: "1",
        hex: "#3D9E6E",
      },
      {
        key: "2",
        hex: "#D58000",
      },
    ],
  },
};

export const Qualitative2Colors2 = {
  default: {
    id: "Qualitative2Colors2",
    colors: [
      {
        key: "1",
        hex: "#938CFF",
      },
      {
        key: "2",
        hex: "#FF8C47",
      },
    ],
  },
  palette1: {
    id: "Qualitative2Colors2",
    colors: [
      {
        key: "1",
        hex: "#6C7ED9",
      },
      {
        key: "2",
        hex: "#D2382D",
      },
    ],
  },
  palette2: {
    id: "Qualitative2Colors2",
    colors: [
      {
        key: "1",
        hex: "#5942CB",
      },
      {
        key: "2",
        hex: "#969AA2",
      },
    ],
  },
  palette3: {
    id: "Qualitative2Colors2",
    colors: [
      {
        key: "1",
        hex: "#60838B",
      },
      {
        key: "2",
        hex: "#B0996F",
      },
    ],
  },
  palette4: {
    id: "Qualitative2Colors2",
    colors: [
      {
        key: "1",
        hex: "#506387",
      },
      {
        key: "2",
        hex: "#8B74DC",
      },
    ],
  },
};

export const Qualitative4Colors = {
  default: {
    id: "Qualitative4Colors",
    colors: [
      {
        key: "1",
        hex: "#473DD9",
      },
      {
        key: "2",
        hex: "#36C4A5",
      },
      {
        key: "3",
        hex: "#FF8C47",
      },
      {
        key: "4",
        hex: "#56546D",
      },
    ],
  },
  palette1: {
    id: "Qualitative4Colors",
    colors: [
      {
        key: "1",
        hex: "#2039B4",
      },
      {
        key: "2",
        hex: "#88B802",
      },
      {
        key: "3",
        hex: "#D2382D",
      },
      {
        key: "4",
        hex: "#56546D",
      },
    ],
  },
  palette2: {
    id: "Qualitative4Colors",
    colors: [
      {
        key: "1",
        hex: "#852738",
      },
      {
        key: "2",
        hex: "#19B2D9",
      },
      {
        key: "3",
        hex: "#DE6A1C",
      },
      {
        key: "4",
        hex: "#636874",
      },
    ],
  },
  palette3: {
    id: "Qualitative4Colors",
    colors: [
      {
        key: "1",
        hex: "#4B625C",
      },
      {
        key: "2",
        hex: "#94BC26",
      },
      {
        key: "3",
        hex: "#C29E1D",
      },
      {
        key: "4",
        hex: "#CABB9F",
      },
    ],
  },
  palette4: {
    id: "Qualitative4Colors",
    colors: [
      {
        key: "1",
        hex: "#55B685",
      },
      {
        key: "2",
        hex: "#8B74DC",
      },
      {
        key: "3",
        hex: "#E77975",
      },
      {
        key: "4",
        hex: "#338BCC",
      },
    ],
  },
};

export const Qualitative12Colors = {
  default: {
    id: "Qualitative12Colors",
    colors: [
      {
        key: "1",
        hex: "#473DD9",
      },
      {
        key: "2",
        hex: "#938CFF",
      },
      {
        key: "3",
        hex: "#36C4A5",
      },
      {
        key: "4",
        hex: "#ADF4E5",
      },
      {
        key: "5",
        hex: "#BDBCC8",
      },
      {
        key: "6",
        hex: "#56546D",
      },
      {
        key: "7",
        hex: "#FF8C47",
      },
      {
        key: "8",
        hex: "#FFCEB0",
      },
      {
        key: "9",
        hex: "#FF4781",
      },
      {
        key: "10",
        hex: "#FFB0C9",
      },
      {
        key: "11",
        hex: "#47B0FF",
      },
      {
        key: "12",
        hex: "#B0DDFF",
      },
    ],
  },
  palette1: {
    id: "Qualitative12Colors",
    colors: [
      {
        key: "1",
        hex: "#2039B4",
      },
      {
        key: "2",
        hex: "#6C7ED9",
      },
      {
        key: "3",
        hex: "#88B802",
      },
      {
        key: "4",
        hex: "#CEE78B",
      },
      {
        key: "5",
        hex: "#BDBCC8",
      },
      {
        key: "6",
        hex: "#56546D",
      },
      {
        key: "7",
        hex: "#D2382D",
      },
      {
        key: "8",
        hex: "#EAA39E",
      },
      {
        key: "9",
        hex: "#92BAD4",
      },
      {
        key: "10",
        hex: "#CDDFEB",
      },
      {
        key: "11",
        hex: "#AAAA97",
      },
      {
        key: "12",
        hex: "#D8D8CF",
      },
    ],
  },
  palette2: {
    id: "Qualitative12Colors",
    colors: [
      {
        key: "1",
        hex: "#852738",
      },
      {
        key: "2",
        hex: "#C95F72",
      },
      {
        key: "3",
        hex: "#19B2D9",
      },
      {
        key: "4",
        hex: "#66D7F4",
      },
      {
        key: "5",
        hex: "#DE6A1C",
      },
      {
        key: "6",
        hex: "#F8A269",
      },
      {
        key: "7",
        hex: "#5942CB",
      },
      {
        key: "8",
        hex: "#816DE5",
      },
      {
        key: "9",
        hex: "#969AA2",
      },
      {
        key: "10",
        hex: "#636874",
      },
      {
        key: "11",
        hex: "#E866E4",
      },
      {
        key: "12",
        hex: "#FF9FFC",
      },
    ],
  },
  palette3: {
    id: "Qualitative12Colors",
    colors: [
      {
        key: "1",
        hex: "#4B625C",
      },
      {
        key: "2",
        hex: "#6A8A82",
      },
      {
        key: "3",
        hex: "#94BC26",
      },
      {
        key: "4",
        hex: "#B5D955",
      },
      {
        key: "5",
        hex: "#C29E1D",
      },
      {
        key: "6",
        hex: "#DDBE4D",
      },
      {
        key: "7",
        hex: "#636874",
      },
      {
        key: "8",
        hex: "#969AA2",
      },
      {
        key: "9",
        hex: "#60838B",
      },
      {
        key: "10",
        hex: "#87B8C4",
      },
      {
        key: "11",
        hex: "#7D6D4F",
      },
      {
        key: "12",
        hex: "#B0996F",
      },
    ],
  },
  palette4: {
    id: "Qualitative12Colors",
    colors: [
      {
        key: "1",
        hex: "#77C59D",
      },
      {
        key: "2",
        hex: "#506387",
      },
      {
        key: "3",
        hex: "#EC9491",
      },
      {
        key: "4",
        hex: "#A45000",
      },
      {
        key: "5",
        hex: "#B5A3F5",
      },
      {
        key: "6",
        hex: "#267FC1",
      },
      {
        key: "7",
        hex: "#309263",
      },
      {
        key: "8",
        hex: "#2C3F64",
      },
      {
        key: "9",
        hex: "#C25553",
      },
      {
        key: "10",
        hex: "#671400",
      },
      {
        key: "11",
        hex: "#725CC5",
      },
      {
        key: "12",
        hex: "#025B9E",
      },
    ],
  },
};

export const SequentialColors = {
  default: [
    {
      id: "SequentialColors1",
      colors: [
        {
          key: "1",
          hex: "#D6D3FF",
        },
        {
          key: "2",
          hex: "#B5B0FF",
        },
        {
          key: "3",
          hex: "#938CFF",
        },
        {
          key: "4",
          hex: "#7369FF",
        },
        {
          key: "5",
          hex: "#5448FF",
        },
        {
          key: "6",
          hex: "#473DD9",
        },
        {
          key: "7",
          hex: "#3C33B5",
        },
      ],
    },
    {
      id: "SequentialColors2",
      colors: [
        {
          key: "1",
          hex: "#D1F9F0",
        },
        {
          key: "2",
          hex: "#ADF4E5",
        },
        {
          key: "3",
          hex: "#87EFD9",
        },
        {
          key: "4",
          hex: "#62EBCD",
        },
        {
          key: "5",
          hex: "#40E6C2",
        },
        {
          key: "6",
          hex: "#36C4A5",
        },
        {
          key: "7",
          hex: "#2DA38A",
        },
      ],
    },
    {
      id: "SequentialColors3",
      colors: [
        {
          key: "1",
          hex: "#FFE3D3",
        },
        {
          key: "2",
          hex: "#FFCEB0",
        },
        {
          key: "3",
          hex: "#FFB78B",
        },
        {
          key: "4",
          hex: "#FFA168",
        },
        {
          key: "5",
          hex: "#FFA168",
        },
        {
          key: "6",
          hex: "#D9773C",
        },
        {
          key: "7",
          hex: "#B56332",
        },
      ],
    },
    {
      id: "SequentialColors4",
      colors: [
        {
          key: "1",
          hex: "#938CFF",
        },
        {
          key: "2",
          hex: "#89A4F7",
        },
        {
          key: "3",
          hex: "#88B9F6",
        },
        {
          key: "4",
          hex: "#8CCBF1",
        },
        {
          key: "5",
          hex: "#98DAEB",
        },
        {
          key: "6",
          hex: "#A8E7E7",
        },
        {
          key: "7",
          hex: "#ADF4E5",
        },
      ],
    },
  ],
  palette1: [
    {
      id: "SequentialColors1",
      colors: [
        {
          key: "1",
          hex: "#BBC3ED",
        },
        {
          key: "2",
          hex: "#9AA7E5",
        },
        {
          key: "3",
          hex: "#6C7ED9",
        },
        {
          key: "4",
          hex: "#4F65D1",
        },
        {
          key: "5",
          hex: "#233FC6",
        },
        {
          key: "6",
          hex: "#2039B4",
        },
        {
          key: "7",
          hex: "#192D8D",
        },
      ],
    },
    {
      id: "SequentialColors2",
      colors: [
        {
          key: "1",
          hex: "#DEEFB1",
        },
        {
          key: "2",
          hex: "#CEE78B",
        },
        {
          key: "3",
          hex: "#B8DB55",
        },
        {
          key: "4",
          hex: "#AAD535",
        },
        {
          key: "5",
          hex: "#95CA02",
        },
        {
          key: "6",
          hex: "#88B802",
        },
        {
          key: "7",
          hex: "#6A8F01",
        },
      ],
    },
    {
      id: "SequentialColors3",
      colors: [
        {
          key: "1",
          hex: "#F1C1BE",
        },
        {
          key: "2",
          hex: "#EAA39E",
        },
        {
          key: "3",
          hex: "#E17A72",
        },
        {
          key: "4",
          hex: "#DB6057",
        },
        {
          key: "5",
          hex: "#D2382D",
        },
        {
          key: "6",
          hex: "#BF3329",
        },
        {
          key: "7",
          hex: "#952820",
        },
      ],
    },
    {
      id: "SequentialColors4",
      colors: [
        {
          key: "1",
          hex: "#6C7ED9",
        },
        {
          key: "2",
          hex: "#89A4F7",
        },
        {
          key: "3",
          hex: "#88B9F6",
        },
        {
          key: "4",
          hex: "#8CCBF1",
        },
        {
          key: "5",
          hex: "#98DAEB",
        },
        {
          key: "6",
          hex: "#A8E7E7",
        },
        {
          key: "7",
          hex: "#CEE78B",
        },
      ],
    },
  ],
  palette2: [
    {
      id: "SequentialColors1",
      colors: [
        {
          key: "1",
          hex: "#EAC1C8",
        },
        {
          key: "2",
          hex: "#E0A3AE",
        },
        {
          key: "3",
          hex: "#D27989",
        },
        {
          key: "4",
          hex: "#C95F72",
        },
        {
          key: "5",
          hex: "#BC374F",
        },
        {
          key: "6",
          hex: "#AB3248",
        },
        {
          key: "7",
          hex: "#852738",
        },
      ],
    },
    {
      id: "SequentialColors2",
      colors: [
        {
          key: "1",
          hex: "#B8EDFA",
        },
        {
          key: "2",
          hex: "#96E4F7",
        },
        {
          key: "3",
          hex: "#66D7F4",
        },
        {
          key: "4",
          hex: "#49D0F1",
        },
        {
          key: "5",
          hex: "#1BC4EE",
        },
        {
          key: "6",
          hex: "#19B2D9",
        },
        {
          key: "7",
          hex: "#138BA9",
        },
      ],
    },
    {
      id: "SequentialColors3",
      colors: [
        {
          key: "1",
          hex: "#CEC6F5",
        },
        {
          key: "2",
          hex: "#B7ABF0",
        },
        {
          key: "3",
          hex: "#9684EA",
        },
        {
          key: "4",
          hex: "#816DE5",
        },
        {
          key: "5",
          hex: "#6248DF",
        },
        {
          key: "6",
          hex: "#5942CB",
        },
        {
          key: "7",
          hex: "#46339E",
        },
      ],
    },
    {
      id: "SequentialColors4",
      colors: [
        {
          key: "1",
          hex: "#2E3DB8",
        },
        {
          key: "2",
          hex: "#2C67AA",
        },
        {
          key: "3",
          hex: "#3B859B",
        },
        {
          key: "4",
          hex: "#5A9C91",
        },
        {
          key: "5",
          hex: "#83AC92",
        },
        {
          key: "6",
          hex: "#A9B89E",
        },
        {
          key: "7",
          hex: "#C6C6BA",
        },
      ],
    },
  ],
  palette3: [
    {
      id: "SequentialColors1",
      colors: [
        {
          key: "1",
          hex: "#D1DBD8",
        },
        {
          key: "2",
          hex: "#BAC9C6",
        },
        {
          key: "3",
          hex: "#9BB1AB",
        },
        {
          key: "4",
          hex: "#88A19B",
        },
        {
          key: "5",
          hex: "#6A8A82",
        },
        {
          key: "6",
          hex: "#607E76",
        },
        {
          key: "7",
          hex: "#4B625C",
        },
      ],
    },
    {
      id: "SequentialColors2",
      colors: [
        {
          key: "1",
          hex: "#DAE9ED",
        },
        {
          key: "2",
          hex: "#C8DEE4",
        },
        {
          key: "3",
          hex: "#AFCFD7",
        },
        {
          key: "4",
          hex: "#9FC6D0",
        },
        {
          key: "5",
          hex: "#87B8C4",
        },
        {
          key: "6",
          hex: "#7BA7B2",
        },
        {
          key: "7",
          hex: "#60838B",
        },
      ],
    },
    {
      id: "SequentialColors3",
      colors: [
        {
          key: "1",
          hex: "#E7DFD2",
        },
        {
          key: "2",
          hex: "#DBD0BD",
        },
        {
          key: "3",
          hex: "#CABB9F",
        },
        {
          key: "4",
          hex: "#C0AD8C",
        },
        {
          key: "5",
          hex: "#B0996F",
        },
        {
          key: "6",
          hex: "#A08B65",
        },
        {
          key: "7",
          hex: "#7D6D4F",
        },
      ],
    },
    {
      id: "SequentialColors4",
      colors: [
        {
          key: "1",
          hex: "#2E3DB8",
        },
        {
          key: "2",
          hex: "#2C67AA",
        },
        {
          key: "3",
          hex: "#3B859B",
        },
        {
          key: "4",
          hex: "#5A9C91",
        },
        {
          key: "5",
          hex: "#83AC92",
        },
        {
          key: "6",
          hex: "#A9B89E",
        },
        {
          key: "7",
          hex: "#C6C6BA",
        },
      ],
    },
  ],
  palette4: [
    {
      id: "SequentialColors1",
      colors: [
        {
          key: "1",
          hex: "#77C59D",
        },
        {
          key: "2",
          hex: "#55B685",
        },
        {
          key: "3",
          hex: "#3D9E6E",
        },
        {
          key: "4",
          hex: "#309263",
        },
        {
          key: "5",
          hex: "#248657",
        },
        {
          key: "6",
          hex: "#187A4C",
        },
        {
          key: "7",
          hex: "#0C6E40",
        },
      ],
    },
    {
      id: "SequentialColors2",
      colors: [
        {
          key: "1",
          hex: "#6FB5E9",
        },
        {
          key: "2",
          hex: "#4BA3E3",
        },
        {
          key: "3",
          hex: "#338BCC",
        },
        {
          key: "4",
          hex: "#267FC1",
        },
        {
          key: "5",
          hex: "#1A73B5",
        },
        {
          key: "6",
          hex: "#0E67AA",
        },
        {
          key: "7",
          hex: "#025B9E",
        },
      ],
    },
    {
      id: "SequentialColors3",
      colors: [
        {
          key: "1",
          hex: "#DB9326",
        },
        {
          key: "2",
          hex: "#D58000",
        },
        {
          key: "3",
          hex: "#BD6800",
        },
        {
          key: "4",
          hex: "#A45000",
        },
        {
          key: "5",
          hex: "#8C3800",
        },
        {
          key: "6",
          hex: "#732000",
        },
        {
          key: "7",
          hex: "#671400",
        },
      ],
    },
    {
      id: "SequentialColors4",
      colors: [
        {
          key: "1",
          hex: "#255A99",
        },
        {
          key: "2",
          hex: "#555BAD",
        },
        {
          key: "3",
          hex: "#7A5CB5",
        },
        {
          key: "4",
          hex: "#9860B2",
        },
        {
          key: "5",
          hex: "#B467A3",
        },
        {
          key: "6",
          hex: "#C9718E",
        },
        {
          key: "7",
          hex: "#D97F78",
        },
      ],
    },
  ],
};
