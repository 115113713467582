import React, { useState, useEffect, useCallback, useId } from "react";
import { useWindowSize } from "usehooks-ts";

import { DropdownContainer, DropdownButton } from "./styles";
import { SectionDropdownMenu } from "./DropdownMenu";
import { ISection } from "../../../../../../../api/sections";
import { SelectorIcon } from "../../../icons/SelectorIcon";

interface Props {
  open: boolean;
  item: ISection;
  setContentItem: (item: ISection) => void;
}

const SectionDropdown: React.FC<Props> = ({
  open,
  item,
  setContentItem,
}) => {
  const id = useId();
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const { width, height } = useWindowSize();

  const calculatePosition = useCallback((rect: DOMRect) => {
    setDropdownPosition({
      top: rect.bottom + window.scrollY + 2,
      left: rect.left + window.scrollX,
    });
  }, []);

  useEffect(() => {
    if (!open && isOpen) {
      setIsOpen(false);
    }
  }, [open, isOpen]);

  useEffect(() => {
    if (isOpen) {
      const buttonRect = document.getElementById(id)!.getBoundingClientRect();
      calculatePosition(buttonRect);
    }
  }, [isOpen, width, height, id, calculatePosition]);

  const toggleDropdown = (event: React.MouseEvent) => {
    const rect = event.currentTarget.getBoundingClientRect();
    calculatePosition(rect);
    setIsOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };
  return (
    <DropdownContainer>
      <DropdownButton id={id} $isOpen={isOpen} onClick={toggleDropdown}>
        Aa
        <SelectorIcon rotate={isOpen} />
      </DropdownButton>
      <SectionDropdownMenu
        item={item}
        setContentItem={setContentItem}
        open={open}
        isOpen={isOpen}
        top={dropdownPosition.top}
        left={dropdownPosition.left}
        closeMenu={closeMenu}
      />
    </DropdownContainer>
  );
};

export default SectionDropdown;
