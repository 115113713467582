import React from "react";
import {
  ProfileSideBarAboutCardDescription,
  ProfileSideBarAboutCardDetailsWrapper,
  ProfileSideBarAboutCardIcon,
  ProfileSideBarAboutCardTitle,
  ProfileSideBarAboutCardWrapper,
  ProfileSideBarHeading,
  ProfileSideBarSectionWrapper,
} from "./styles";
import { Envelope } from "react-bootstrap-icons";

export const ContactsSection = () => {
  return (
    <ProfileSideBarSectionWrapper>
      <ProfileSideBarHeading>Contacts</ProfileSideBarHeading>
      <ProfileSideBarAboutCardWrapper>
        <ProfileSideBarAboutCardIcon>
          <Envelope />
        </ProfileSideBarAboutCardIcon>
        <ProfileSideBarAboutCardDetailsWrapper>
          <ProfileSideBarAboutCardTitle>Email</ProfileSideBarAboutCardTitle>
          <ProfileSideBarAboutCardDescription>
            robert.r@gmail.com
          </ProfileSideBarAboutCardDescription>
        </ProfileSideBarAboutCardDetailsWrapper>
      </ProfileSideBarAboutCardWrapper>
    </ProfileSideBarSectionWrapper>
  );
};
