import React, { useEffect, useMemo, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { ModalOverlay } from "../styles";
import {
  UpdateModalContentWrapper,
  UpdateModalHeaderWrapper,
  UpdateModalWrapper,
  AccountBlock,
  AccountAvatar,
  AccountFullName,
  UpdateTab,
  UpdateTabs,
  UpdateModalFooterWrapper,
} from "./styles";
import { AccountDto } from "../../../models/Authentication";
import { getUser } from "../../../store/selectors/main";
import { Button } from "../../Button";
import { CheckCircle } from "react-bootstrap-icons";
import { Essentials } from "./Essentials";
import { ContactDetails } from "./ContactDetails";
import { Security } from "./Security";
import { Permissions } from "./Permissions";
import { Notifications } from "./Notifications";

const formInitialValues: Record<string, any> = {
  firstName: "",
  lastName: "",
  jobTitle: "",
  organization: "",
  location: "",
};

export const profileValidationSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your First Name")
      .matches(/^[a-zA-Z]+$/, "First Name should only contain letters"),

    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your Last Name")
      .matches(/^[a-zA-Z]+$/, "Last Name should only contain letters"),

    jobTitle: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your Job Title")
      .matches(/^[a-zA-Z]+$/, "Job Title should only contain letters"),

    organization: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your Organization"),

    location: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your Location"),
  });
};

export const UpdateProfileModal = ({ onClose }: { onClose: () => void }) => {
  const user = useSelector(getUser);
  const [account, setAccount] = useState<AccountDto | undefined>();
  const [selectedTab, setTab] = useState<string | undefined>("essentials");

  useEffect(() => {
    if (user?.accessToken) {
      const account: AccountDto = jwtDecode(user?.accessToken);
      setAccount(account);
    }
  }, [user]);

  const fullName = useMemo(() => {
    const name = [account?.firstName, account?.lastName]?.join(" ")?.trim();
    if (name?.length) {
      return name;
    }
    return "Robert Robertson";
  }, [account?.firstName, account?.lastName]);

  const initials = useMemo(() => {
    let initials = account?.email?.charAt(0);
    if (account?.firstName?.length || account?.lastName?.length) {
      initials =
        (account?.firstName?.charAt(0) || "") +
        (account?.lastName?.charAt(0) || "");
    }
    return initials?.toUpperCase();
  }, [account?.email, account?.firstName, account?.lastName]);

  return (
    <ModalOverlay onClick={onClose}>
      <UpdateModalWrapper onClick={(e) => e.stopPropagation()}>
        <UpdateModalHeaderWrapper>
          <AccountBlock>
            <AccountAvatar>{initials}</AccountAvatar>
            <AccountFullName>{fullName}</AccountFullName>
          </AccountBlock>
          <UpdateTabs>
            <UpdateTab
              $selected={selectedTab === "essentials"}
              onClick={() => setTab("essentials")}
            >
              Essentials
            </UpdateTab>
            <UpdateTab
              $selected={selectedTab === "contact_details"}
              onClick={() => setTab("contact_details")}
            >
              Contact details
            </UpdateTab>
            <UpdateTab
              $selected={selectedTab === "security"}
              onClick={() => setTab("security")}
            >
              Security
            </UpdateTab>
            <UpdateTab
              $selected={selectedTab === "permissions"}
              onClick={() => setTab("permissions")}
            >
              Permissions
            </UpdateTab>
            <UpdateTab
              $selected={selectedTab === "notifications"}
              onClick={() => setTab("notifications")}
            >
              Notifications
            </UpdateTab>
          </UpdateTabs>
        </UpdateModalHeaderWrapper>
        <UpdateModalContentWrapper>
          <Formik
            initialValues={formInitialValues}
            validationSchema={profileValidationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values: any) => {}}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  {selectedTab === "essentials" && (
                    <Essentials
                      values={values}
                      touched={touched}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      initials={initials}
                      avatar={account?.avatar}
                    />
                  )}
                  {selectedTab === "contact_details" && (
                    <ContactDetails
                      values={values}
                      touched={touched}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  )}
                  {selectedTab === "security" && (
                    <Security
                      values={values}
                      touched={touched}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  )}
                  {selectedTab === "permissions" && (
                    <Permissions
                      values={values}
                      touched={touched}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  )}
                  {selectedTab === "notifications" && (
                    <Notifications
                      values={values}
                      touched={touched}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  )}
                </Form>
              );
            }}
          </Formik>
        </UpdateModalContentWrapper>
        <UpdateModalFooterWrapper>
          <Button
            name="Cancel"
            onClick={onClose}
            variant="neutral"
            size="medium"
          />
          <Button
            name="Update"
            onClick={() => {}}
            variant={"secondary"}
            size="medium"
            icon={<CheckCircle />}
          />
        </UpdateModalFooterWrapper>
      </UpdateModalWrapper>
    </ModalOverlay>
  );
};
