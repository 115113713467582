import styled from "styled-components";

import { ReactComponent as UserImg } from "../../assets/userIcon.svg";
import { animateMenuOpen } from "../../styles";
import { ChevronCompactDown } from "react-bootstrap-icons";

export const UserMenuWrapper = styled.div`
  display: flex;
  align-self: center;
  position: relative;
  height: 36px;
  gap: 8px;
`;

export const UserIcon = styled(UserImg)``;

export const UserDetailsWrapper = styled.div``;

export const UserName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  overflow: hidden;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
`;

export const UserRole = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralWeak};
`;

export const DropDownIcon = styled(ChevronCompactDown)<{ $open: boolean }>`
transform: ${({ $open }) => ($open ? 'rotateX(180deg)' : 'rotateX(0deg)')};
transition: 0.3s;
`;

export const UserMenu = styled.div<{ $open: boolean }>`
  position: absolute;
  top: 60px;
  right: -5px;
  text-align: center;
  background: ${({ theme }) => theme.neutralBackgroundBase};
  padding: 0 12px;
  opacity: ${({ $open }) => ($open ? 1 : 0)};
  width: 240px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 10px;
  z-index: 111;
  animation: 0.3s ${({ $open }) => $open && animateMenuOpen} ease forwards;
  box-shadow: rgba(99, 99, 99, 0.2) 0 0 5px 0;

  li {
    list-style: none;
    cursor: pointer;
    padding-top: 13px;
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.neutralText};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: 0.1s ease-in-out;
    gap: 8px;

    &:hover {
      color: ${({ theme }) => theme.neutralText};

      svg {
        filter: brightness(0) saturate(100%) invert(5%) sepia(16%)
          saturate(5773%) hue-rotate(203deg) brightness(92%) contrast(89%);
      }
    }

    svg {
      height: 16px;
      width: 16px;
    }

    &:first-child {
      border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
      padding-bottom: 13px;
    }

    &:nth-child(2) {
      border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
      padding-bottom: 10px;
    }

    &:last-child {
      border-top: 1px solid ${({ theme }) => theme.neutralBorder};
      padding-bottom: 13px;
    }

    &:nth-last-child(2) {
      padding-bottom: 13px;
    }
  }
`;

export const AccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  color: ${({ theme }) => theme.neutralWeak};
`;

export const Account = styled.p`
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.8px;
  color: ${({ theme }) => theme.neutralWeak};
`

export const AccountBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 18px;
`;

export const AccountDetailsBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-left: 8px;
`;

export const AccountAvatar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.neutralBackground};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: ${({ theme }) => theme.neutralText};
`;

export const AccountFullName = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.neutralText};
`;

export const AccountEmail = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  margin-top: 2px;
  color: ${({ theme }) => theme.neutralWeak};
`;

export const Theme = styled.div`
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.neutralWeak};
`;

export const ThemeBlock = styled.div`
  border-radius: 16px;
  padding: 2px;
  height: 32px;
  display: flex;
  background-color: ${({ theme }) => theme.neutralBackground};
  margin-top: 8px;
  gap: 2px;
`;

export const ThemeLabel = styled.div<{ $selected: boolean }>`
  font-size: 14px;
  font-style: normal;
  font-weight: ${({ $selected }) => ($selected ? "500" : "400")};
  line-height: 16px;
  color: ${({ theme, $selected }) =>
    $selected ? theme.neutralText : theme.neutralWeak};
  display: flex;
  padding: 4px 6px;
  border-radius: 16px;
  background-color: ${({ theme, $selected }) =>
    $selected ? theme.neutralBackgroundBase : "transparent"};
  justify-content: center;
  align-items: center;
  width: 68px;
  box-shadow: ${({ $selected }) =>
    $selected
      ? `0px 2px 2px 0px rgba(20, 28, 44, 0.04),
  0px 2px 4px 1px rgba(20, 28, 44, 0.04)`
      : "none"};
  transition: right 500ms ease, background-color 500ms ease,
    box-shadow 500ms ease, color 500ms ease;
`;
