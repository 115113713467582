export const darkTheme = {
  //Background Colors
  primaryBackground: "#5448FF0F",
  primaryBackgroundPublish: "rgba(84, 72, 255, 0.06)",
  primaryBackgroundMedium: "#5448FF1F",
  primaryBackgroundActive: "#5448FF29",
  primaryBackgroundHover: "rgba(84, 72, 255, 0.16)",
  primaryBackgroundStrong: "#5448FF",
  neutralBackgroundActive: "#5F68771F",
  neutralBackgroundMedium: "rgb(190,190,190)",
  neutralBackgroundBase: "rgba(255, 255, 255, 1)",
  neutralBackgroundMediumActive: "#F1F4F7",
  neutralBackground: "rgba(95, 104, 119, 0.08)",
  successBackground: "rgba(43, 158, 119, 0.12)",
  neutralBackgroundHover: "#5F68771A",
  activeBackground: "#5448FF0F",
  secondaryBackground: "rgba(0, 20, 63, 1)",
  stratosBackground: "rgba(0, 6, 49, 1)",

  neutralBorder: "rgba(211, 219, 227, 1)",
  neutralBorderMedium: "#939BA7",
  primaryBorder: "rgba(84, 72, 255, 1)",
  mediumBorder: "rgba(147, 155, 167, 1)",
  successBorder: "rgba(43, 158, 119, 0.2)",
  colorGray500: "rgb(223, 225, 228)",

  //Button Background Colors
  primaryButton: "rgba(84, 72, 255, 0.08)",
  secondaryButton: "#00143F",
  secondaryButtonHover: "#000631",
  secondaryButtonActive: "#000631",
  neutralButton: "rgba(95, 104, 119, 0.08)",
  neutralButtonColored: "#DCDFE5",
  neutralButtonColoredHover: "rgba(226, 230, 234)",
  alertBackground: "rgba(197, 54, 96, 0.12)",
  alertBackgroundMedium: "rgba(197, 54, 96, 0.2)",

  //Font Colors
  primaryText: "#493DDD",
  primaryTextStrongActive: "#6562FF",
  secondaryText: "rgba(255, 255, 255, 1)",
  neutralText: "rgba(95, 104, 119, 1)",
  neutralTextWeak: "rgba(92, 99, 110, 1)",
  neutralTextDisabled: "#939BA7",
  disabledText: "rgba(147, 155, 167, 1)",
  neutralWeak: "rgba(95, 104, 119, 1)",
  successText: "rgba(33, 124, 93, 1)",
  alertText: "rgba(153, 42, 75, 1)",
  primaryTextHover: "#372EA5",
  primaryTextStrong: "#FFF",
  primaryTextHoverStrong: "rgba(55, 46, 165, 1)",
  primaryTextStrongHover: "rgb(131,145,255)",

  alertBackgroundStrong: "rgba(197, 54, 96, 1)",
  alertBorder: "rgba(244, 245, 246, 1)",

  boxShadowColor: "rgba(99, 99, 99, 0.2) 0 0 3px 0",

  primaryBorderColor: "rgba(211, 219, 227, 1)",
};
