import { generateQueryString } from "../helpers/queryGenerator";
import axios from "./axios";
import { AxiosError, AxiosResponse } from "axios";

export const getPublicProject = async (id: string) =>
  axios
    .get(`/public/projects/${id}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const getPublicProjectPages = async (id: string) =>
  axios
    .get(`/public/projects/${id}/pages`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const getPublicProjectPage = async (query: {
  id: string;
  pageId: string;
}) =>
  axios
    .get(`/public/projects/${query.id}/pages/${query.pageId}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const getPublicPageWidgets = async (query: {
  id: string;
  pageId: string;
  query?: string;
}) => {
  const queryParams = generateQueryString<any>({
    query: query.query || ''
  });
  return axios
    .get(`/public/projects/${query.id}/pages/${query.pageId}/widgets${queryParams}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
}