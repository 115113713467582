import React, { useState } from "react";

import {
  CheckBoxWrapper,
  DatasetsTable,
  Details,
  Dimensions,
  HeadingWrapper,
  Name,
  UploadedOn,
  UploadedOnSort,
} from "./styles";
import { DatasetRow } from "../DatasetRow";
import { DatasetsResponseListDto, NewDatasetDTO } from "../../models/Datasets";
import { useSortByCreatedDate } from "../../hooks/useSortByDate";
import { CheckBox } from "../Inputs/CustomCheckbox/CheckBox";

export const DatasetsList = ({ items }: Partial<DatasetsResponseListDto>) => {
  const { handleSortByCreatedDate, sortItemsByCreatedDate } =
    useSortByCreatedDate("desc");
  const sortedItems = sortItemsByCreatedDate(items ? items : []);
  const [checkAll, setCheckAll] = useState<boolean>(false)
  return (
    <DatasetsTable>
      <HeadingWrapper>
        <CheckBoxWrapper>
          <CheckBox 
            checked={checkAll}
            onChange={(e) => setCheckAll(e.target.checked)}
          />
        </CheckBoxWrapper>
        <Name>Name</Name>
        <Dimensions>Dimensions</Dimensions>
        <UploadedOn>
          Uploaded on <UploadedOnSort onClick={handleSortByCreatedDate} />
        </UploadedOn>
        <Details></Details>
      </HeadingWrapper>
      {sortedItems?.map((dataset: NewDatasetDTO) => (
        <DatasetRow
          id={dataset.id}
          name={dataset.name}
          createdAt={dataset.createdAt}
          fields={dataset.fields}
          filePath={dataset.filePath}
          updatedAt={dataset.updatedAt}
          selectAll={checkAll}
          key={dataset.id}
        />
      ))}
    </DatasetsTable>
  );
};
