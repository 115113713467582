import styled from "styled-components";

export const Container = styled.div<{
  $isDragActive: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 1px;
  border-radius: 8px;
  height: 186px;
  border-color: ${({ theme }) => theme.primaryBorder};
  border-style: ${({ $isDragActive }) => ($isDragActive ? "solid" : "dashed")};
  background-color: ${({ theme, $isDragActive }) =>
    $isDragActive ? theme.primaryBackgroundHover : theme.primaryBackground};
  margin: 20px 20px 16px 20px;
  justify-content: center;
  transition: 0.1s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.primaryBackgroundHover};
    border-style: solid;
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  border: 1px solid ${({ theme }) => theme.primaryBackgroundActive};
  border-radius: 50%;
  margin-bottom: 16px;

  svg {
    fill: ${({ theme }) => theme.primaryText};
  }
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  align-items: center;
`;

export const UploadText = styled.span`
  font-size: 16px;
  flex: 1;
`;

export const UploadDescription = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.neutralWeak};
`;
