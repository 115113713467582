import React from "react";
import { ProjectMenu } from "../../components/ProjectMenu";
import { Loader } from "../../components/Loader";
import { useSelector } from "react-redux";
import { getAllPages } from "../../store/selectors/projects";

interface LayoutProps {
  children: React.ReactNode;
}

const ProjectPageLayout = ({ children }: LayoutProps) => {
  const pages = useSelector(getAllPages);

  return (
    <>
      <ProjectMenu />
      {children}
      {pages.isLoading && <Loader />}
    </>
  );
};

export default ProjectPageLayout;
