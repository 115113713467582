import usCounties from "../Config/GeoJson/us-counties.json";

interface Props {
  GEOID: string,
  FIPS_CODE: string,
  COUNTY_STATE_CODE: string
  COUNTY_STATE_NAME: string
}

export const getCountyDataFromId = (id: string): Props => {
  const county = (usCounties as any).features.find((el: { properties: { GEOID: string; }; }) => el.properties.GEOID === id)?.properties

  return county;
}