import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../Button";
import { setActiveModal } from "../../store/slices/modals";
import { AvailableWidgets } from "../AvailableWidgets";

import {
  DatasetsWrapper,
  DatasetsHeading,
  DatasetsHeadingWrapper,
  DatasetsSection,
} from "./styles";
import { DatasetsItem } from "../DatasetsItem";
import { requestGetAllDatasets } from "../../store/slices/datasets";
import { PlusCircle, LayersHalf } from "react-bootstrap-icons";
import {
  getCurrentProjectData,
  getCurrentWidget,
} from "../../store/selectors/projects";

export const DatavizRecommendedWidgets = ({
  setSelectedChart,
}: {
  setSelectedChart: (chart?: string) => void;
}) => {
  const dispatch = useDispatch();
  const { datasets } = useSelector(getCurrentProjectData);
  const currentWidget = useSelector(getCurrentWidget);

  useEffect(() => {
    dispatch(requestGetAllDatasets({}));
  }, [dispatch]);

  const currentKeys = useMemo(() => {
    if (!currentWidget) {
      return [];
    }
    let keys = [...(currentWidget.xAxe || []), ...(currentWidget.yAxe || [])];
    if (currentWidget?.groupBy && currentWidget?.groupBy?.length) {
      keys = [...keys, ...currentWidget?.groupBy];
    }
    return keys;
  }, [currentWidget]);

  return (
    <>
      <DatasetsSection>
        <DatasetsHeadingWrapper>
          <DatasetsHeading>Datasets</DatasetsHeading>
          <Button
            name={
              currentWidget && currentWidget.id ? "Switch dataset" : "Add more"
            }
            onClick={() =>
              dispatch(
                setActiveModal({ id: "datasetLibraryModal", back: false })
              )
            }
            variant="primary"
            icon={
              currentWidget && currentWidget.id ? (
                <LayersHalf />
              ) : (
                <PlusCircle />
              )
            }
            size="small"
          />
        </DatasetsHeadingWrapper>
        <DatasetsWrapper>
          {datasets.map((dataset) => {
            return (
              <DatasetsItem
                selectedDimensions={currentKeys}
                dataset={dataset}
                key={dataset.filePath}
              />
            );
          })}
        </DatasetsWrapper>
      </DatasetsSection>
      <AvailableWidgets selectChart={setSelectedChart} />
    </>
  );
};
