import styled from "styled-components";

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SwitchLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme.neutralText};
`;

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 56px;
  height: 36px;
`;

export const Slider = styled.span<{
  $icon?: string;
}>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.neutralBackgroundActive};
  transition: 0.4s;
  border-radius: 25px;
  
  &:before {
    position: absolute;
    content: '';
    height: calc(100% - 4px);
    aspect-ratio: 1/1;
    left: 2px;
    bottom: 2px;
    background-color: ${({ theme }) => theme.neutralBackgroundBase};
    transition: 0.4s;
    border-radius: 50%;
  
  }
  
  &:after{
    position: absolute;
    content: '';
    transition: 0.4s;
    aspect-ratio: 1/1;
    ${({ $icon }) => $icon ? `background-image: url(${$icon})` : ''};
    height: calc(100% - 20px);
    background-size: contain;
    left: 10px;
    bottom: 10px;
  }

  input:checked + &:after {
    transform: translateX(calc(100% + 4px));
  }

  input:checked + & {
    background-color: ${({ theme }) => theme.neutralBackgroundBase};
  }
/* 
  input:focus + & {
    box-shadow: 0 0 1px #2196F3;
  } */

  input:checked + &:before {
    background-color: #00143F;
    transform: translateX(calc(100% - 13px));
  }
`;

export const SwitchInput = styled.input<{
  $error?: boolean;
}>`
  ${({ $error }) => $error ?
    `
      ${Slider} {
        box-shadow: 0 0 1px #C536601F;
        border: none;
      }
    `
    :
    ''
  } // TO DO: error for switch
  opacity: 0;
  width: 0;
  height: 0;
`;
