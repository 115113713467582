import styled from "styled-components";
import { SquareFill, ChevronExpand } from "react-bootstrap-icons";

export const DatasetsTable = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 8px;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralWeak};
  height: 36px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  background-color: ${({ theme }) => theme.neutralBackgroundMedium};
  border-radius: 7px 7px 0 0;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  margin: 0 11px 0 16px;
`;

export const Name = styled.div`
  min-width: 220px;
  padding-left: 8px;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 1px;
    background: ${({ theme }) => theme.neutralBorder};
    height: 100%;
    top: 0;
    right: 5px;
  }
`;

export const SelectAll = styled(SquareFill)`
  width: 100%;
  max-width: 16px;
  margin: 0 16px;
  cursor: pointer;
`;

export const Dimensions = styled.div`
  min-width: 250px;
  width: 100%;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 1px;
    background: ${({ theme }) => theme.neutralBorder};
    height: 100%;
    top: 0;
    right: 5px;
  }
`;

export const UploadedOn = styled.div`
  width: 100%;
  min-width: 230px;
  text-align: right;
  padding-right: 12px;
  flex: 1;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 1px;
    background: ${({ theme }) => theme.neutralBorder};
    height: 100%;
    top: 0;
    right: 5px;
  }
`;

export const Details = styled.div`
  width: 30px;
`;

export const UploadedOnSort = styled(ChevronExpand)`
  margin-left: 4px;
  cursor: pointer;
`;
