import styled from "styled-components";

export const DropdownContainer = styled.div`
  position: relative;
  z-index: 9999;
  min-width: 200px;
  height: 100%;
  width: auto;
`;

export const DropdownButton = styled.button<{ $isOpen: boolean }>`
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 4px;
  color: ${({ theme, $isOpen }) =>
    $isOpen ? theme.neutralText : theme.neutralTextWeak};
  background: ${({ theme, $isOpen }) =>
    $isOpen ? theme.neutralBackgroundMediumActive : theme.neutralBackgroundBase};
  display: flex;
  padding: 2px 3px;
  gap: 2px;
  align-items: center;
  transition: 0.3s;
  font-size: 13.5px;
  cursor: pointer;

  svg path {
    fill: ${({ theme }) => theme.neutralTextWeak};
  }

  &:hover {
    ${({ $isOpen, theme }) =>
      !$isOpen &&
      `
        border: 1px solid ${theme.neutralBorderMedium};
        color: ${theme.neutralText};
        
        svg path {
          fill: ${theme.neutralText};
        }
    `}
  }
`;

export const DropdownMenuWrapper = styled.div<{
  $isOpen: boolean;
  $top: number;
  $left: number;
}>`
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 16px 4px;
  position: absolute;
  height: max-content;
  top: ${(props) => props.$top}px;
  left: ${(props) => props.$left}px;
  width: 227px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  visibility: ${(props) => (props.$isOpen ? "visible" : "hidden")};
  transform: ${(props) => (props.$isOpen ? "scale(1)" : "scale(0)")};
  transform-origin: left top;
  transition: all 0.3s;
  z-index: 99999;
`;

export const DropdownTitle = styled.div`
  font-size: 11px;
  line-height: 12px;
  margin-bottom: 4px;
  padding-left: 4px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const CheckBoxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  padding-bottom: 6px;
  margin-bottom: 9px;
`;

export const CheckboxLabel = styled.label<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  padding: 6px 8px;
  margin: 0 4px;
  padding-left: 5px;
  gap: 8px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s;
  background: ${({ theme, $active }) =>
    !!$active ? theme.neutralBackground : "transparent"};
  &:hover {
    background: ${({ theme }) => theme.neutralBackgroundHover};
  }
  &:focus {
    background: ${({ theme }) => theme.neutralBackground};
    border: 2px solid ${({ theme }) => theme.primaryBackgroundActive};
  }
  &:active {
    background: ${({ theme }) => theme.neutralBackgroundActive};
  }
`;

export const CheckboxText = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 2px;

  span:nth-child(1) {
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.neutralText};
    font-weight: 500;
  }
  span:nth-child(2) {
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.neutralTextWeak};
  }
`;
