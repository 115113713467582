import React, { useState } from "react";
import { Button } from "../../../../components/Button";
import { ArrowLeft } from "react-bootstrap-icons";
import {
  ActionWrapper,
  ConfirmationsDescription,
  ConfirmationHeading,
  ConfirmationWrapper,
  AuthPageWrapper,
  AuthWrapper,
  LogoWrapper,
  Background,
  Container,
  SendIcon,
  Logo,
  ResetPassInputs,
  ResetPassConfirm,
} from "../../styles";
import { useNavigate } from "react-router-dom";
import { FormGroup } from "../../FormGroup";
import { Formik, Form } from "formik";
import { ResetPassValidationSchema } from "../../../../constants";

type ResetPassForm = {
  newPassword: string;
  confirmNewPassword: string;
};

export const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const [isSuccesChanged, setIsSuccesChanged] = useState<boolean>(false);

  const formInitialValues: ResetPassForm = {
    newPassword: "",
    confirmNewPassword: "",
  };

  const handleSubmit = (values: ResetPassForm) => {
    setIsSuccesChanged(true);
  };

  return (
    <Container>
      <Background />
      <AuthPageWrapper>
        <LogoWrapper>
          <Logo onClick={() => navigate("/")} />
        </LogoWrapper>
        <AuthWrapper>
          {isSuccesChanged ? (
            <ConfirmationWrapper>
              <ResetPassConfirm />
              <ConfirmationHeading>Password Changed</ConfirmationHeading>
              <ConfirmationsDescription>
                Your password has been successfully changed.
              </ConfirmationsDescription>
              <ActionWrapper>
                <Button
                  onClick={() => navigate("/")}
                  variant={"secondary"}
                  size="medium"
                  name="Go to Sign In"
                  icon={<SendIcon />}
                />
              </ActionWrapper>
            </ConfirmationWrapper>
          ) : (
            <ConfirmationWrapper>
              <ConfirmationHeading>Reset Your Password</ConfirmationHeading>
              <ConfirmationsDescription>
                Time for a fresh start! Go ahead and set a new password.
              </ConfirmationsDescription>
              <Formik
                validateOnBlur={false}
                validateOnChange={true}
                onSubmit={handleSubmit}
                initialValues={formInitialValues}
                validationSchema={ResetPassValidationSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setValues,
                }) => (
                  <Form
                    onSubmit={handleSubmit}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      width: "100%",
                    }}
                  >
                    <ResetPassInputs>
                      <FormGroup
                        id="newPassword"
                        name="newPassword"
                        type="password"
                        placeholder="New Password"
                        errors={errors.newPassword}
                        values={values.newPassword}
                        handleChange={handleChange}
                        touched={touched.newPassword}
                        handleBlur={handleBlur}
                        setValues={() =>
                          setValues({ ...values, newPassword: "" })
                        }
                        width="100%"
                      />
                      <FormGroup
                        id="confirmNewPassword"
                        name="confirmNewPassword"
                        type="password"
                        placeholder="Confirm New Password"
                        touched={touched.confirmNewPassword}
                        errors={errors.confirmNewPassword}
                        values={values.confirmNewPassword}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setValues={() =>
                          setValues({ ...values, confirmNewPassword: "" })
                        }
                        width="100%"
                      />
                    </ResetPassInputs>
                    <ActionWrapper>
                      <Button
                        onClick={() => {
                          navigate("/sign-in");
                        }}
                        variant="neutral"
                        size="medium"
                        name="Back to Sign In"
                        iconLeft={<ArrowLeft />}
                      />
                      <Button
                        onClick={() => handleSubmit()}
                        variant={
                          Object.keys(errors).length ? "disabled" : "secondary"
                        }
                        disabled={Boolean(Object.keys(errors).length)}
                        size="medium"
                        name="Reset Password"
                        icon={<SendIcon />}
                      />
                    </ActionWrapper>
                  </Form>
                )}
              </Formik>
            </ConfirmationWrapper>
          )}
        </AuthWrapper>
      </AuthPageWrapper>
    </Container>
  );
};
