import React, { useRef, useState } from "react";
import {
  ContentWrapper,
  OptionsMenuItem,
  OptionsMenuItemsWrapper,
  OptionsMenuWrapper,
  OptionsWrapper,
} from "./styles";
import { ImagePicker } from "../../../../../../ImagePicker";
import { useOutsideClick } from "../../../../../../../hooks/useOutsideClick";
import { SectionMenuArrowButton, SectionMenuClose } from "../styles";

interface SectionImageDropdownInterface {
  fileUrl?: string;
  setFile: any;
}
export const SectionImageDropdown = ({
  fileUrl,
  setFile,
}: SectionImageDropdownInterface) => {
  const [activeTab, setActiveTab] = useState<"image">("image");
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
  const ref = useRef(null);
  useOutsideClick(ref, () => {
    setOptionsMenuOpen(false);
  });

  return (
    <>
      <div ref={ref}>
        <SectionMenuArrowButton
          $active={optionsMenuOpen}
          onClick={() => {
            setOptionsMenuOpen(!optionsMenuOpen);
          }}
        />
        {optionsMenuOpen && (
          <SectionMenuClose
            onClick={() => {
              setOptionsMenuOpen(false);
            }}
          />
        )}
        {optionsMenuOpen && (
          <OptionsWrapper>
            <OptionsMenuWrapper>
              <OptionsMenuItemsWrapper>
                <OptionsMenuItem
                  onClick={() => setActiveTab("image")}
                  $activeItem={activeTab === "image"}
                  $width={45}
                >
                  Image
                </OptionsMenuItem>
              </OptionsMenuItemsWrapper>
            </OptionsMenuWrapper>
            <ContentWrapper>
              <ImagePicker
                isSection={true}
                image={fileUrl!}
                callback={(newImage) => {
                  setFile(newImage);
                }}
              />
            </ContentWrapper>
          </OptionsWrapper>
        )}
      </div>
    </>
  );
};
