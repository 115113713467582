import styled from "styled-components";
import { PlusLg, GearWideConnected } from "react-bootstrap-icons";

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 68px;
  justify-content: space-between;
`;

export const PageItemsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const PagesSection = styled.div`
  display: flex;
  align-items: center;
`;

export const FeaturesSection = styled.div`
  display: flex;
  gap: 8px;
`;

export const AddNewPageWrapper = styled.div`
  display: flex;
  margin-left: 8px;
  width: 36px;
  height: 36px;
  justify-content: center;
  place-items: center;
  align-self: center;
  cursor: pointer;

  &:hover {
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
    justify-content: center;
    place-items: center;
    align-self: center;
  }
`;

export const AddNewPage = styled(PlusLg)``;

export const SettingsBlock = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: rgba(95, 104, 119, 0.08);
`;
export const Settings = styled(GearWideConnected)<{
  $disabled?: boolean;
}>`
  cursor: pointer;
  transition: 0.3s;
  width: 20px;
  height: 20px;
  .bg {
    fill: #5f6877;
    opacity: 0.08;
  }
  .icon {
    fill: #5f6877;
  }
  ${({ $disabled }) =>
    $disabled
      ? `
      fill: #939BA7 !important;
    `
      : ``}
  ${({ $disabled }) =>
    !$disabled
      ? `
      &:hover {
        .bg {
          fill: #5F6877;
          opacity: 0.10;
        }
        .icon {
          fill: #0B101A;
        }
      }
    `
      : `
      
    `}
`;

export const RecommendedWidgetsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 24px;
  padding: 6px;
  cursor: pointer;
`;

export const WidgetsCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: black;
  color: white;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;

export const WidgetsTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
`;
