import styled from "styled-components";

export const DatasetsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
`;

export const DatasetsHeadingWrapper = styled.div`
  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: space-between;
`;

export const DatasetsHeading = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
`;

export const DatasetsWrapper = styled.span`
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
