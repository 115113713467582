import { createPortal } from "react-dom";
import { useRef } from "react";

import { Button } from "../../../../../Button";
import { CheckBox } from "../../../../../Inputs/CustomCheckbox/CheckBox";
import { CheckBoxesWrapper, CheckboxLabel, CheckboxText, DropdownMenuWrapper, DropdownTitle } from "./styles";
import { ApparitionConfig } from "../../../../../../models/Widgets";
import { PageContentGeneratorItemType, WidgetContentItem } from "../../../../../../store/slices/pageContentGenerator";
import { useOnClickOutside } from "usehooks-ts";

interface DropdownMenuProps {
  item: WidgetContentItem;
  open: boolean;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  top: number;
  left: number;
  apparitionConfig: ApparitionConfig;
  setContentItem: React.Dispatch<React.SetStateAction<PageContentGeneratorItemType | undefined>>
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({ item, isOpen, open, setIsOpen, top, left, apparitionConfig, setContentItem }) => {
  const ref = useRef(null)

  const handleClickOutside = () => {
    // setIsOpen(!isOpen)
  }

  useOnClickOutside(ref, handleClickOutside)

  return (open && isOpen) ? createPortal(
    <DropdownMenuWrapper ref={ref} $isOpen={!!(!!open && !!isOpen)} $top={top} $left={left}>
      <DropdownTitle>Show</DropdownTitle>
      <CheckBoxesWrapper>
        <CheckboxLabel $active={!!item?.apparitionConfig?.descriptionDisabled}>
          <CheckBox
            checked={!!item?.apparitionConfig?.descriptionDisabled}
            status={!!item?.apparitionConfig?.descriptionDisabled ? 'checked' : 'none'}
            onChange={() => setContentItem({
              ...item,
              apparitionConfig: {
                ...apparitionConfig,
                descriptionDisabled: !item?.apparitionConfig?.descriptionDisabled,
              }
            })}
          />
          <CheckboxText>
            <span>Alternative text</span>
            <span>Data vizualization description</span>
          </CheckboxText>
        </CheckboxLabel>
        <CheckboxLabel $active={!!item?.apparitionConfig?.insightsDisabled}>
          <CheckBox
            checked={!!item?.apparitionConfig?.insightsDisabled}
            status={!!item?.apparitionConfig?.insightsDisabled ? 'checked' : 'none'}
            onChange={(e) => setContentItem({
              ...item,
              apparitionConfig: {
                ...apparitionConfig,
                insightsDisabled: !item?.apparitionConfig?.insightsDisabled,
              }
            })}
          />
          <CheckboxText>
            <span>Insight information</span>
            <span>Main highlights</span>
          </CheckboxText>
        </CheckboxLabel>
      </CheckBoxesWrapper>
      <Button
        onClick={() => {
          setContentItem({
            ...item,
            apparitionConfig: {
              ...apparitionConfig,
              descriptionDisabled: true,
              insightsDisabled: true,
            }
          })
        }}
        fullWidth
        size="small"
        variant="neutral"
        name="Hide all"
      />
    </DropdownMenuWrapper>,
    document.body
  ) : <></>;
};