import { MarkersVisualisationDataDto } from "../../../../models/Widgets";

export function calculateValuesAndSum(layerData: MarkersVisualisationDataDto[] | undefined) {
  const values: number[] = [];
  const sum = layerData?.reduce((acc, curr) => {
    const value = parseFloat(curr.value);
    values.push(value);
    return acc + value;
  }, 0) || 0;
  return { values, sum };
}