import React, { useEffect, useState, useCallback, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { useSelector } from "react-redux";
import {
  ColDef,
  ColumnState,
  GridReadyEvent,
  SortChangedEvent,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ChevronLeft,
  ChevronRight,
} from "react-bootstrap-icons";

import { getCurrentProjectData } from "../../../store/selectors/projects";
import { getDatasetContents } from "../../../api/datasets";
import { ServerResponse } from "../../../models";
import {
  GridWrapper,
  PaginationButton,
  PaginationCount,
  PaginationWrapper,
  TablePageWrapper,
} from "./styles";
import { Select } from "../../Inputs/CustomSelect/Select";

interface RowData {
  [key: string]: any;
}

const TablePage: React.FC = () => {
  const [rowData, setRowData] = useState<RowData[]>([]);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState<ColumnState | undefined>(undefined);
  const [totalRows, setTotalRows] = useState(0);
  const totalPages = Math.ceil(totalRows / pageSize);

  const { datasets } = useSelector(getCurrentProjectData);

  const memoizedColumnDefs = useMemo(() => columnDefs, [columnDefs]);

  const fetchDataset = async (pageNumber: number, pageSize: number) => {
    try {
      const skip = (pageNumber - 1) * pageSize;
      const { response }: ServerResponse = await getDatasetContents(
        datasets?.[0]?.id!,
        {
          limit: pageSize,
          skip,
          filter: JSON.stringify(filter),
          sortBy: sort?.colId,
          sortDirection: sort?.sort,
        }
      );

      if (response?.data) {
        setRowData(response?.data?.items || []);
        setTotalRows(response?.data?.count || 0);
        getColumnData(response?.data?.items || []);
      }
    } catch (error) {
      console.error("Error fetching dataset:", error);
    }
  };

  useEffect(() => {
    if (datasets && datasets[0]?.id) {
      fetchDataset(page, pageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasets, page, pageSize, filter, sort]);

  const getColumnData = useCallback((data: RowData[]) => {
    if (data.length > 0) {
      const keys = Object.keys(data[0]);
      const dynamicColumnDefs: ColDef[] = keys.map((key) => ({
        field: key,
        flex: 1,
        headerName: key.charAt(0).toUpperCase() + key.slice(1),
        sortable: true,
        filter: true,
      }));
      setColumnDefs(dynamicColumnDefs);
    }
  }, []);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handlePageSizeChange = (size: any) => {
    setPageSize(Number(size));
  };

  const getSortedItem = (el: ColumnState) => {
    if (el.sort) {
      return el;
    }
    return;
  };

  return (
    <TablePageWrapper>
      <GridWrapper className="ag-theme-alpine">
        <AgGridReact
          rowData={rowData}
          columnDefs={memoizedColumnDefs}
          onSortChanged={(e: SortChangedEvent) => {
            setSort(e.api.getColumnState().find(getSortedItem));
          }}
          domLayout="autoHeight"
          onFilterChanged={(e) => {
            setFilter(e.api.getFilterModel());
          }}
          onGridReady={(event: GridReadyEvent) => {
            event.api.sizeColumnsToFit();
          }}
        />
      </GridWrapper>
      <PaginationWrapper>
        <PaginationCount>Page size:</PaginationCount>
        <Select
          width={"80px"}
          openTop
          value={String(pageSize)}
          options={[
            { option: "10", value: "10" },
            { option: "25", value: "25" },
            { option: "50", value: "50" },
          ]}
          handleChange={handlePageSizeChange}
        />
        <PaginationButton
          onClick={() => handlePageChange(1)}
          disabled={page === 1}
        >
          <ChevronDoubleLeft />
        </PaginationButton>
        <PaginationButton
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          <ChevronLeft />
        </PaginationButton>
        <PaginationCount>
          Page {page} of {totalPages}
        </PaginationCount>
        <PaginationButton
          onClick={() => handlePageChange(page + 1)}
          disabled={page === totalPages}
        >
          <ChevronRight />
        </PaginationButton>
        <PaginationButton
          onClick={() => handlePageChange(totalPages)}
          disabled={page === totalPages}
        >
          <ChevronDoubleRight />
        </PaginationButton>
      </PaginationWrapper>
    </TablePageWrapper>
  );
};

export default TablePage;
