import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CheckBox } from "../../../Inputs/CustomCheckbox/CheckBox";
import {
  CheckboxLabel,
  CheckboxWrapper,
  IconWrapper,
  InteractivityBlock,
  InteractivityDescription,
  InteractivityHeadingWrapper,
  InteractivityLabel,
  InteractivityTitle,
  InteractivityToggle,
  InteractivityWrapper,
  SwitchedOff,
  SwitchedOn,
} from "../styles";
import {
  getDraftPageSettings,
  getPageSettings,
} from "../../../../store/selectors/projects";
import { getModalCreateOptions } from "../../../../store/selectors/modals";
import {
  setDraftPageSettings,
  setPageSettings,
} from "../../../../store/slices/projectPages";

export const Interactivity = () => {
  const dispatch = useDispatch();
  const pageSettings = useSelector(getPageSettings);
  const pageDraftSettings = useSelector(getDraftPageSettings);
  const createNewProject = useSelector(getModalCreateOptions);
  const settings = createNewProject ? pageDraftSettings : pageSettings;
  const openedIneractivity =
    settings?.showFilters || settings?.showSearch || settings.showTooltip;
  const [interactivityState, setInteractivityState] =
    useState(openedIneractivity);

  const toggleSwitch = () => {
    const localInteractivity = !interactivityState;
    setInteractivityState(localInteractivity);
    // dispatch(
    //   createNewProject
    //     ? setDraftPageSettings({
    //         ...settings,
    //         interactivity: localInteractivity ? ["interactivity"] : [],
    //       })
    //     : setPageSettings({
    //         ...settings,
    //         interactivity: localInteractivity ? ["interactivity"] : [],
    //       })
    // );
  };

  const switchSettings = (
    label: "showFilters" | "showSearch" | "showTooltip"
  ) => {
    const localSetting = !settings[label];
    dispatch(
      createNewProject
        ? setDraftPageSettings({
            ...settings,
            [label]: localSetting,
          })
        : setPageSettings({
            ...settings,
            [label]: localSetting,
          })
    );
  };

  return (
    <InteractivityWrapper>
      <InteractivityHeadingWrapper>
        <InteractivityTitle>Interactivity</InteractivityTitle>
        <InteractivityToggle $isEnabled={interactivityState}>
          {interactivityState ? "Show" : "Hide"}
          <IconWrapper>
            {interactivityState ? (
              <SwitchedOn onClick={toggleSwitch} />
            ) : (
              <SwitchedOff onClick={toggleSwitch} />
            )}
          </IconWrapper>
        </InteractivityToggle>
      </InteractivityHeadingWrapper>
      <InteractivityDescription>
        Can greatly enhance the user experience by making the data more
        accessible and easier to manipulate.
      </InteractivityDescription>
      {interactivityState ? (
        <InteractivityBlock>
          <InteractivityLabel>
            What functionality would you like to add?
          </InteractivityLabel>
          <CheckboxWrapper className="interactivity">
            <CheckBox
              checked={settings.showFilters}
              onChange={() => switchSettings("showFilters")}
            />
            <CheckboxLabel>Filters</CheckboxLabel>
          </CheckboxWrapper>
          <CheckboxWrapper className="interactivity">
            <CheckBox
              checked={settings.showSearch}
              onChange={() => switchSettings("showSearch")}
            />
            <CheckboxLabel>Search</CheckboxLabel>
          </CheckboxWrapper>
          <CheckboxWrapper className="interactivity">
            <CheckBox
              checked={settings.showTooltip}
              onChange={() => switchSettings("showTooltip")}
            />
            <CheckboxLabel>Tooltips</CheckboxLabel>
          </CheckboxWrapper>
        </InteractivityBlock>
      ) : null}
    </InteractivityWrapper>
  );
};
