import React from "react";
import { ProjectCardUsersWrapper, UserIcon, UserIconWrapper } from "./styles";
import { getInitials } from "../../helpers/getInitials";

interface ProjectCardUsersInterface {
  users: { name: string; id: string }[];
}

export const ProjectCardUsers = ({ users }: ProjectCardUsersInterface) => {
  return (
    <ProjectCardUsersWrapper>
      {users.map((user) => (
        <UserIconWrapper
          key={user.id}
          $user={user.id}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <UserIcon>{getInitials(user.name)}</UserIcon>
        </UserIconWrapper>
      ))}
    </ProjectCardUsersWrapper>
  );
};
