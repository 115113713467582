import { Qualitative12Colors, Qualitative2Colors1, Qualitative2Colors2, Qualitative4Colors } from "../colors";
import { styleTypes } from "../types/types";

export const getQualitativePalette = (styleId: styleTypes, number?: number) => {
  
  if (!number) {
    return { id: "", colors: [] };
  }

  if (number < 3) {
    const pallete1 = Qualitative2Colors1[styleId];
    const pallete2 = Qualitative2Colors2[styleId];

    return [pallete1, pallete2];
  }

  if (number > 2 && number < 5) {
    return Qualitative4Colors[styleId];
  }

  if (number > 4) {
    return Qualitative12Colors[styleId];
  }

  return { id: "", colors: [] };
};