import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import ProjectImg from "../../assets/project.png";
import AvatarImage from "../../assets/profileAvatar.png";
import {
  ButtonsWrapper,
  CreatedAt,
  DescriptionContainer,
  Label,
  ProjectContainer,
  Status,
  StatusTitle,
  Title,
  Description,
  WrapperContent,
  ImageOverlay,
  CreatedDate,
  CreatedTime,
  ImageContainer,
} from "./styles";
import { Button } from "../Button";
import { useDispatch } from "react-redux";
import { resetPages } from "../../store/slices/projectPages";
import { ProjectCardUsers } from "../ProjectCardUsers";
import { ProjectItemI } from "../../models/Projects";
import { PencilSquare, ArrowRight } from "react-bootstrap-icons";
import { downloadFile } from "../../api/files";
import { AxiosError, AxiosResponse } from "axios";

interface ProjectItemProps {
  project: ProjectItemI;
}

interface DownloadFileResponse {
  response?: AxiosResponse;
  error?: AxiosError;
}

export const ProjectItem = ({ project }: ProjectItemProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDescription, setShowDescription] = useState(false);
  const formattedDate = format(new Date(project.createdAt), "MM.dd.yyyy");
  const formattedTime = format(new Date(project.createdAt), " hh:mm a");
  const temporaryLabel = "LTSS";
  const [pagePreviewImage, setPagePreviewImage] = useState<string | undefined>(
    undefined
  );

  const reqPhoto = async (url: string) => {
    const response: DownloadFileResponse = await downloadFile(url);

    return setPagePreviewImage(response?.response?.data.url);
  };

  useEffect(() => {
    if (!project.previewImage) return;
    reqPhoto(project.previewImage);
  }, [project.previewImage]);

  const users = [
    { name: "Angela Smith", id: "1", avatar: AvatarImage },
    { name: "John Doe", id: "2", avatar: AvatarImage },
    { name: "Brad Pitt", id: "3" },
    { name: "John Doe", id: "4", avatar: AvatarImage },
    { name: "Angela Smith", id: "5", avatar: AvatarImage },
    { name: "Brad Pitt", id: "6", avatar: AvatarImage },
  ];

  return (
    <>
      <ProjectContainer
        onClick={() => {
          dispatch(resetPages());
          navigate(`/projects/${project.id}`);
        }}
      >
        <ProjectCardUsers users={users} />
        <ButtonsWrapper>
          <Button
            onClick={() => {}}
            variant="neutral-color"
            size="small"
            name="Preview"
          />
          <Button
            onClick={() => {}}
            variant="secondary"
            size="small"
            name="Open"
            icon={<ArrowRight fill="white" />}
          />
        </ButtonsWrapper>

        <ImageContainer
          src={pagePreviewImage || ProjectImg}
          $showDescription={showDescription}
          $blur={!pagePreviewImage}
        />
        <ImageOverlay />
        <WrapperContent
          onMouseEnter={() => setShowDescription(true)}
          onMouseLeave={() => setShowDescription(false)}
        >
          <DescriptionContainer>
            <Label $size={11}>{temporaryLabel}</Label>
            {project.isPublic && (
              <Status $size={11}>
                <StatusTitle>Published</StatusTitle>
              </Status>
            )}
            <CreatedAt>
              <PencilSquare />
              <CreatedDate>{formattedDate} </CreatedDate>
              <CreatedTime>{formattedTime}</CreatedTime>
            </CreatedAt>
          </DescriptionContainer>
          <Title $size={14}>{project.name}</Title>
          <Description $showDescription={showDescription}>
            {project.description}
          </Description>
        </WrapperContent>
      </ProjectContainer>
    </>
  );
};
